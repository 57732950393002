import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function Dropdown({ icone, texte, to, sousMenus }) {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  // Compare l'URL actuelle avec l'URL du lien pour déterminer s'il est actif
  const isActive = location.pathname === to;

  // Détermine la classe CSS conditionnelle
  const linkClassName = `nav-link text-white ${isActive ? "active" : ""}`;

  // Gère le clic pour ouvrir ou fermer le dropdown
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <li className="nav-item">
      <span className={linkClassName} onClick={toggleDropdown}>
        <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
          <i className="material-icons opacity-10">{icone}</i>
        </div>
        <span className="nav-link-text ms-1">{texte}</span>
        <i className={`material-icons float-end ${isOpen ? "rotate-icon" : ""}`}>
          arrow_drop_down
        </i>
      </span>

      {isOpen && (
        <ul className="submenu">
          {sousMenus.map((sousMenu, index) => (
            <li key={index}>
              <Link to={sousMenu.to} className="text-white">
                {sousMenu.texte}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
}
