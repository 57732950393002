import axios from 'axios';
import {MDBIcon} from 'mdbreact';
import {Link} from "react-router-dom";
import {useState, useEffect} from 'react';


const dataManager = {
   columns : [
    {
      label: 'Last name',
      field: 'nom',
      sort: 'asc',
      width: 150,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'First names',
      field: 'prenoms',
      sort: 'asc',
      width: 270,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'Email',
      field: 'email',
      sort: 'asc',
      width: 200,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'Phone',
      field: 'telephone',
      sort: 'asc',
      width: 100,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'Job',
      field: 'poste',
      sort: 'asc',
      width: 100,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'Customer',
      field: 'client',
      sort: 'asc',
      width: 150,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'Action',
      field: 'action',
      sort: 'asc',
      width: 150,
      attributes: {
        className: 'text-center',
      },
    },
  ],
  rows: [], // Initialisez avec un tableau vide pour les lignes
};

function modifier(id) {
  // Définissez l'URL de modification en fonction de l'ID du domaine
  const urlModification = `/edit/manager/${id}`; // Remplacez cette URL par la route de modification appropriée

  return (
    <div className='col-md-6'>
      <Link to={urlModification} className="text-secondary font-weight-normal text-xs" data-toggle="tooltip" data-original-title="Edit">
        <MDBIcon icon="edit" className="mdb-icon-fab mdb-color-success" />
      </Link>
    </div>
  );
}
function handleDeletePost(id, callback) {
  if (window.confirm("Are you sure you want to delete this manager ?")) {
    // Demandez une confirmation avant de supprimer le poste
    const authToken = localStorage.getItem('token');

    axios.get(`https://cerebrotbt.com/api/admin/manager/delete/${id}`,{
      headers : {
        'Authorization': `Bearer ${authToken}`
      },
    })
      .then((response) => {
        // Gérez la réponse si nécessaire
        alert('The manager has been successfully deleted');
        if (typeof callback === 'function') callback();
        // Vous pouvez également mettre à jour votre liste de postes ici
      })
      .catch((error) => {
        // Gérez les erreurs en cas de problème lors de la suppression
        if (typeof callback === 'function') callback();
        console.error('Error deleting manager :', error);
      });
  }
}
export async function fetchManagerData(){
  try {
    const authToken = localStorage.getItem('token'); // Remplacez par le jeton d'authentification approprié

    // Make an Axios GET request to the API
    const response = await axios.get('https://cerebrotbt.com/api/admin/manager/index', {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response.data?.managers ?? [];
    // Assuming the response data is an array of manager objects, update the rows.
    // Adjust this part based on the actual structure of the API response.
  } catch (error) {
  console.error('Error fetching data from the API:', error);
}
};

export function useTableManager(){

  const [configs, _setConfigs] = useState(dataManager);


      useEffect(() => {
        (async () => {
            await load();
        })()
    }, []);

  const cb = async () => {
      const data = await fetchManagerData(configs);
      setConfigs(data)
  }
  const setConfigs = (data) => {
    _setConfigs({
        ...configs,
        rows:data ? data.map((manager) => ({
          nom: manager.nom,
          prenoms: manager.prenoms,
          email: manager.email,
          telephone: manager.telephone,
          poste: manager.poste,
          client: manager.client ? manager.client.nom : 'N/A',
          action: (
            <div className='row'>
               {modifier(manager.id)} {/* Appel de la fonction modifier avec l'ID du domaine */}
              <div className='col-md-6'>
              <Link className='text-secondary font-weight-normal text-xs' data-toggle="tooltip" data-original-title="Supprimer" onClick={() => handleDeletePost(manager.id,cb)}>
                <MDBIcon icon="trash-alt" className="mdb-icon-fab mdb-color-danger" />
              </Link>
              </div>
            </div>
          ), // You can customize this if needed
        })) :[]
      })
    }


    const load = async () => {
      const data = await fetchManagerData(configs);
      setConfigs(data)
  }

  return [configs, load];
}
