import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

import Champs from "../autentification/champs";
import { Link } from 'react-router-dom';


export default function Nav() {

  const [recherche, setRecherche] = useState("");
  const [user, setUser] = useState(null);
  const location = useLocation();
  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState([]);


  const handleLogout = () => {
    // Supprimer le token du localStorage
    localStorage.removeItem('token');


    // Rediriger l'utilisateur vers la page de connexion ou une autre page
    // selon votre application
    window.location.href = '/'; // Remplacez '/login' par la route appropriée
  };
  useEffect(() => {
    // Récupérez la chaîne JSON depuis le local storage
    const userInfoJSON = localStorage.getItem("user");

    // Vérifiez si userInfoJSON est défini
    if (userInfoJSON) {
      // Parsez la chaîne JSON en un objet JavaScript
      const parsedUserInfo = JSON.parse(userInfoJSON);
      // Accédez à la propriété "nom" de l'objet
      const nom = parsedUserInfo.nom + ' ' + parsedUserInfo.prenoms;
      setUser(nom);
    } else {
      console.log('Aucune donnée utilisateur trouvée dans le local storage');
    }
    //const userId = userInfo.id;
    const fetchData = async () => {
      try {
        const authToken = localStorage.getItem('token');
        const response = await axios.get("https://cerebrotbt.com/api/admin/notification/index", {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        });
        setNotifications(response.data.notifications);
        setNotificationsCount(response.data.count);
      } catch (error) {
        console.error("Erreur lors de la récupération des notifications:", error);
      }
    };

    fetchData();
  }, []);
  const titre = () => {
    let inputString = location.pathname;

    // Supprimer le slash initial
    inputString = inputString.replace(/^\//, '');

    // Mettre la première lettre en majuscule
    inputString = inputString.charAt(0).toUpperCase() + inputString.slice(1);
    return inputString;

  }
  const getTimePeriod = (createdAt) => {
    const currentTime = new Date();
    const createdAtTime = new Date(createdAt);
    const timeDifference = Math.abs(currentTime - createdAtTime);
    const minutes = Math.floor(timeDifference / (1000 * 60));
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (minutes < 60) {
      return `${minutes} minute${minutes > 1 ? 's' : ''}`;
    } else if (hours < 24) {
      return `${hours} heure${hours > 1 ? 's' : ''}`;
    } else {
      return `${days} jour${days > 1 ? 's' : ''}`;
    }
  };
  const handleRetour = () => {
    if (location.pathname !== "/edit/") {
      // Si l'URL courante n'est pas "/edit/", utilisez le hook history pour revenir à la page précédente
      window.history.back();
    } else {
      // Sinon, effectuez l'action que vous souhaitez pour afficher le retour (par exemple, ne rien faire ici)
    }
  };
  return (
    <>
      <nav className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" data-scroll="true">
        <div className="container-fluid py-1 px-3">
          <nav aria-label="breadcrumb">
            <h6 className="font-weight-bolder mb-0">
              <span onClick={handleRetour} style={{ cursor: "pointer", color: "#939191" }}>
                {location.pathname !== "/edit/" && "Back | "}
              </span>

              {titre()}
            </h6>
          </nav>
          <div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
            <div className="ms-md-auto pe-md-3 d-flex align-items-center">
              <div className="input-group input-group-outline">


                <Champs type="search" className="form-control" value={recherche} onChange={(e) => { setRecherche(e.target.value) }} placeholder="Enter here..." />

              </div>
            </div>
            <ul className="navbar-nav  justify-content-end">
              <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
                <a href="" className="nav-link text-body p-0" id="iconNavbarSidenav">
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line"></i>
                    <i className="sidenav-toggler-line"></i>
                    <i className="sidenav-toggler-line"></i>
                  </div>
                </a>
              </li>
              <li className="nav-item px-0 d-flex align-items-center">
                <a href="#" className="nav-link text-body p-0">
                  <i className="fa fa-cog fixed-plugin-button-nav cursor-pointer"></i>
                </a>
              </li>
              <li className="nav-item dropdown pe-2 d-flex align-items-center">
                <a href="#" className="text-body" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" style={{
                  display: "flex",
                  flexDirection: "column-reverse",
                  paddingBottom: "21px",
                  marginLeft: "10px",
                }}>
                  <i className="fa fa-bell cursor-pointer"></i><span style={{
                    backgroundColor: "red",
                    display: "flex",
                    borderRadius: "51px",
                    color: "#fff",
                    justifyContent: "center",
                    borderRadius: "50%",
                    width: "20px",
                    height: "20px",
                    position: "relative",
                    top: "-60%"
                  }}>{notificationsCount}</span>
                </a>
                <ul className="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4" aria-labelledby="dropdownMenuButton">
                  {notifications.map(notification => (
                    <Link to="/matching" className="mb-2">
                      <a className="dropdown-item border-radius-md" href="#">
                        <div className="d-flex py-1">
                          <div className="my-auto">
                            {/*<img src="../assets/img/team-2.jpg" className="avatar avatar-sm  me-3 " />*/}
                          </div>
                          <div className="d-flex flex-column justify-content-center">
                            <h6 className="text-sm font-weight-normal mb-1">
                              <span className="font-weight-bold">{notification.message}</span> de {notification.sender}
                            </h6>
                            <p className="text-xs text-secondary mb-0">
                              <i className="fa fa-clock me-1"></i>
                               {getTimePeriod(notification.created_at)} ago
                            </p>
                          </div>
                        </div>
                      </a>
                    </Link>
                  ))}
                </ul>
              </li>
              <li className="nav-item dropdown pe-2 d-flex align-items-center">
                <a href="#" className="nav-link text-body font-weight-bold px-0" id="dropdownMenuProfil" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="fa fa-user me-sm-1"></i>
                  Welcome {user ? user : ''} !
                </a>
                <ul className="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4" aria-labelledby="dropdownMenuProfil">
                  <li className="mb-2">
                    <Link to="/profile" className="dropdown-item border-radius-md">
                      <div className="d-flex py-1">
                        <div className="my-auto">
                          <i className="material-icons opacity-10">person</i>
                        </div>
                        <div className="d-flex flex-column justify-content-center" style={{ marginLeft: "20px" }}>
                          <h6 className="text-sm font-weight-normal mb-1">
                            <span className="font-weight-bold">Profile</span>
                          </h6>
                        </div>
                      </div>
                    </Link>
                  </li>

                  <li className="mb-2">
                    <a className="dropdown-item border-radius-md" onClick={handleLogout}>
                      <div className="d-flex py-1">
                        <div className="my-auto">
                          <i class="material-icons opacity-10">logout</i>
                        </div>
                        <div className="d-flex flex-column justify-content-center" style={{ marginLeft: "20px" }}>
                          <h6 className="text-sm font-weight-normal mb-1">
                            <span className="font-weight-bold">Déconnexion</span>
                          </h6>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}