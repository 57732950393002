import { useState, useEffect } from "react";
import axios from 'axios';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import { fetchIndustriesFromAPI } from "../../datas/industries";
import { fetchRolesFromAPI } from "../../datas/role";

import NavigationGauche from "../../components/dashboard/navGauch";
import Nav from "../../components/dashboard/nav";

import Champs from "../../components/autentification/champs";
import Boutons from "../../components/autentification/bouton";
import { useParams } from "react-router-dom";

export default function EditUser() {
  const [formattedRole, setFormattedRole] = useState([]);
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isUserLoading, setIsUserLoading] = useState(true);
  const animatedComponents = makeAnimated();
  const [role, setRole] = useState(null);
  const { userId } = useParams();
  const [nom, setNom] = useState("");
  const [prenoms, setPrenoms] = useState("");
  const [telephone, seTelephone] = useState("");
  const [email, setEmail] = useState("");




  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = { 
                         nom: user.nom, 
                         prenoms: user.prenoms, 
                         telephone: user.telephone, 
                         email: user.email, 
                         role_id: user.roles[0].id
                       };
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
 
      console.log("formData: ",formData);
      const response = await axios.post(`https://cerebrotbt.com/api/admin/user/update/${userId}`, formData, { headers });

      const updatedUser = response.data;
      setUser(updatedUser);
      setNom(''); 
      setPrenoms('');
      setEmail('');
      seTelephone('');
      setRole(null);
      
    } catch (error) {
      console.error('Erreur lors de la soumission du formulaire :', error);
    }
  };

  useEffect(() => {
    fetchRolesFromAPI().then((data) => {
      const formatted = data.roles.map(role => ({
        value: role.id,
        label: role.nom,
      }));
      setFormattedRole(formatted);
      setIsLoading(false);
    });

    setIsUserLoading(true);
  

    axios
      .get(`https://cerebrotbt.com/api/admin/user/show/${userId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then((response) => {
        setUser(response.data.user); // Assurez-vous que le nom de la propriété est correct (domaine vs Domaine)
        setIsUserLoading(false);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération de l'utilisateur:", error);
        setIsUserLoading(false);
      });
  }, [userId]);

  if (isLoading || isUserLoading) {
    return <div className="loader"></div>;
  }

  return (
    <>
      <NavigationGauche />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
        <Nav />
        <div className="container py-4">
          <div className="row mx-7">
            <div className="col-md-12 mb-lg-0 mb-4">
              <form  onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                  <div className="input-group input-group-outline mb-3">
                      <Champs TypeChamp="text" value={user.nom} onChange={(e) => setUser({ ...user, nom: e.target.value })} placeholder="Nom" />
                  </div>
                    <div className="input-group input-group-outline mb-3">
                      <Champs TypeChamp="text" value={user.prenoms} onChange={(e) => setUser({ ...user, prenoms: e.target.value })} placeholder="Prénoms" />
                    </div>
                    <div className="input-group input-group-outline mb-3">
                      <Champs TypeChamp="text" value={user.telephone} onChange={(e) => setUser({ ...user, telephone: e.target.value })} placeholder="Téléphone" />
                    </div>
                    <div className="input-group input-group-outline mb-3">
                      <Champs TypeChamp="text" value={user.email} onChange={(e) => setUser({ ...user, email: e.target.value })} placeholder="Email" />
                    </div>
                  </div>
                </div>
                <div className="row">
                <div className="col-md-10">
                <div className="input-group input-group-outline mb-3">
                      <Select
                        options={formattedRole}
                        isSearchable
                        onChange={(selectedOption) => setRole(selectedOption)}
                        value={{ value:user.roles ? user.roles[0].id : "", 
                          label: user.roles ? user.roles[0].nom : ""}}
                        name="role_id"
                        placeholder="Rôle"
                        className="col-md"
                      />
                    </div>
                </div>
                <div className="col-md-2">
                <div className="input-group input-group-outline mb-3">
                      <Boutons type="submit" valeur="Enregistrer" />
                    </div>
                </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
