import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function Liens({ icone, texte, to }) {
  const location = useLocation();

  // Compare l'URL actuelle avec l'URL du lien pour déterminer s'il est actif
  const isActive = location.pathname === to;


  // Détermine la classe CSS conditionnelle
  const linkClassName = `nav-link text-white ${isActive ? "active" : ""}`;

  return (
    <li className="nav-item">
      <Link to={to} className={linkClassName}>
        <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
          <i className="material-icons opacity-10">{icone}</i>
        </div>
        <span className="nav-link-text ms-1">{texte}</span>
      </Link>
    </li>
  );
}
