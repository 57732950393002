import axios from 'axios';
import { MDBIcon } from 'mdbreact';
import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';



const dataPoste = {
  columns: [
    {
      label: 'Customers',
      field: 'clients',
      sort: 'asc',
      width: 150,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'Office presence',
      field: 'officepresence',
      sort: 'asc',
      width: 270,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'Jobs',
      field: 'postes',
      sort: 'asc',
      width: 200,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'Remuneration',
      field: 'remunerations',
      sort: 'asc',
      width: 100,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'Phone manager',
      field: 'phone',
      sort: 'asc',
      width: 100,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'E-mail manager',
      field: 'email',
      sort: 'asc',
      width: 100,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'Status',
      field: 'statut',
      sort: 'asc',
      width: 150,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'Action',
      field: 'action',
      sort: 'asc',
      width: 150,
      attributes: {
        className: 'text-center',
      },
    },
  ],
  rows: [], // Initialisez avec un tableau vide pour les lignes
};
function modifier(id) {
  // Définissez l'URL de modification en fonction de l'ID du domaine
  const urlModification = `/edit/poste/${id}`; // Remplacez cette URL par la route de modification appropriée

  return (
    <div className='col-md-3'>
      <Link title='Modification' to={urlModification} className="text-secondary font-weight-normal text-xs" data-toggle="tooltip" data-original-title="Edit">
        <MDBIcon icon="eye" className="mdb-icon-fab mdb-color-success" />
      </Link>
    </div>
  );
}
function matching(id) {
  // Définissez l'URL de modification en fonction de l'ID du domaine
  const urlModification = `/matching-poste/${id}`; // Remplacez cette URL par la route de modification appropriée
  return (
    <div className='col-md-3'>
      <Link title='Matching' to={urlModification} className="text-secondary font-weight-normal text-xs" data-toggle="tooltip"
        data-original-title="Matching">
        <MDBIcon icon="hands-helping" className="mdb-icon-fab mdb-color-success" />
      </Link>
    </div>
  );
}
function miseEnReseau(id) {
  const urlMiseEnReseau = `/mise-en-reseau-poste/${id}`; // Remplacez cette URL par la route de modification appropriée

  return (
    <div className='col-md-3'>
      <Link title='Mise en réseau' to={urlMiseEnReseau} className="text-secondary font-weight-normal text-xs" data-toggle="tooltip" data-original-title="Modifier">
        <MDBIcon icon="exchange-alt" className="mdb-icon-fab mdb-color-success" />
      </Link>
    </div>
  );
}
function handleDeletePost(id, callback) {
  if (window.confirm("Êtes-vous sûr de vouloir supprimer ce poste ?")) {
    // Demandez une confirmation avant de supprimer le poste
    const authToken = localStorage.getItem('token');

    axios.get(`https://cerebrotbt.com/api/admin/poste/delete/${id}`, {
      headers: {
        'Authorization': `Bearer ${authToken}`
      },
    })
      .then((response) => {
        // Gérez la réponse si nécessaire
        alert('Le poste a été supprimé avec succès');
        if (typeof callback === 'function') callback();
        // Vous pouvez également mettre à jour votre liste de postes ici
      })
      .catch((error) => {
        // Gérez les erreurs en cas de problème lors de la suppression
        console.error('Erreur lors de la suppression du poste :', error);
        if (typeof callback === 'function') callback();
      });
  }
}
export async function fetchPosteData() {
  try {
    const authToken = localStorage.getItem('token'); // Remplacez par le jeton d'authentification approprié

    // Effectuez une demande GET Axios à l'API
    const response = await axios.get('https://cerebrotbt.com/api/admin/poste/index', {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    const postes = response.data?.postes ?? [];
    console.log('postes: ',postes)
    return postes;
    // En supposant que les données de réponse sont un tableau d'objets client, mettez à jour les lignes.
    // Ajustez cette partie en fonction de la structure réelle de la réponse de l'API.
  } catch (error) {
    console.error('Erreur lors de la récupération des données depuis l\'API :', error);
    return [];
  }
};

export function useTablePoste() {
  const [configs, _setConfigs] = useState(dataPoste);
  const userFrom = localStorage.getItem('userFrom')


  useEffect(() => {
    (async () => {
      await load();
    })()
  }, []);

  const cb = async () => {
    const data = await fetchPosteData(configs);
    setConfigs(data)
  }

  const setConfigs = (data) => {
    _setConfigs({
      ...configs,
      rows: data.map((poste) => ({
        clients: (
          <div data-bs-toggle="modal" data-bs-target={`#exampleModal-${poste.id}`}>
            {poste.client.nom}
          </div>
        ),
        officepresence: (
          <div data-bs-toggle="modal" data-bs-target={`#exampleModal-${poste.id}`}>
            {poste.office.nom}
          </div>
        ),
        postes: (
          <div data-bs-toggle="modal" data-bs-target={`#exampleModal-${poste.id}`}>
            {poste.titre}
          </div>
        ),
        remunerations: (
          <div data-bs-toggle="modal" data-bs-target={`#exampleModal-${poste.id}`}>
            {poste.remuneration.nom}
          </div>
        ),
        phone: (
          <div data-bs-toggle="modal" data-bs-target={`#exampleModal-${poste.id}`}>
            {poste.client.managers.map((manager) => manager.telephone)}

          </div>
        ),
        email: (
          <div data-bs-toggle="modal" data-bs-target={`#exampleModal-${poste.id}`}>
            {poste.client.managers.map((manager) => manager.email)}
          </div>
        ),
        statut: (
          <div data-bs-toggle="modal" data-bs-target={`#exampleModal-${poste.id}`}>
            <span className={`badge badge-sm bg-${poste.statut === 'public' ? 'gradient-success' : 'secondary'}`}>
              {poste.statut ? poste.statut : "N/A"}</span>
          </div>
        ),
        action: (
          <div className='row'>
            {userFrom === "self" ? "":matching(poste.id) }
            {miseEnReseau(poste.id)}
            <div className='col-md-3' data-bs-toggle="modal" data-bs-target={`#exampleModal-${poste.id}`}>
      <Link title='Modification'  className="text-secondary font-weight-normal text-xs" data-toggle="tooltip" data-original-title="Edit">
        <MDBIcon icon="eye" className="mdb-icon-fab mdb-color-success" />
      </Link>
    </div>
            <div className='col-md-3'>
              <Link title='Supprimer' className='text-secondary font-weight-normal text-xs' data-toggle="tooltip" data-original-title="Supprimer" onClick={() => handleDeletePost(poste.id, cb)}>
                <MDBIcon icon="trash-alt" className="mdb-icon-fab mdb-color-danger" />
              </Link>
            </div>
            <div className="modal fade" id={`exampleModal-${poste.id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">Les informations du client</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">

                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <table className="table table-bordered">
                              <tbody>
                                <tr>
                                  <th scope="row">CLIENT</th>
                                  <td>{poste.client.nom}</td>
                                </tr>
                                <tr>
                                  <th scope="row">TYPE DE PRESENCE AU BUREAU</th>
                                  <td>{poste.office.nom}</td>
                                </tr>
                                <tr>
                                  <th scope="row">POSTES</th>
                                  <td>{poste.titre}</td>
                                </tr>
                                <tr>
                                  <th scope="row">TYPE DE REMUNERATION</th>
                                  <td>{poste.remuneration.nom}</td>
                                </tr>
                                <tr>
                                  <th scope="row">TYPE DE CONTRAT</th>
                                  <td>{poste.contrat.nom}</td>
                                </tr>
                                <tr>
                                  <th scope="row">ADRESSE</th>
                                  <td>{poste.location}</td>
                                </tr>
                                <tr>
                                  <th scope="row">DESCRIPTION</th>
                                  <td>{poste.description}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">fermer</button>
                    </div>
                  </div>
                </div>
              </div>
          </div>

        ),
        
      }))

    })
  }

  const load = async () => {
    const data = await fetchPosteData(configs);
    setConfigs(data)
  }

  return [configs, load];
}
