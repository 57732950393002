import { useState, useEffect,useRef } from "react";
import axios from 'axios';
import makeAnimated from 'react-select/animated';
import { MDBDataTable } from 'mdbreact';


import NavigationGauche from "../components/dashboard/navGauch";
import Nav from "../components/dashboard/nav";
import DataIndustry from "../datas/dataTable/tableIndustries";

import Champs from "../components/autentification/champs";
import Boutons from "../components/autentification/bouton";
import { useTableIndustry } from "../datas/dataTable/tableIndustries";

import { Link, useParams } from "react-router-dom";

export default function Industrie() {

  const [config, load] = useTableIndustry();


  const { industryId } = useParams();
  const [industries, setIndustries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const animatedComponents = makeAnimated();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [isUpdateFormVisible, setIsUpdateFormVisible] = useState(false); // État pour afficher/masquer le formulaire de mise à jour
  const [isAddFormVisible, setIsAddFormVisible] = useState(true); // État pour afficher/masquer le formulaire d'ajout
  const [alertMessages, setAlertMessages] = useState([]);


  const [nom, setNom] = useState("");


  const addAlertMessage = (message, type) => {
    // Ajoutez un nouvel message à la liste des alertes
    setAlertMessages([...alertMessages, { message, type }]);
    // Supprimez automatiquement le message après un certain délai (par exemple, 5 secondes)
    setTimeout(() => {
      removeAlertMessage();
    }, 5000); // 5000 millisecondes (5 secondes)
  };
  const removeAlertMessage = () => {
    // Supprimez le premier message de la liste des alertes
    if (alertMessages.length > 0) {
      const updatedMessages = [...alertMessages];
      updatedMessages.shift();
      setAlertMessages(updatedMessages);
    // Actualisez la page après avoir supprimé le message (délai de 5000 millisecondes)
    setTimeout(() => {
      window.location.reload();
    }, 5000);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Empêche la soumission par défaut du formulaire

    try {
      // Créez un objet contenant les données que vous souhaitez envoyer
      const formData = { nom };

      // Définissez votre jeton d'authentification Bearer ici
      const token = localStorage.getItem('token');

      // Configurez l'en-tête d'autorisation avec le jeton Bearer
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      // Envoyez la requête POST à l'URL spécifiée avec les données du formulaire et l'en-tête d'autorisation
      const response = await axios.post('https://cerebrotbt.com/api/admin/industry/add_industry', formData, { headers });

      // Traitez la réponse ici, par exemple, affichez un message de succès
      console.log('Réponse du serveur :', response.data);
      if(response.status === 201){
        addAlertMessage("L'industry a été créé avec succès", "success");
        // Ajoutez la nouvelle industrie à la liste existante
        const newIndustry = response.data; // Assurez-vous que response.data contient les détails de la nouvelle industrie
        setIndustries(prevIndustries => [...prevIndustries, newIndustry]);
        // Réinitialisez le champ "nom" après la soumission réussie
        setNom('');
       }
       await load();
    } catch (error) {
      // Gérez les erreurs ici, par exemple, affichez un message d'erreur
      console.error('Erreur lors de la soumission du formulaire :', error);
      addAlertMessage(`Erreur lors de la modification de l'industrie . ${error.response.data.message}`, "danger");
    }
  };
  // Fonction pour gérer la récupération des données d'une industrie pour la mise à jour
  const handleEditIndustry = (industryId) => {
    // Utilisez l'ID de l'industrie pour récupérer ses données depuis l'API
    axios
      .get(`https://cerebrotbt.com/api/admin/industry/show/${industryId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        // Mettez à jour l'état selectedIndustry avec les données de l'industrie sélectionnée
        setSelectedIndustry(response.data.Industry);
  
        // Affichez le formulaire de mise à jour et masquez le formulaire d'ajout
        setIsUpdateFormVisible(true);
        setIsAddFormVisible(false);
      })
      .catch((error) => {
        console.error('Erreur lors de la récupération des données de l\'industrie :', error);
      });
  };
  
  // Fonction pour gérer la soumission du formulaire de mise à jour
  const handleUpdateIndustry = async (e) => {
    e.preventDefault();

    try {
      const formData = {
        nom: selectedIndustry.nom, // Utilisez les données de selectedIndustry pour la mise à jour
      };

      const token = localStorage.getItem('token');

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      await axios.post(`https://cerebrotbt.com/api/admin/industry/update/${selectedIndustry.id}`, formData, { headers });

      // Mettez à jour l'état industries après la mise à jour réussie
      setIndustries(prevIndustries => {
        return prevIndustries.map(industry => {
          if (industry.id === selectedIndustry.id) {
            // Utilisez les données de selectedIndustry pour mettre à jour cette industrie spécifique
            return {
              ...industry,
              nom: selectedIndustry.nom,
            };
          }
          return industry;
        });
      });

      // Réinitialisez selectedIndustry et masquez le formulaire de mise à jour
      setSelectedIndustry(null);
      setIsUpdateFormVisible(false);
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'industrie :', error);
    }
  };
// Fonction pour gérer la suppression d'une industrie
const handleDeleteIndustry = async (industryId) => {
    try {
      // Définissez votre jeton d'authentification Bearer ici
      const token = localStorage.getItem('token');
  
      // Configurez l'en-tête d'autorisation avec le jeton Bearer
      const headers = {
        Authorization: `Bearer ${token}`,
      };
  
      // Envoyez la requête DELETE pour supprimer l'industrie avec l'ID spécifié
      await axios.get(`https://cerebrotbt.com/api/admin/industry/delete/${industryId}`, { headers });
  
      // Mettez à jour l'état des industries en supprimant l'industrie de la liste
      setIndustries(prevIndustries => prevIndustries.filter(industry => industry.id !== industryId));
    } catch (error) {
      // Gérez les erreurs ici, par exemple, affichez un message d'erreur
      console.error('Erreur lors de la suppression de l\'industrie :', error);
    }
  };
  useEffect(() => {
    axios
    .get(`https://cerebrotbt.com/api/admin/industry/index?page=${currentPage}&search=${searchTerm}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then((response) => {
      setIndustries(response.data.Industry);
      setTotalPages(response.data.totalPages);
      setIsLoading(false);
    })
    .catch((error) => {
      console.error('Erreur lors de la récupération des industries :', error);
      setIsLoading(false);
    });
  
  }, []);

  const handleRowClick = (entry) => {
    // Gérez les clics de ligne ici
    console.log('Row clicked:', entry);

};

  if (isLoading) {
    return <div className="loader"></div>;
  }
  return (
    <>

      <NavigationGauche />


      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
        <Nav />
        <div className="container py-4">

          <div className="row mx-7">
          {alertMessages.map((alert, index) => (
          <div key={index} className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
            {alert.message}
            <button
              type="button"
              className="btn-close"
              onClick={() => removeAlertMessage(index)}
              aria-label="Close"
            ></button>
          </div>
        ))}
            <div className="col-md-12 mb-lg-0 mb-4">
            {/* Affichez le formulaire d'ajout */}
            {isAddFormVisible && (
              <form role="form" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-10">
                      <div className="input-group input-group-outline mb-3">
                        <Champs TypeChamp="text" value={nom} onChange={(e) => { setNom(e.target.value) }} placeholder="Name" />
                    
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="input-group input-group-outline mb-3">
                        <Boutons type="submit" valeur="Save" />

                      </div>
                    </div>

                  </div>
              </form>
              )}
            {/* Affichez le formulaire de mise à jour si isUpdateFormVisible est vrai */}
           {isUpdateFormVisible && selectedIndustry && (
         <form role="form" onSubmit={handleUpdateIndustry}>
               <div className="row">
          <div className="col-md-9">
            <div className="input-group input-group-outline mb-3">
              <Champs
                TypeChamp="text"
                value={selectedIndustry ? selectedIndustry.nom : ''}
                onChange={(e) => {
                  if (selectedIndustry) {
                    setSelectedIndustry({ ...selectedIndustry, nom: e.target.value });
                  }
                }}
                placeholder="Nom"
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="input-group input-group-outline mb-3">
              <Boutons type="submit" valeur="Mise à jour" />
            </div>
          </div>
        </div>
       </form>
     )}
            </div>
          </div>

          <div className="row mt-4" >
            <div className="col-12">
            <p className="font-weight-bold">LIST OF INDUSTRIES</p>
            <MDBDataTable
                striped
                bordered
                small
                entriesLabel="See entries"
                infoLabel={["Show", "to", "from", "elements", "Display of {0}-{1} of {2} items",]}
                searchLabel="search"
                noRecordsFoundLabel="No record found"
                noBottomColumns
              paginationLabel={["P", "S"]}
                data={config}
                onRowClick={handleRowClick}
              />
            {/*<div className="col-md-4">
  <div className="input-group input-group-outline mb-3">
    <input
      type="text"
      className="form-control"
      placeholder="Rechercher..."
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
    />
    <div className="input-group-append">
      <button
        className="btn btn-outline-primary"
        type="button"
        onClick={() => {
          setCurrentPage(1); 
        }}
      >
        Rechercher
      </button>
    </div>
  </div>
</div>

              <div className="card my-2">
                <div className="card-body px-0 pb-2">
                  <div className="table-responsive p-0">
                    <table className="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nom</th>
                          <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">actions</th>
                        </tr>
                      </thead>
                      <tbody>
                      {industries.map(industry => (
                          <tr key={industry.id}>
                            <td>
                              <div className="d-flex px-2 py-1">
                                <div className="d-flex flex-column justify-content-center">
                                  <h6 className="mb-0 text-sm">{industry.nom}</h6>
                                </div>
                              </div>
                            </td>
                            <td className="align-middle text-center text-sm">
                             
                               <Link
                                    to={`/update-industry/${industry.id}`}
                                    onClick={() => handleEditIndustry(industry.id)} // Appel de la fonction de récupération des données pour la mise à jour
                                    className="text-secondary font-weight-normal text-xs"
                                    data-toggle="tooltip"
                                    data-original-title="Edit"
                                >
                                <i className="material-icons opacity-10">edit</i>
                              </Link>
                              <button
                                className="text-danger font-weight-normal text-xs"
                                onClick={() => handleDeleteIndustry(industry.id)} // Appel de la fonction de suppression
                                data-toggle="tooltip"
                                data-original-title="Delete"
                                >
                                <i className="material-icons opacity-10">delete</i>
                            </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    
                  </div>
                </div>
              </div>
              <div className="col-md-12">
  <nav aria-label="Page navigation example">
    <ul className="pagination justify-content-end">
      <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
        <button
          className="page-link"
          onClick={() => {
            if (currentPage > 1) {
              setCurrentPage(currentPage - 1);
            }
          }}
        >
          Précédent
        </button>
      </li>
      <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
        <button
          className="page-link"
          onClick={() => {
            if (currentPage < totalPages) {
              setCurrentPage(currentPage + 1);
            }
          }}
        >
          Suivant
        </button>
      </li>
    </ul>
  </nav>
</div>*/}

            </div>
          </div>


        </div>
      </main>


    </>
  );
}