import axios from 'axios';
import { MDBIcon } from 'mdbreact';
import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';


const dataUser = {
  columns: [
    {
      label: 'Last name',
      field: 'nom',
      sort: 'asc',
      width: 150,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'First names',
      field: 'prenoms',
      sort: 'asc',
      width: 270,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'Email',
      field: 'email',
      sort: 'asc',
      width: 200,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'Phone',
      field: 'telephone',
      sort: 'asc',
      width: 100,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'Role',
      field: 'role',
      sort: 'asc',
      width: 100,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'Status',
      field: 'status',
      sort: 'asc',
      width: 100,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'Action',
      field: 'action',
      sort: 'asc',
      width: 150,
      attributes: {
        className: 'text-center',
      },
    },
  ],
  rows: [], // Initialisez avec un tableau vide pour les lignes
};

function handleDeletePost(id, callback) {
  if (window.confirm("Êtes-vous sûr de vouloir supprimer cet utilisateur ?")) {
    // Demandez une confirmation avant de supprimer le poste
    const authToken = localStorage.getItem('token');

    axios.get(`https://cerebrotbt.com/api/admin/user/delete/${id}`, {
      headers: {
        'Authorization': `Bearer ${authToken}`
      },
    })
      .then((response) => {
        // Gérez la réponse si nécessaire
        alert("L' utilisateur a été supprimé avec succès");
        if (typeof callback === 'function') callback();

        // Vous pouvez également mettre à jour votre liste de postes ici
      })
      .catch((error) => {
        // Gérez les erreurs en cas de problème lors de la suppression
        console.error("Erreur lors de la suppression de l'utilisateur :", error);
        if (typeof callback === 'function') callback();

      });
  }
}
function modifier(id) {
  // Définissez l'URL de modification en fonction de l'ID du domaine
  const urlModification = `/edit/user/${id}`; // Remplacez cette URL par la route de modification appropriée

  return (
    <div className='col-md-4'>
      <Link to={urlModification} className="text-secondary font-weight-normal text-xs" data-toggle="tooltip" data-original-title="Modifier">
        <MDBIcon icon="edit" className="mdb-icon-fab mdb-color-success" />
      </Link>
    </div>
  );
}
const fetchUserData = async () => {
  try {
    const authToken = localStorage.getItem('token'); // Remplacez par le jeton d'authentification approprié

    // Effectuez une demande GET Axios à l'API
    const response = await axios.get('https://cerebrotbt.com/api/admin/user/index', {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    // En supposant que les données de réponse sont un tableau d'objets client, mettez à jour les lignes.
    // Ajustez cette partie en fonction de la structure réelle de la réponse de l'API.
    return response.data?.users ?? [];
  } catch (error) {
    console.error('Erreur lors de la récupération des données depuis l\'API :', error);
  }
}

export function useDataUser() {

  const [configs, _setConfigs] = useState(dataUser);

  useEffect(() => {
    (async () => {
      await load();
    })()
  }, []);

  const cb = async () => {
    const data = await fetchUserData(configs);
    setConfigs(data)
  }

  const setConfigs = (data) => {
    _setConfigs({
      ...configs,
      rows: data.map((user) => ({
        nom: user.nom,
        prenoms: user.prenoms,
        email: user.email,
        telephone: user.telephone,
        status: (
          <span className={`badge badge-sm bg-${user.from === 'reseau' ? 'gradient-success' : 'secondary'}`}>
            {user.from == "reseau" ? "mandataire" : "invité"}</span>
        ),
        role: user.roles.map((role) => role.nom),
        action: (
          <div className='row'>
            <div className='col-md-4'>
              <Link className="text-secondary font-weight-normal text-xs" data-toggle="tooltip" data-original-title="View">
                <MDBIcon icon="eye" className="mdb-icon-fab mdb-color-primary" />
              </Link>
            </div>
            {modifier(user.id)} {/* Appel de la fonction modifier avec l'ID du domaine */}
            <div className='col-md-4'>
              <Link className='text-secondary font-weight-normal text-xs' data-toggle="tooltip" data-original-title="Delete" onClick={() => handleDeletePost(user.id, cb)}>
                <MDBIcon icon="trash-alt" className="mdb-icon-fab mdb-color-danger" />
              </Link>
            </div>
          </div>

        ),
      }))

    })
  }

  const load = async () => {
    const data = await fetchUserData(configs);
    setConfigs(data)
  }

  return [configs, load];

}
