import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import NavigationGauche from "../components/dashboard/navGauch";
import Nav from "../components/dashboard/nav";
import Champs from "../components/autentification/champs";
export default function Management() {

  const [formData, setFormData] = useState({
    nom: "",
  });
  const [typesDeContrat, setTypesDeContrat] = useState([]); // État pour stocker les types de contrat
  const [typesDeOffice, setTypesDeOffice] = useState([]); // État pour stocker les types de contrat
  const [typesDeRemu, setTypesDeRemu] = useState([]); // État pour stocker les types de contrat

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleRegistration = async (e) => {
    e.preventDefault();

    const authToken = localStorage.getItem("token");
    if (!authToken) {
      console.error("Le token d'authentification est undefined.");
      return;
    }

    try {
      const response = await axios.post(
        "https://cerebrotbt.com/api/admin/remuneraton/add_remuneraton",
        formData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      // Traitez la réponse ici (par exemple, affichez un message de succès ou mettez à jour l'état avec les données ajoutées)
      console.log("Réponse de l'API après l'ajout :", response.data);

      // Réinitialisez le formulaire après avoir ajouté la rémunération
      setFormData({
        nom: "",
      });
    } catch (error) {
      console.error("Erreur lors de l'ajout de la rémunération :", error);
    }
  };
  // Utilisez useEffect pour charger les types de contrat depuis l'API lors du chargement initial du composant
  useEffect(() => {
    const authToken = localStorage.getItem("token");
    if (authToken) {
      // Remplacez l'URL par l'URL de votre API
      axios.get("https://cerebrotbt.com/api/admin/contrat/index", {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then((response) => {
          // Mettez à jour l'état avec les données de l'API
          setTypesDeContrat(response.data.Contrat);
        })
        .catch((error) => {
          console.error("Erreur lors de la récupération des types de contrat:", error);
        });
      axios.get("https://cerebrotbt.com/api/admin/remuneraton/index", {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then((response) => {
          // Mettez à jour l'état avec les données de l'API
          setTypesDeRemu(response.data.Remuneration);
        })
        .catch((error) => {
          console.error("Erreur lors de la récupération des types de contrat:", error);
        });
    } else {
      console.error("Le token d'authentification est undefined.");
    }
  }, []); // Le tableau vide signifie que cela ne s'exécute qu'une fois lors du chargement initial

  return (
    <>
      <NavigationGauche />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
        <Nav />
        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-md-12 mb-lg-0 mb-4">
              <div className="card mt-4">
                <div className="card-header pb-0 p-3">
                  <div className="row">
                    <div className="col-6 d-flex align-items-center">
                      <h6 className="mb-0">Type de contrat </h6>
                    </div>
                  </div>
                </div>
                <div className="card-body p-3">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row">
                        <div>
                          <form>
                            <div className="container">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="input-group input-group-outline mb-3">
                                    <div className="text-center">
                                      <Champs className="nom" type="text" name="nom" value={formData.nom} onChange={handleInputChange} placeholder="Nom" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-group input-group-outline mb-3">
                                    <div className="text-center">
                                      <button type="submit" className="btn btn-lg bg-gradient-primary btn-lg w-100 mb-0" style={{ marginTop: "0px !important" }}>S'enregister</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class="card">
                        <div class="table-responsive">
                          <table class="table align-items-center mb-0">
                            <thead>
                              <tr>
                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Type de contrat</th>
                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Array.isArray(typesDeContrat) ? (
                                typesDeContrat.map((type, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div class="d-flex px-2 py-1">
                                        <div class="d-flex flex-column justify-content-center">
                                          <h6 class="mb-0 text-xs">{type.nom}</h6>
                                        </div>
                                      </div>
                                    </td>
                                    <td class="align-middle">
                                      <a href="javascript:;" class="text-secondary font-weight-normal text-xs" data-toggle="tooltip" data-original-title="Edit user">
                                        <i class="material-icons opacity-10">edit</i>
                                      </a>
                                      <a href="javascript:;" class="text-secondary font-weight-normal text-xs" data-toggle="tooltip" data-original-title="Edit user">
                                        <i class="material-icons opacity-10">delete</i>
                                      </a>
                                    </td>
                                  </tr>
                                ))
                              ) :
                                (
                                  <tr>
                                    <td colspan="2">Aucun type de contrat trouvé.</td>
                                  </tr>
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-lg-0 mb-4">
              <div className="card mt-4">
                <div className="card-header pb-0 p-3">
                  <div className="row">
                    <div className="col-6 d-flex align-items-center">
                      <h6 className="mb-0">Type d'office présence</h6>
                    </div>
                  </div>
                </div>
                <div className="card-body p-3">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row">
                        <div>
                          <form>
                            <div className="container">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="input-group input-group-outline mb-3">
                                    <div className="text-center">
                                      <Champs className="nom" type="text" name="nom" value={formData.nom} onChange={handleInputChange} placeholder="Nom" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-group input-group-outline mb-3">
                                    <div className="text-center">
                                      <button type="submit" className="btn btn-lg bg-gradient-primary btn-lg w-100 mb-0" style={{ marginTop: "0px !important" }}>S'enregister</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class="card">
                        <div class="table-responsive">
                          <table class="table align-items-center mb-0">
                            <thead>
                              <tr>
                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Type de contrat</th>
                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div class="d-flex px-2 py-1">
                                    <div class="d-flex flex-column justify-content-center">
                                      <h6 class="mb-0 text-xs">Freelance</h6>
                                    </div>
                                  </div>
                                </td>
                                <td class="align-middle">
                                  <a href="javascript:;" class="text-secondary font-weight-normal text-xs" data-toggle="tooltip" data-original-title="Edit user">
                                    <i class="material-icons opacity-10">edit</i>
                                  </a>
                                  <a href="javascript:;" class="text-secondary font-weight-normal text-xs" data-toggle="tooltip" data-original-title="Edit user">
                                    <i class="material-icons opacity-10">delete</i>
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-lg-0 mb-4">
              <div className="card mt-4">
                <div className="card-header pb-0 p-3">
                  <div className="row">
                    <div className="col-6 d-flex align-items-center">
                      <h6 className="mb-0">Type de rémuneration</h6>
                    </div>
                  </div>
                </div>
                <div className="card-body p-3">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row">
                        <div>
                          <form onSubmit={handleRegistration}>
                            <div className="container">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="input-group input-group-outline mb-3">
                                    <div className="text-center">
                                      <Champs className="nom" type="text" name="nom" value={formData.nom} onChange={handleInputChange} placeholder="Nom" />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-group input-group-outline mb-3">
                                    <div className="text-center">
                                      <button type="submit" className="btn btn-lg bg-gradient-primary btn-lg w-100 mb-0" style={{ marginTop: "0px !important" }}>S'enregister</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class="card">
                        <div class="table-responsive">
                          <table class="table align-items-center mb-0">
                            <thead>
                              <tr>
                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Type de contrat</th>
                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Array.isArray(typesDeRemu) ? (
                                typesDeRemu.map((type, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div class="d-flex px-2 py-1">
                                        <div class="d-flex flex-column justify-content-center">
                                          <h6 class="mb-0 text-xs">{type.nom}</h6>
                                        </div>
                                      </div>
                                    </td>
                                    <td class="align-middle">
                                      <a href="javascript:;" class="text-secondary font-weight-normal text-xs" data-toggle="tooltip" data-original-title="Edit user">
                                        <i class="material-icons opacity-10">edit</i>
                                      </a>
                                      <a href="javascript:;" class="text-secondary font-weight-normal text-xs" data-toggle="tooltip" data-original-title="Edit user">
                                        <i class="material-icons opacity-10">delete</i>
                                      </a>
                                    </td>
                                  </tr>
                                ))
                              ) :
                                (
                                  <tr>
                                    <td colspan="2">Aucun type de contrat trouvé.</td>
                                  </tr>
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </main>
    </>
  );
}