import React from "react";
import '../../src/index.css';
import TopNav from "../components/autentification/topnav";

export default function Services() {
    return (
        <>
            <TopNav />
            <main className="main-content  mt-0">
                <section id="pricing" className="pricing-content section-padding">
                    <div className="container">
                        <div className="section-title text-center">
                            <h2>Nos services</h2>
                            <p>Cerebro_TBT, le talent markeplace pour des opportunités égaliter pour tous.</p>
                        </div>
                        <div className="row text-center">
                            <div className="col-lg-6 col-sm-6 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0" >


                                <div className="pricing_design">
                                    <div className="single-pricing" style={{padding:"76px 40px"}}>
                                        <div className="price-head">
                                            <h2>OFFRE INVITÉ (FREEMIUM)</h2>
                                            {/*<h1 className="price">$29</h1>
                                            <span>/Monthly</span>*/}
                                        </div>
                                        <ul>
                                            <li> - Enregistrez vos profils et opportunités</li>
                                            <li> - Partagez les aux réseaux</li>
                                            <li> - Soyez rétribué en cas de recrutement*</li>
                                        </ul>
                                        {/*<p className="desc">
                                            Déposez votre profil et/ou des candidats de votre réseau sur la marketplace et laissez faire la magie du collectif.

                                            Recevez une notification par e-mail lorsque votre candidat est en processus d’entretien ou recruté, ou lorsque votre opportunité est staffée et soyez rétribué pour vos actions*
                                        </p>*/}
                                        <div className="pricing-price">

                                        </div>
                                        <a href="/inscription" className="price_btn">
                                            Commencer à coopter
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6 col-xs-12 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                <div className="pricing_design">
                                    <div className="single-pricing">
                                        <div className="price-head">
                                            <h2>OFFRE MANDATAIRE 69€ / Mois</h2>
                                        </div>
                                        <ul>
                                            <li>- Enregistrez vos profils et opportunités</li>
                                            <li>- Recevez des profils et lead à exploiter</li>
                                            <li>- Partagez les aux réseaux</li>
                                            <li>- Soyez rétribué en cas de recrutement*</li>
                                        </ul>
                                        {/*<ul>
                                            <li>Rendez privé et public</li>
                                            <li>Mettez en réseau vos candidats et opportunités</li>
                                            <li>Réceptionnez et exploitez des leads et opportunités</li>
                                            <li>Accédez aux fonctionnalités CRM</li>
                                            <li>Accès ATS</li>
                                            <li>Accès à la formation Techbytech Factory</li>
                                        </ul>*/}
                                        <div className="pricing-price">

                                        </div>
                                        <a href="https://techbytech.io/rejoindre-techbytech-2/" className="price_btn"> Intégrer les réseaux Techbytech</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}