import { FaEye, FaEyeSlash } from 'react-icons/fa';
import React, { useState } from "react";

export default function PasswordField({ TypeChamp, name, value, onChange, placeholder }) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      {showPassword ? (
        <input
          type="text" // Utilisez "text" si showPassword est vrai
          name={name}
          className="form-control"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          id="id_password"
        required/>
      ) : (
        <input
          type="password" // Utilisez "password" si showPassword est faux
          name={name}
          className="form-control"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          id="id_password"
          required/>
      )}
      <div
        className="eye-icon"
        onClick={() => setShowPassword(!showPassword)}
        style={{ marginLeft: "-19px", cursor: "pointer",
        zIndex: "1",
        position: "absolute",
        left: "99%",
        top: "12%" }}
      >
        {showPassword ? <FaEyeSlash /> : <FaEye />}
      </div>
    </>
  );
}
