import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { MDBDataTable } from 'mdbreact';

import NavigationGauche from "../components/dashboard/navGauch";
import Nav from "../components/dashboard/nav";
import Champs from "../components/autentification/champs";
import Boutons from "../components/autentification/bouton";

//import DataManager from "../datas/table";
import { useTableManager } from "../datas/table";
import { fetchIndustriesFromAPI } from "../datas/industries";
import { fetchDomainesByIndustryFromAPI } from "../datas/domaines";
import { fetchContratsFromAPI } from '../datas/contrat';
import { fetchOfficeFromAPI } from '../datas/office';
import { fetchRemunerationFromAPI } from '../datas/remuneration';
import { useTableConfig } from '../datas/dataTable/tableCandidats';


import ChampsUrl from '../components/autentification/ChampsUrl';


export default function Candidat() {

    const [config, load] = useTableConfig();

    const [isLoading, setIsLoading] = useState(true);
    const [formattedIndustries, setFormattedIndustries] = useState([]);
    const [formattedContrats, setformattedContrats] = useState([]);
    const [formattedOffices, setformattedOffices] = useState([]);
    const [formattedRemunerations, setformattedRemunerations] = useState([]);
    const [candidatId, setCandidatId] = useState(null);
    const [alertMessages, setAlertMessages] = useState([]);



    const [selectedIndustry, setSelectedIndustry] = useState([]);
    const [domainesForSelectedIndustry, setDomainesForSelectedIndustry] = useState([]);

    const madataireOptions = [
        { value: 'brouillon', label: 'Brouillon' },
        { value: 'privé', label: 'Privé' },
        { value: 'public', label: 'Public' },
        { value: 'fermer', label: 'Fermer' },

    ];

    const inviteOptions = [
        { value: 'brouillon', label: 'Brouillon' },
        { value: 'public', label: 'Public' },
        { value: 'fermer', label: 'Fermer' },

    ];

    const customLanguage = {
        pagination: {
            label: "Affichage de {0}-{1} de {2} éléments",
            first: "Premier",
            last: "Dernier",
            previous: "Précédent",
            next: "Suivant",
        },
        search: "Chercher :",
        info: "Affichage de {0}-{1} de {2} éléments",
        infoEmpty: "Affichage de 0-0 de 0 éléments",
    };

    // a sup
    const [nom, setNom] = useState("");
    const [prenoms, setPrenoms] = useState("");
    const [email, setEmail] = useState("");
    const [telephone, setTelephone] = useState("");
    const [lienLinkedin, setLienLinkedin] = useState("");
    const [lienGithub, setLienGithub] = useState("");
    const [lienPortefolio, setLienPortefolio] = useState("");
    const [location, setLocation] = useState("");
    const [remunerationMin, setRemunerationMin] = useState("");
    const [remunerationMax, setRemunerationMax] = useState("");
    const [poste, setPoste] = useState("");
    const [statut, setStatut] = useState([]);
    const [cv, setCv] = useState("");
    const [contrat_id, setContrat_id] = useState([]);
    const [officePresence_id, setOfficePresence_id] = useState([]);
    const [remuneration_id, setRemuneration_id] = useState([]);
    const [user, setUser] = useState("");
    const [disponibilite, setDisponible] = useState("inprocess");

    const [userFrom, setUserFrom] = useState(null);
    const [industry_id, setIndustry_id] = useState([]);

    const [selectedDomaines, setSelectedDomaines] = useState([]);
    const addAlertMessage = (message, type) => {
        // Ajoutez un nouvel message à la liste des alertes
        setAlertMessages([...alertMessages, { message, type }]);

        // Supprimez automatiquement le message après un certain délai (par exemple, 5 secondes)
        setTimeout(() => {
            removeAlertMessage();
        }, 5000); // 5000 millisecondes (5 secondes)
    };
    const removeAlertMessage = () => {
        // Supprimez le premier message de la liste des alertes
        if (alertMessages.length > 0) {
            const updatedMessages = [...alertMessages];
            updatedMessages.shift();
            setAlertMessages(updatedMessages);

            // Actualisez la page après avoir supprimé le message (délai de 5000 millisecondes)
            setTimeout(() => {
                window.location.reload();
            }, 5000);
        }
    };
    // fin a sup
    const [candidat, setCandidat] = useState([]);

    const handleFileChange = (e) => {
        // Assurez-vous que vous utilisez le fichier lui-même au lieu de e.target.files[0]
        const file = e.target.value;
        console.log("cv :", file);
        setCv(file);
    };
    console.log("user_id: ", user);
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const token = localStorage.getItem('token');
            const formData = new FormData();
            if (nom) {
                formData.append('nom', nom);
            }
            if (prenoms) {
                formData.append('prenoms', prenoms);
            }
            if (email) {
                formData.append('email', email);
            }
            if (telephone) {
                formData.append('telephone', telephone);
            }
            if (cv) {
                formData.append('cv', cv);
            }
            if (lienLinkedin) {
                formData.append('lienLinkedin', lienLinkedin);
            }
            if (lienGithub) {
                formData.append('lienGithub', lienGithub);
            }
            if (lienPortefolio) {
                formData.append('lienPortefolio', lienPortefolio);
            }
            if (location) {
                formData.append('location', location);
            }
            if (remunerationMin) {
                formData.append('remunerationMin', remunerationMin);
            }
            if (remunerationMax) {
                formData.append('remunerationMax', remunerationMax);
            }
            if (contrat_id.value) {
                formData.append('contrat_id', contrat_id.value);
            }
            if (officePresence_id.value) {
                formData.append('officePresence_id', officePresence_id.value);
            }
            if (remuneration_id.value) {
                formData.append('remuneration_id', remuneration_id.value);
            }
            if (poste) {
                formData.append('poste', poste);
            }
            if (selectedIndustry.value) {
                formData.append('industry_id', selectedIndustry.value);
            }
            if (statut.value) {
                formData.append('statut', statut.value);
            }
            if (user) {
                formData.append('user_id', user);
            }
            if(disponibilite){
                formData.append('disponibilite',disponibilite);
            }
            selectedDomaines.forEach((selectedDomaines) => {
                formData.append('domaine_id[]', selectedDomaines.value);
            });
            // Créez un objet de configuration pour l'en-tête
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
            };
            console.log("Données: ", formData);

            // Envoyez la requête POST avec Axios
            const response = await axios.post(
                'https://cerebrotbt.com/api/admin/candidat/add_candidat',
                formData,
                config
            );

            // Récupérez l'ID du candidat créé
            const newCandidatId = response.data.candidat_id;
            setCandidatId(newCandidatId);
            // Continuez avec l'étape suivante (envoi du CV)
            const cvFormData = new FormData();
            cvFormData.append('cv_path', document.getElementById('cvFile').files[0]);

            const cvUploadResponse = await axios.post(
                `https://cerebrotbt.com/api/admin/candidat/add_candidat/${newCandidatId}/upload-cv`,
                cvFormData,
                config
            );
            // Traitez la réponse ici (par exemple, affichez un message de succès)
            console.log('Réponse de la requête (CV upload):', cvUploadResponse.data);
            if ((response.status === 200) && (cvUploadResponse.status === 200)) {
                addAlertMessage("Client créé avec succès !", "success");
            }
            alert('Formulaire soumis avec succès');
            await load();

        } catch (error) {
            // Gérez les erreurs ici
            if (error.response) {
                // La requête a été faite, mais le serveur a répondu avec un code d'erreur
                console.error('Réponse d\'erreur du serveur:');
                // Parcours des erreurs et affichage de chaque message
                for (const key in error.response.data.errors) {
                    if (error.response.data.errors.hasOwnProperty(key)) {
                        const errorMessages = error.response.data.errors[key];
                        // Affichage du nom du champ et de ses messages d'erreur
                        console.error(`Erreur dans le champ "${key}":`);
                        addAlertMessage(`Erreur dans le champ "${key}":`);
                        errorMessages.forEach(errorMessage => {
                            console.error(`- ${errorMessage}`);
                            addAlertMessage(`- ${errorMessage}`);
                        });
                    }
                }
            } else if (error.request) {
                // La requête a été faite, mais aucune réponse n'a été reçue
                console.error('Aucune réponse reçue:', error.request);
                addAlertMessage('Aucune réponse du serveur');
            } else {
                // Une erreur s'est produite lors de la configuration de la requête
                console.error('Erreur de configuration de la requête:', error.message);
                addAlertMessage('Erreur de configuration de la requête');
            }
        }
    };

    const [currentPage, setCurrentPage] = useState(1); // État pour suivre la page actuelle

    // Fonction pour gérer le passage à la page précédente
    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Fonction pour gérer le passage à la page suivante
    const handleNext = () => {
        // Remplacez 5 par le nombre de pages dans votre pagination
        if (currentPage < 5) {
            setCurrentPage(currentPage + 1);
        }
    };

    const [dataManagerConfig, setDataManagerConfig] = useState([]);
    // Fonction pour récupérer les domaines en fonction de l'industrie sélectionnée
    const fetchDomainesForIndustry = async (industryId) => {
        const data = await fetchDomainesByIndustryFromAPI(industryId);
        const formatted = data.domaines?.map((domaine) => ({
            value: domaine.id,
            label: domaine.nom,
        }));
        setDomainesForSelectedIndustry(formatted);
    };
    useEffect(() => {
        //fetchData();
        // Attendez le chargement des données avant de formater les industries
        fetchIndustriesFromAPI().then((data) => {
            const formatted = data.industries?.map(industry => ({
                value: industry.id,
                label: industry.nom,
            }));
            setFormattedIndustries(formatted);
            setIsLoading(false);
        });

        fetchContratsFromAPI().then((data) => {
            const formatted = data.contrats?.map(contrat => ({
                value: contrat.id,
                label: contrat.nom,
            }));
            setformattedContrats(formatted);
            setIsLoading(false);
        });
        fetchOfficeFromAPI().then((data) => {
            const formatted = data.officePresences?.map(office => ({
                value: office.id,
                label: office.nom,
            }));
            setformattedOffices(formatted);
            setIsLoading(false);
        });

        fetchRemunerationFromAPI().then((data) => {
            const formatted = data.remunerations?.map(remuneration => ({
                value: remuneration.id,
                label: remuneration.nom,
            }));
            setformattedRemunerations(formatted);
            setIsLoading(false);
        });
        // Récupérez la chaîne JSON depuis le local storage
        const userInfoJSON = localStorage.getItem("user");

        // Vérifiez si userInfoJSON est défini
        if (userInfoJSON) {
            // Parsez la chaîne JSON en un objet JavaScript
            const parsedUserInfo = JSON.parse(userInfoJSON);
            // Accédez à la propriété "nom" de l'objet
            const from = parsedUserInfo.from;
            const id = parsedUserInfo.id;
            setUserFrom(from);
            setUser(id);
        } else {
            console.log('Aucune donnée utilisateur trouvée dans le local storage');
        }

    }, []);

    const handleRowClick = (entry) => {
        // Gérez les clics de ligne ici
        console.log('Row clicked:', entry);

    };

    if (isLoading) {
        return <div className="loader"></div>;
    }
    return (
        <>

            <NavigationGauche />

            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <Nav />
                <div className="container-fluid py-4">

                    <div className="row">
                        {alertMessages.map((alert, index) => (
                            <div key={index} className={`alert alert-${alert.type ? alert.type : 'danger'} alert-dismissible fade show`} role="alert">
                                {alert.message}
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => removeAlertMessage(index)}
                                    aria-label="Close"
                                ></button>
                                {console.log('alert: ', alert)}
                            </div>
                        ))}
                        <div className="col-md-12 mb-lg-0 mb-4">

                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="input-group input-group-outline mb-3">
                                            <Champs TypeChamp="text" value={nom} onChange={(e) => {
                                                setNom(e.target.value)
                                            }} placeholder="Last name" />

                                        </div>
                                        <div className="input-group input-group-outline mb-3">
                                            <Champs TypeChamp="text" value={prenoms} onChange={(e) => {
                                                setPrenoms(e.target.value)
                                            }} placeholder="First name(s)" />

                                        </div>
                                        <div className="input-group input-group-outline mb-3">
                                            <Champs TypeChamp="email" value={email} onChange={(e) => {
                                                setEmail(e.target.value)
                                            }} placeholder="Adresse email" />
                                        </div>
                                        <div className="input-group input-group-outline mb-3">
                                            <Champs TypeChamp="tel" value={telephone}
                                                onChange={(e) => setTelephone(e.target.value)}
                                                placeholder="Phone" />

                                        </div>
                                        <div className="input-group input-group-outline mb-3">
                                            <Champs TypeChamp="text" value={location}
                                                onChange={(e) => setLocation(e.target.value)}
                                                placeholder="Location" />
                                        </div>
                                        <div className="input-group input-group-outline mb-3">
                                            <Champs TypeChamp="text" value={poste}
                                                onChange={(e) => setPoste(e.target.value)} placeholder="Job" />

                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="input-group input-group-outline mb-3">

                                            <Select
                                                options={formattedIndustries}
                                                isSearchable
                                                onChange={(selectedOption) => {
                                                    setSelectedIndustry(selectedOption);
                                                    // Appelez la fonction pour récupérer les domaines en fonction de l'industrie
                                                    fetchDomainesForIndustry(selectedOption.value);
                                                }}
                                                value={selectedIndustry}
                                                name="industry_id"
                                                placeholder="Industry"
                                                className="col-md"
                                            />

                                        </div>
                                        <div className="input-group input-group-outline mb-3">
                                            <Select
                                                options={domainesForSelectedIndustry}
                                                isSearchable
                                                isMulti={true}
                                                onChange={(selectedOption) => {
                                                    setSelectedDomaines(selectedOption)
                                                }}
                                                value={selectedDomaines}
                                                name="domaine_id"
                                                placeholder="Domaine"
                                                className="col-md"
                                            />

                                        </div>
                                        <div className="input-group input-group-outline mb-3">
                                            <Select
                                                options={formattedContrats}
                                                isSearchable
                                                onChange={(selectedOption) => setContrat_id(selectedOption)}
                                                value={console.log("type de contrat: ",contrat_id)}
                                                name="contrat_id"
                                                placeholder="Contract type"
                                                className="col-md"
                                            />
                                        </div>
                                        <div className="input-group input-group-outline mb-3">
                                            <Select
                                                options={formattedOffices}
                                                isSearchable
                                                onChange={(selectedOption) => setOfficePresence_id(selectedOption)}
                                                value={officePresence_id}
                                                name="officePresence_id"
                                                placeholder="Type of office presence"
                                                className="col-md"
                                            />
                                        </div>
                                        <div className="input-group input-group-outline mb-3">
                                            <Select
                                                options={formattedRemunerations}
                                                isSearchable
                                                onChange={(selectedOption) => setRemuneration_id(selectedOption)}
                                                value={remuneration_id}
                                                name="remuneration_id"
                                                placeholder="Type of remuneration"
                                                className="col-md"
                                            />
                                        </div>
                                         {
                                          contrat_id.label === "freelance" 
                                          
                                          ?  
                                          <div className='row'>
                                            <p style={{fontSize: "12px",marginBottom: "10px", color:"red"}}>REMUNERATION FOR THIS PROJECT</p>
                                            <div className='col-md-6'>
                                                <div className="input-group input-group-outline mb-3">
                                                    <Champs TypeChamp="text" value={remunerationMin}
                                                        onChange={(e) => setRemunerationMin(e.target.value)}
                                                        placeholder="Min. remuneration" />

                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="input-group input-group-outline mb-3">
                                                    <Champs TypeChamp="text" value={remunerationMax}
                                                        onChange={(e) => setRemunerationMax(e.target.value)}
                                                        placeholder="Max. remuneration" />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className='row'>
                                            <p style={{fontSize: "12px",marginBottom: "10px", color:"red"}}>ANNUAL REMUNERATION</p>
                                            <div className='col-md-6'>
                                                <div className="input-group input-group-outline mb-3">
                                                    <Champs TypeChamp="text" value={remunerationMin}
                                                        onChange={(e) => setRemunerationMin(e.target.value)}
                                                        placeholder="Min. remuneration" />

                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="input-group input-group-outline mb-3">
                                                    <Champs TypeChamp="text" value={remunerationMax}
                                                        onChange={(e) => setRemunerationMax(e.target.value)}
                                                        placeholder="Max. remuneration" />
                                                </div>
                                            </div>
                                        </div>
                                         }

                                        
                                    </div>

                                    <div className="col-md-4">


                                        <div className="input-group input-group-outline mb-3">
                                            <input
                                                type="file"
                                                id="cvFile"
                                                className="form-control"
                                                onChange={handleFileChange}
                                                accept=".pdf"
                                            />
                                        </div>

                                        <div className="input-group input-group-outline mb-3">
                                            <ChampsUrl TypeChamp="url" value={lienLinkedin}
                                                onChange={(e) => setLienLinkedin(e.target.value)}
                                                placeholder="link linkedin" pattern="https://.*" />
                                        </div>
                                        <div className="input-group input-group-outline mb-3">
                                            <ChampsUrl TypeChamp="url" value={lienGithub}
                                                onChange={(e) => setLienGithub(e.target.value)}
                                                placeholder="link github" pattern="https://.*" />
                                        </div>
                                        <div className="input-group input-group-outline mb-3">
                                            <ChampsUrl TypeChamp="url" value={lienPortefolio}
                                                onChange={(e) => setLienPortefolio(e.target.value)}
                                                placeholder="link de portefolio" pattern="https://.*" />
                                        </div>
                                        <div className="input-group input-group-outline mb-3">
                                            <Select
                                                options={ userFrom === "self" ? inviteOptions : madataireOptions}
                                                isSearchable
                                                onChange={(selectedOption) => setStatut(selectedOption)}
                                                value={statut}
                                                name="statut"
                                                placeholder="Choose a status"
                                                className="col-md"
                                            />
                                        </div>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="input-group input-group-outline mb-3">
                                            <Boutons type="submit" valeur="Save" />
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>

                    <div className="row mt-4 pt-4">
                        <div className="col-md-12">
                            <div>
                                <span className="font-weight-bold mr-4">LIST OF CANDIDATES</span>
                            </div>
                            {
                                config
                                    ? (
                                        <MDBDataTable
                                            striped
                                            bordered
                                            small
                                            entriesLabel="See entries"
                                            infoLabel={["Show", "to", "from", "elements", "Display of {0}-{1} of {2} items",]}
                                            searchLabel="search"
                                            noRecordsFoundLabel="No record found"
                                            noBottomColumns
                                            paginationLabel={["P", "S"]}
                                            data={config}
                                            onRowClick={handleRowClick}
                                        />
                                    ) :
                                    <div className="loader" />
                            }

                        </div>
                    </div>

                </div>
            </main>


        </>
    );
}
