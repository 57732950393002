import { useState } from "react";
import background from "../assets/img/10780346_19197061.jpg";
import TopNav from "../components/autentification/topnav";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie"; // Importez js-cookie

import Boutons from "../components/autentification/bouton";
import Choix from "../components/autentification/choix";
import Champs from "../components/autentification/champs";
import PasswordField from "../components/autentification/passwordfield";

export default function Login() {
    const [email, setEmail] = useState("");
    const [motdepasse, setMotdepasse] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked); // Inverse l'état actuel
    };
    const handleLogin = async() => {
        try {
            const response = await axios.post("https://cerebrotbt.com/api/user/login", {
              email: email,
              password: motdepasse,
            });
      
            if (response.status === 200) {
                // La requête a réussi
                if (isChecked) {
                  // Si "Se souvenir de moi" est coché, stockez un cookie persistant
                  Cookies.set("authToken", response.data.token, { expires: 1 }); // Cookie valide pendant 1 jour
                }
                console.log("user0", JSON.stringify(response.data.user));
                // Stockez les données de l'utilisateur dans localStorage
                localStorage.setItem("user", JSON.stringify(response.data.user));
                localStorage.setItem("token", response.data.token);

              
                // Redirigez l'utilisateur vers le dashboard
                navigate("/dashboard");
              } else {
                // La requête a échoué, traitez l'erreur ici
                setError("La connexion a échoué. Veuillez vérifier vos informations d'identification.");
              }
              
          } catch (error) {
            // Une erreur s'est produite lors de la requête
            console.error("Erreur lors de la requête:", error);
          
            if (error.response) {
                // Il y a une réponse du serveur
                const errorMessage = error.response.status === 500 ? error.response.data.message : error.response.data.error; // Accédez au message d'erreur du serveur
                setError(`Une erreur s'est produite lors de la connexion : ${errorMessage}`);
            } else {
                setError("Une erreur s'est produite lors de la connexion.");
            }
            }
          
      };
      const handleSubmit = (e) => {
        e.preventDefault(); // Empêcher la soumission par défaut du formulaire
        handleLogin(); // Appeler la fonction handleLogin pour effectuer la connexion
      };
    return (

        <>

            <TopNav />

            <main className="main-content  mt-0">
                <section>
                    <div className="page-header min-vh-100">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-4 col-lg-5 col-md-7 d-flex flex-column ms-auto me-auto ms-lg-auto me-lg-5">
                                    <div className="card card-plain">
                                        <div className="card-header" style={{textAlign:"center"}}>
                                            <h4 className="font-weight-bolder">Hey, welcome back!</h4>
                                            <p className="mb-0">Log in with your username and password.</p>
                                        </div>
                                        <div className="card-body" style={{paddingTop:"0px"}}>
                                            {/* Afficher le message d'erreur ici */}
                                            {error && (
                                            <div className="alert alert-danger" role="alert" style={{color:"#fff"}}>
                                                {error}
                                            </div>
                                            )}
                                            <form onSubmit={handleSubmit}>
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="input-group input-group-outline mb-3">

                                                                <Champs TypeChamp="email" name="email" value={email} onChange={(e) => { setEmail(e.target.value) }} placeholder="Email address" />

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="input-group input-group-outline mb-3">

                                                                 <PasswordField TypeChamp="password" name="motdepasse" value={motdepasse} onChange={(e) => { setMotdepasse(e.target.value) }} placeholder="Password" />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-check form-switch d-flex align-items-center mb-3">
                                                    <Choix checked={isChecked} onChange={handleCheckboxChange} />
                                                </div>
                                                <div className="text-center">
                                                    <Boutons valeur="Login" />
                                                </div>
                                            </form>

                                        </div>
                                        <div className="card-footer text-center pt-0 px-lg-2 px-1">
                                            <p className="mb-2 text-sm mx-auto">
                                            Don't have an account yet?
                                                <Link to="/registration" className="text-primary text-gradient font-weight-bold"> Register at </Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="col-6 d-lg-flex h-100 my-auto pe-0 top-0 start-0 text-center justify-content-center flex-column">
                                    <div
                                        className="login_img position-absolute bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center"
                                        style={{ backgroundImage: `url(${background})`, backgroundSize: "cover", width: "50%" }}>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </main>

        </>

    );
}