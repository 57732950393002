import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import Boutons from '../components/autentification/bouton';
import { useParams } from 'react-router-dom';
import { fetchUsersFromAPI } from '../datas/user';

export default function MiseEnReseauPoste() {
  const { posteId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCandidat, setIsLoadingCandidat] = useState(true);
  const [posteData, setPosteData] = useState(null);
  const [formattedUsers, setFormattedUsers] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [matching, setMatching] = useState({});
  const [alertMessages, setAlertMessages] = useState([]);
  const [user, setUser] = useState("");


  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().slice(0, 19).replace("T", " ");


  const handleInputChange = (inputValue) => {
    // Faites ici une recherche ou un filtrage en fonction de la valeur saisie dans le champ de recherche
  };

  const addAlertMessage = (message, type) => {
    // Ajoutez un nouvel message à la liste des alertes
    setAlertMessages([...alertMessages, { message, type }]);
    // Supprimez automatiquement le message après un certain délai (par exemple, 5 secondes)
    setTimeout(() => {
      removeAlertMessage();
    }, 5000); // 5000 millisecondes (5 secondes)
  };

  const removeAlertMessage = () => {
    // Supprimez le premier message de la liste des alertes
    if (alertMessages.length > 0) {
      const updatedMessages = [...alertMessages];
      updatedMessages.shift();
      setAlertMessages(updatedMessages);
      // Actualisez la page après avoir supprimé le message (délai de 5000 millisecondes)
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setMatching((prevMatching) => ({
      ...prevMatching,
      sharedUserId: selectedOption ? selectedOption.value : "",
    }));
  };

  const authToken = localStorage.getItem('token');

  const handleSubmit = async (e) => {
    e.preventDefault(); // Empêcher le formulaire de se soumettre par défaut

    try {
      console.log("Request Data:", {
        posteId: parseInt(posteId),
        sharedUserId: matching ? matching.sharedUserId : '',
      });

      const response = await axios.post(
        'https://cerebrotbt.com/api/admin/candidat/miseEnReseau',
        {
            posteId: parseInt(posteId),
          sharedUserId: matching ? matching.sharedUserId : '',
        },
        {
          headers: {
            'Authorization': `Bearer ${authToken}`
          }
        }
      );
      if (response.status === 201) {
        addAlertMessage("Mise en réseau effectué avec succès !", "success");
      }
      // Vous pouvez gérer la réponse ici (par exemple, afficher un message de succès)
      console.log('Mise en réseau effectué avec succès:', response.data);
    } catch (error) {
      // Afficher des messages d'erreur spécifiques dans la console pour le débogage
      addAlertMessage(`Erreur lors de la mise en réseau: ${error.response.data.message}`, "danger");
      console.error('Erreur lors de la mise en réseau:', error.message);
      console.error('Réponse de l\'erreur:', error.response);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://cerebrotbt.com/api/admin/poste/show/${posteId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
        const data = response.data;
        setPosteData(data);
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error.message);
      }
    };
    //fetchData();
    const userInfoJSON = localStorage.getItem("user");
    // Vérifiez si userInfoJSON est défini
    if (userInfoJSON) {
      // Parsez la chaîne JSON en un objet JavaScript
      const parsedUserInfo = JSON.parse(userInfoJSON);
      // Accédez à la propriété "nom" de l'objet
      const id = parsedUserInfo.id;
      setUser(id);
  } else {
      console.log('Aucune donnée utilisateur trouvée dans le local storage');
  }
  
    fetchUsersFromAPI().then((data) =>{
         const formatted = data ? data.map(user =>({
            value: user.id,
            label: user.nom +' '+user.prenoms
         })) : [];
        setFormattedUsers(formatted);
         setIsLoading(false);
    }) ;

    fetchData();
  }, [posteId]); // Mettez à jour lorsque candidatId change

  const handleRetour = () => {
    window.history.back();
  };

  return (
    <div>
      <h1 style={{ marginLeft: "50px" }}> <span onClick={handleRetour} title='retour' style={{ marginRight: "20px" }}>&laquo;</span>Partage de Poste</h1>
      {posteData ? (
        <div className='container'>
          {alertMessages.map((alert, index) => (
            <div key={index} className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
              {alert.message}
              <button
                type="button"
                className="btn-close"
                onClick={() => removeAlertMessage(index)}
                aria-label="Close"
              ></button>
            </div>
          ))}
          <div className='row'>
            <div className='col-md-5'>
              <p>Titre: <span style={{ fontWeight: 'bold' }}>{posteData.Poste.titre}</span></p>
              <p>description: <span style={{ fontWeight: 'bold' }}>{posteData.Poste.description}</span></p>
              <p>Client: <span style={{ fontWeight: 'bold' }}>{posteData.Poste.client.nom}</span></p>
              <p>Type de contrat: <span style={{ fontWeight: 'bold' }}>{posteData.Poste.contrat.nom}</span></p>
              <p>Adresse: <span style={{ fontWeight: 'bold' }}>{posteData.Poste.location}</span></p>
              <p>Type de remuneration: <span style={{ fontWeight: 'bold' }}>{posteData.Poste.remuneration.nom}</span></p>
              <p>type de présence office: <span style={{ fontWeight: 'bold' }}>{posteData.Poste.office ? posteData.Poste.office.nom : ""}</span></p>
            </div>
            <div className='col-md-5'>
              <form onSubmit={handleSubmit}>
                <div className="input-group input-group-outline mb-3">
                  <Select
                    options={formattedUsers}
                    isSearchable
                    onInputChange={handleInputChange}
                    onChange={handleChange}
                    value={selectedOption}
                    name="sharedUserId"
                    placeholder="Rechercher un utilisateur..."
                    className="col-md"
                  />
                </div>
                <Boutons type="submit" valeur="Mise en réseau" />
              </form>
            </div>
          </div>
        </div>
      ) : (
        <p>Chargement en cours...</p>
      )}
    </div>
  );
}
