import axios from 'axios';
import { MDBIcon } from 'mdbreact';
import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';



const dataPoste = {
  columns: [
    {
      label: 'Name',
      field: 'nom',
      sort: 'asc',
      width: 150,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'Job',
      field: 'poste',
      sort: 'asc',
      width: 270,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'Company',
      field: 'societes',
      sort: 'asc',
      width: 200,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'Location',
      field: 'localisation',
      sort: 'asc',
      width: 100,
      attributes: {
        className: 'text-center',
      },
    },
    {
        label: 'Salary',
        field: 'salaire',
        sort: 'asc',
        width: 100,
        attributes: {
          className: 'text-center',
        },
      },
    {
      label: 'Action',
      field: 'action',
      sort: 'asc',
      width: 150,
      attributes: {
        className: 'text-center',
      },
    },
  ],
  rows: [], // Initialisez avec un tableau vide pour les lignes
};


function handleDeletePost(id, callback) {
  if (window.confirm("Êtes-vous sûr de vouloir supprimer ce poste ?")) {
    // Demandez une confirmation avant de supprimer le poste
    const authToken = localStorage.getItem('token');

    axios.get(`https://cerebrotbt.com/api/admin/${id}`, {
      headers: {
        'Authorization': `Bearer ${authToken}`
      },
    })
      .then((response) => {
        // Gérez la réponse si nécessaire
        alert('Le poste a été supprimé avec succès');
        if (typeof callback === 'function') callback();
        // Vous pouvez également mettre à jour votre liste de postes ici
      })
      .catch((error) => {
        // Gérez les erreurs en cas de problème lors de la suppression
        console.error('Erreur lors de la suppression du poste :', error);
        if (typeof callback === 'function') callback();
      });
  }
}
export async function fetchPosteData() {
  try {
    const authToken = localStorage.getItem('token'); // Remplacez par le jeton d'authentification approprié

    // Effectuez une demande GET Axios à l'API
    const response = await axios.get('https://cerebrotbt.com/api/admin/matching/listeRejeter', {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    const postes = response.data.data ?? [];
    console.log('matching: ',postes)
    return postes;
    // En supposant que les données de réponse sont un tableau d'objets client, mettez à jour les lignes.
    // Ajustez cette partie en fonction de la structure réelle de la réponse de l'API.
  } catch (error) {
    console.error('Erreur lors de la récupération des données depuis l\'API :', error);
    return [];
  }
};

export function useTableMatchingEnRejeter() {
  const [configs, _setConfigs] = useState(dataPoste);

  useEffect(() => {
    (async () => {
      await load();
    })()
  }, []);

  const cb = async () => {
    const data = await fetchPosteData(configs);
    setConfigs(data)
  }

  const setConfigs = (data) => {
    _setConfigs({
      ...configs,
      rows: data.map((poste) => ({
        nom: (
          <div data-bs-toggle="modal" data-bs-target={`#exampleModal-${poste.id}`}>
            {poste.candidat.nom}
          </div>
        ),
        poste: (
          <div data-bs-toggle="modal" data-bs-target={`#exampleModal-${poste.id}`}>
            {poste.poste_libre.libelle}
          </div>
        ),
        societes: (
          <div data-bs-toggle="modal" data-bs-target={`#exampleModal-${poste.id}`}>
            {poste.client.nom}
          </div>
        ),
        localisation: (
          <div data-bs-toggle="modal" data-bs-target={`#exampleModal-${poste.id}`}>
            {poste.candidat.location}
          </div>
        ),
        salaire: (
            <div data-bs-toggle="modal" data-bs-target={`#exampleModal-${poste.id}`}>
              {poste.candidat.remunerationMax} €
            </div>
          ),
        action: (
          <div className='row'>
            <div className='col-md-12' data-bs-toggle="modal" data-bs-target={`#exampleModal-${poste.id}`}>
              <Link title='details' className='text-secondary font-weight-normal text-xs' data-toggle="tooltip" data-original-title="Details" /*onClick={() => handleDeletePost(poste.id, cb)}*/>
                <MDBIcon icon="eye" className="mdb-icon-fab mdb-color-danger" style={{marginLeft: "80px", marginRight:"20px"}}/>
              </Link>
            </div>
            <div className="modal fade" id={`exampleModal-${poste.id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">Les informations du client</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">

                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <table className="table table-bordered">
                            <tbody>
                              <tr>
                                <th scope="row">NOM</th>
                                <td>{poste.candidat.nom}</td>
                              </tr>
                              <tr>
                                <th scope="row">POSTES</th>
                                <td>{poste.poste_libre.libelle}</td>
                              </tr>
                              <tr>
                                <th scope="row">SOCIETES</th>
                                <td>{poste.client.nom}</td>
                              </tr>
                              <tr>
                                <th scope="row">LOCALISATION</th>
                                <td>{poste.candidat.location}</td>
                              </tr>
                              <tr>
                                <th scope="row">SALAIRE</th>
                                <td>{poste.candidat.remunerationMax} €</td>
                              </tr>
                              <tr>
                                <th scope="row">STATUT</th>
                                <td>
                                  <span className={`badge badge-sm bg-${poste.statut === "2" ? 'success' : poste.statut === "1" ? 'danger' : 'warning'}`}>
                                    {poste.statut === "2" ? 'Accepter' : poste.statut === "1" ? 'Rejeter' : 'En cours'}
                                  </span>
                                </td>
                              </tr>

                            </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">fermer</button>
                    </div>
                  </div>
                </div>
              </div>
          </div>

        ),
        
      }))

    })
  }

  const load = async () => {
    const data = await fetchPosteData(configs);
    setConfigs(data)
  }

  return [configs, load];
}
