import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import axios from 'axios';
import {MDBDataTable} from 'mdbreact';

import NavigationGauche from "../components/dashboard/navGauch";
import Nav from "../components/dashboard/nav";
import Champs from "../components/autentification/champs";
import Boutons from "../components/autentification/bouton";

//import DataManager from "../datas/table";
import {useTableManager} from "../datas/table";
import {fetchIndustriesFromAPI} from "../datas/industries";
import {fetchDomainesByIndustryFromAPI} from "../datas/domaines";
import {fetchContratsFromAPI} from '../datas/contrat';
import {fetchOfficeFromAPI} from '../datas/office';
import {fetchRemunerationFromAPI} from '../datas/remuneration';
import {useTableConfig} from '../datas/dataTable/tableCandidats';


import ChampsUrl from '../components/autentification/ChampsUrl';


export default function ListesCandidat() {

    const [config, load] = useTableConfig();

    const [isLoading, setIsLoading] = useState(true);
    const [formattedIndustries, setFormattedIndustries] = useState([]);
    const [formattedContrats, setformattedContrats] = useState([]);
    const [formattedOffices, setformattedOffices] = useState([]);
    const [formattedRemunerations, setformattedRemunerations] = useState([]);
    const [candidatId, setCandidatId] = useState(null);


    const [selectedIndustry, setSelectedIndustry] = useState([]);
    const [domainesForSelectedIndustry, setDomainesForSelectedIndustry] = useState([]);
   
    const options = [
        { value: 'privé', label: 'Privé' },
        { value: 'public', label: 'Public' },
      ];

    const customLanguage = {
        pagination: {
            label: "Affichage de {0}-{1} de {2} éléments",
            first: "Premier",
            last: "Dernier",
            previous: "Précédent",
            next: "Suivant",
        },
        search: "Chercher :",
        info: "Affichage de {0}-{1} de {2} éléments",
        infoEmpty: "Affichage de 0-0 de 0 éléments",
    };

    // a sup
    const [nom, setNom] = useState("");
    const [prenoms, setPrenoms] = useState("");
    const [email, setEmail] = useState("");
    const [telephone, setTelephone] = useState("");
    const [lienLinkedin, setLienLinkedin] = useState("");
    const [lienGithub, setLienGithub] = useState("");
    const [lienPortefolio, setLienPortefolio] = useState("");
    const [location, setLocation] = useState("");
    const [remunerationMin, setRemunerationMin] = useState("");
    const [remunerationMax, setRemunerationMax] = useState("");
    const [poste, setPoste] = useState("");
    const [statut, setStatut] = useState([]);
    const [cv, setCv] = useState("");
    const [contrat_id, setContrat_id] = useState([]);
    const [officePresence_id, setOfficePresence_id] = useState([]);
    const [remuneration_id, setRemuneration_id] = useState([]);
    const [user, setUser] = useState("");

    const [user_id, setUser_id] = useState(null);
    const [industry_id, setIndustry_id] = useState([]);

    const [selectedDomaines, setSelectedDomaines] = useState([]);

    // fin a sup
    const [candidat, setCandidat] = useState([]);

    const handleFileChange = (e) => {
        // Assurez-vous que vous utilisez le fichier lui-même au lieu de e.target.files[0]
        const file = e.target.value;
        console.log("cv :", file);
        setCv(file);
    };

    const [currentPage, setCurrentPage] = useState(1); // État pour suivre la page actuelle

    // Fonction pour gérer le passage à la page précédente
    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Fonction pour gérer le passage à la page suivante
    const handleNext = () => {
        // Remplacez 5 par le nombre de pages dans votre pagination
        if (currentPage < 5) {
            setCurrentPage(currentPage + 1);
        }
    };

    const [dataManagerConfig, setDataManagerConfig] = useState([]);
    // Fonction pour récupérer les domaines en fonction de l'industrie sélectionnée
    const fetchDomainesForIndustry = async (industryId) => {
        const data = await fetchDomainesByIndustryFromAPI(industryId);
        const formatted = data.domaines?.map((domaine) => ({
            value: domaine.id,
            label: domaine.nom,
        }));
        setDomainesForSelectedIndustry(formatted);
    };
    useEffect(() => {
        //fetchData();
        // Attendez le chargement des données avant de formater les industries
        fetchIndustriesFromAPI().then((data) => {
            const formatted = data.industries?.map(industry => ({
                value: industry.id,
                label: industry.nom,
            }));
            setFormattedIndustries(formatted);
            setIsLoading(false);
        });

        fetchContratsFromAPI().then((data) => {
            const formatted = data.contrats?.map(contrat => ({
                value: contrat.id,
                label: contrat.nom,
            }));
            setformattedContrats(formatted);
            setIsLoading(false);
        });
        fetchOfficeFromAPI().then((data) => {
            const formatted = data.officePresences?.map(office => ({
                value: office.id,
                label: office.nom,
            }));
            setformattedOffices(formatted);
            setIsLoading(false);
        });

        fetchRemunerationFromAPI().then((data) => {
            const formatted = data.remunerations?.map(remuneration => ({
                value: remuneration.id,
                label: remuneration.nom,
            }));
            setformattedRemunerations(formatted);
            setIsLoading(false);
        });
        // Récupérez la chaîne JSON depuis le local storage
        const userInfoJSON = localStorage.getItem("user");

        // Vérifiez si userInfoJSON est défini
        if (userInfoJSON) {
            // Parsez la chaîne JSON en un objet JavaScript
            const parsedUserInfo = JSON.parse(userInfoJSON);
            // Accédez à la propriété "nom" de l'objet
            const id = parsedUserInfo.id;
            setUser(id);
        } else {
            console.log('Aucune donnée utilisateur trouvée dans le local storage');
        }

    }, []);

    const handleRowClick = (entry) => {
        // Gérez les clics de ligne ici
        console.log('Row clicked:', entry);

    };

    if (isLoading) {
        return <div className="loader"></div>;
    }
    return (
        <>

            <NavigationGauche/>

            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <Nav/>
                <div className="container-fluid py-4">

                    <div className="row mt-4 pt-4">
                        <div className="col-md-12">
                            <div>
                                <span className="font-weight-bold mr-4">LISTE DE CANDIDATS</span>
                            </div>
                            {
                                config
                                    ? (
                                        <MDBDataTable
                                            striped
                                            bordered
                                            small
                                            entriesLabel="Voir les entrées"
                                            infoLabel={["Afficher", "à", "de", "éléments", "Affichage de {0}-{1} de {2} éléments",]}
                                            searchLabel="chercher"
                                            noRecordsFoundLabel="Aucun enregistrement n'a été trouvé"
                                            noBottomColumns
                                            paginationLabel={["P", "S"]}
                                            data={config}
                                            onRowClick={handleRowClick}
                                        />
                                    ) :
                                    <div className="loader"/>
                            }

                        </div>
                    </div>

                </div>
            </main>


        </>
    );
}
