import React, { useState, useEffect, useRef } from "react";
import Select from 'react-select';
import Axios from 'axios';
import makeAnimated from 'react-select/animated';
import { Link } from "react-router-dom";

import Nav from "../../components/dashboard/nav";
import NavigationGauche from "../../components/dashboard/navGauch";
import Champs from "../../components/autentification/champs";
import Boutons from "../../components/autentification/bouton";
import Textarea from '../../components/autentification/textarea'; 
import { useParams } from 'react-router-dom';

import { fetchIndustriesFromAPI } from "../../datas/industries";

export default function EditClient() {
  const [isLoading, setIsLoading] = useState(true);
  const animatedComponents = makeAnimated();
  const [alertMessages, setAlertMessages] = useState([]);

  const [formattedIndustries, setFormattedIndustries] = useState([]);
  const [nom, setNom] = useState("");
  const [description, setDescription] = useState("");
  const [postes, setPostes] = useState(1);
  const [statut, setStatut] = useState("");
  const [editorContent, setEditorContent] = useState('<p>Description</p>');
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [clients, setClients] = useState([]);
  const [posteValues, setPosteValues] = useState([]); 
  const options = [
    { value: 'lead', label: 'Lead' },
    { value: 'prospect', label: 'Prospect' },
    { value: 'active', label: 'Active' },
    { value: 'closed', label: 'Closed' },
  ];
  const { clientId } = useParams();
  const addAlertMessage = (message, type) => {
    // Ajoutez un nouvel message à la liste des alertes
    setAlertMessages([...alertMessages, { message, type }]);
  
    // Supprimez automatiquement le message après un certain délai (par exemple, 5 secondes)
    setTimeout(() => {
      removeAlertMessage();
    }, 5000); // 5000 millisecondes (5 secondes)
  };
  const removeAlertMessage = () => {
    // Supprimez le premier message de la liste des alertes
    if (alertMessages.length > 0) {
      const updatedMessages = [...alertMessages];
      updatedMessages.shift();
      setAlertMessages(updatedMessages);

    // Actualisez la page après avoir supprimé le message (délai de 5000 millisecondes)
    setTimeout(() => {
      window.location.reload();
    }, 5000);
    }
  };
    
  const handlePostesChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    if (!isNaN(newValue) && newValue >= 1) {
      setPostes(newValue);
    } else {
      setPostes(1);
    }
  };
  const handleEditorChange = (content) => {
    // Cette fonction sera appelée lorsque le contenu de l'éditeur TinyMCE changera
    setEditorContent(console.log("description: ",content));
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (!selectedIndustries) {
      console.error("Le champ 'industries' est requis. ");
      return;
    }
    // Assurez-vous que 'statut' est une chaîne de caractères avant de l'ajouter à formData
    const statutValue = statut ? statut.value : '';
    const formData = new FormData();
    formData.append('nom', nom);
    formData.append('description', description);
    formData.append('statut', statutValue);
    formData.append('nbr_postes_libres', postes);
    console.log("Valeurs de selectedIndustries :", selectedIndustries);
    selectedIndustries.forEach((selectedIndustries) => {
      formData.append('industries[]', selectedIndustries.value);
    });
    for (let i = 0; i < postes; i++) {
      formData.append(`postes_libres[${i}]`, posteValues[i] || ''); // Ajouter name attribut
    }
    const authToken = localStorage.getItem('token');
    const headers = {
      'Authorization': `Bearer ${authToken}`
    };

    Axios.post(`https://cerebrotbt.com/api/admin/client/update/${clientId}`
    , formData, { headers: headers, 'Content-Type': 'multipart/form-data' })
      .then((response) => {
        if (response.status === 201) {
          addAlertMessage("Client créé avec succès !", "success");
          setNom("");
          setDescription("");
          setStatut("");
          setPostes(1);
          setSelectedIndustries([]);
          setPosteValues([]);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la création du client :", error);
        addAlertMessage(`Erreur lors de la création du client . ${error.response.data.message}`, "danger");
      });
  };
  const handlePosteInputChange = (index, value) => {
    const newPosteValues = [...posteValues];
    newPosteValues[index] = value;
    setPosteValues(newPosteValues);
  };
  const champsInputs = [];
  for (let i = 1; i <= postes; i++) {
    champsInputs.push(
      <div key={i} className="col-md-12">
        <div className="input-group input-group-outline mb-3">
          <Champs
            TypeChamp="text"
            placeholder={`Titre poste ${i}`}
            name={`poste_${i}`} // Ajout de l'attribut name
            value={posteValues[i - 1] || ''}
            onChange={(e) => handlePosteInputChange(i - 1, e.target.value)}
          />
        </div>
      </div>
    );
  }

  const editorRef = useRef(null);

  useEffect(() => {
    if (clientId) {
    fetchIndustriesFromAPI().then((data) => {
      const formatted = data.industries.map(industry => ({
        value: industry.id,
        label: industry.nom,
      }));
      setFormattedIndustries(formatted);
      // Set the initial value for selectedIndustries based on the fetched data
      const initialSelectedIndustries =data.clientIndustries ? data.clientIndustries.map(clientIndustry => ({
         value: clientIndustry.id,
         label: clientIndustry.nom,
     })) 
     : [];
     console.log("initialSelectedIndustries: ",initialSelectedIndustries);
      setSelectedIndustries(initialSelectedIndustries);

      setIsLoading(false);
    });
    const authToken = localStorage.getItem('token');
    const headers = {
      'Authorization': `Bearer ${authToken}`
    };
  // Vérifier si 'id' est défini avant d'effectuer la requête
  console.log('id: ',clientId)
  
    // Récupérer le client
    Axios.get(`https://cerebrotbt.com/api/admin/client/show/${clientId}`, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          // Mettez à jour l'état des clients avec les données reçues
          setClients(response.data.Client);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération du client :", error);
      });
  }
}, [clientId]);

  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  if (isLoading) {
    return <div className="loader"></div>;
  }

  return (
    <>
      <NavigationGauche />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
        <Nav />
        <div className="container-fluid">
          <div className="row mx-7">
          {alertMessages.map((alert, index) => (
          <div key={index} className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
            {alert.message}
            <button
              type="button"
              className="btn-close"
              onClick={() => removeAlertMessage(index)}
              aria-label="Close"
            ></button>
          </div>
        ))}

            <div className="col-md-12 mb-lg-0 mb-4">
              <form role="form" onSubmit={handleFormSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-group input-group-outline mb-3">
                      <Champs TypeChamp="text" value={clients.nom || ''} onChange={(e) => { setNom(e.target.value) }} placeholder="Nom" />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-group input-group-outline mb-3">
                      <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isSearchable
                        isMulti
                        name="industry_id"
                        onChange={(selectedOption) => {
                          setSelectedIndustries(selectedOption); 
                        }}
                        value={selectedIndustries}
                        placeholder="Industrie"
                        options={formattedIndustries}
                        className="col"
                      />
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="input-group input-group-outline mb-3">
                      <Champs TypeChamp="number" value={clients.nbr_postes_libres || ''} onChange={handlePostesChange} placeholder="Nombres de postes libres" />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="input-group input-group-outline mb-3">
                      <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        options={options}
                        value={clients.statut || ''}
                        name="statut"
                        onChange={(selectedOption) => setStatut(selectedOption)}
                        placeholder="Statut"
                        className="col"
                      />
                    </div>
                  </div>
                  {champsInputs}
                </div>
                <div className="row">
                  <div className="col-md">
                    <div className="input-group input-group-outline mb-3">
                
                    <Textarea TypeChamp="text" value={clients.description || ''} onChange={(e) => { setDescription(e.target.value) }} placeholder="Description" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                      <Boutons type="submit" valeur="Enregistrer" />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
