import axios from 'axios';
import { MDBIcon } from 'mdbreact';
import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import file from "../../assets/img/file.png"


const tableConfigs = {
    columns: [
        {
            label: 'Last name',
            field: 'nom',
            sort: 'asc',
            width: 150,
            attributes: {
                className: 'text-center',
            },
        },
        {
            label: 'First names',
            field: 'prenoms',
            sort: 'asc',
            width: 270,
            attributes: {
                className: 'text-center',
            },
        },
        {
            label: 'Max. remuneration',
            field: 'remunerationmax',
            sort: 'asc',
            width: 270,
            attributes: {
                className: 'text-center',
            },
        },

        {
            label: 'CV',
            field: 'cv',
            sort: 'asc',
            width: 270,
            attributes: {
                className: 'text-center',
            },
        },

        {
            label: 'Job',
            field: 'poste',
            sort: 'asc',
            width: 270,
            attributes: {
                className: 'text-center',
            },
        },
        {
            label: 'Location',
            field: 'localisation',
            sort: 'asc',
            width: 270,
            attributes: {
                className: 'text-center',
            },
        },
        {
            label: 'Statut',
            field: 'statut',
            sort: 'asc',
            width: 270,
            attributes: {
                className: 'text-center',
            },
        },
        {
            label: 'Action',
            field: 'action',
            sort: 'asc',
            width: 150,
            attributes: {
                className: 'text-center',
            },
        },
    ],
    rows: [], // Initialisez avec un tableau vide pour les lignes
};

function matching(id) {
    // Définissez l'URL de modification en fonction de l'ID du domaine
    const urlModification = `/matching-candidat/${id}`; // Remplacez cette URL par la route de modification appropriée
    return (
        <div className='col-md-3'>
            <Link title='Matching' to={urlModification} className="text-secondary font-weight-normal text-xs" data-toggle="tooltip"
                data-original-title="Matching">
                <MDBIcon icon="hands-helping" className="mdb-icon-fab mdb-color-success" />
            </Link>
        </div>
    );
}
function modifier(id) {
    // Définissez l'URL de modification en fonction de l'ID du domaine
    const urlModification = `/edit/candidat/${id}`; // Remplacez cette URL par la route de modification appropriée
    return (
        <div className='col-md-3'>
            <Link title='Modifier' to={urlModification} className="text-secondary font-weight-normal text-xs" data-toggle="tooltip"
                data-original-title="Modifier">
                <MDBIcon icon="edit" className="mdb-icon-fab mdb-color-success" />
            </Link>
        </div>
    );
}
function modifierInvited(id) {
    // Définissez l'URL de modification en fonction de l'ID du domaine
    const urlModification = `/edit/candidat/${id}`; // Remplacez cette URL par la route de modification appropriée
    return (
        <div className='col-md-4'>
            <Link title='Modifier' to={urlModification} className="text-secondary font-weight-normal text-xs" data-toggle="tooltip"
                data-original-title="Modifier">
                <MDBIcon icon="edit" className="mdb-icon-fab mdb-color-success" />
            </Link>
        </div>
    );
}
function miseEnReseau(id) {
    const urlMiseEnReseau = `/mise-en-reseau-candidat/${id}`; // Remplacez cette URL par la route de modification appropriée

    return (
        <div className='col-md-3'>
            <Link title='Mise en réseau' to={urlMiseEnReseau} className="text-secondary font-weight-normal text-xs" data-toggle="tooltip" data-original-title="Modifier">
                <MDBIcon icon="exchange-alt" className="mdb-icon-fab mdb-color-success" />
            </Link>
        </div>
    );
}
function miseEnReseauInvited(id) {
    const urlMiseEnReseau = `/mise-en-reseau-candidat/${id}`; // Remplacez cette URL par la route de modification appropriée

    return (
        <div className='col-md-4'>
            <Link title='Mise en réseau' to={urlMiseEnReseau} className="text-secondary font-weight-normal text-xs" data-toggle="tooltip" data-original-title="Modifier">
                <MDBIcon icon="exchange-alt" className="mdb-icon-fab mdb-color-success" />
            </Link>
        </div>
    );
}
function handleDeleteCandidat(id, callback) {

    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce candidat ?")) {
        // Demandez une confirmation avant de supprimer le candidat
        const authToken = localStorage.getItem('token');

        axios.get(`https://cerebrotbt.com/api/admin/candidat/delete/${id}`, {
            headers: {
                'Authorization': `Bearer ${authToken}`
            },
        })
            .then((response) => {
                // Gérez la réponse si nécessaire
                alert('Le candidat a été supprimé avec succès');
                if (typeof callback === 'function') callback();
                // Vous pouvez également mettre à jour votre liste de candidats ici
            })
            .catch((error) => {
                // Gérez les erreurs en cas de problème lors de la suppression
                console.error('Erreur lors de la suppression du candidat :', error);
                if (typeof callback === 'function') callback();
            });
    }
}

export async function fetchCandidatData() {
    try {
        const authToken = localStorage.getItem('token'); // Remplacez par le jeton d'authentification approprié

        // Effectuez une demande GET Axios à l'API
        const response = await axios.get('https://cerebrotbt.com/api/admin/candidat/index', {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });
        // En supposant que les données de réponse sont un tableau d'objets client, mettez à jour les lignes.
        // Ajustez cette partie en fonction de la structure réelle de la réponse de l'API.
        const candidats = response.data?.candidats ?? [];
        { console.log('data: ', candidats) }

        return candidats;
    } catch (error) {
        console.error('Erreur lors de la récupération des données depuis l\'API :', error);
        return [];
    }
}

export function useTableConfig() {
    const [configs, _setConfigs] = useState(tableConfigs);
    const userFrom = localStorage.getItem('userFrom')

    useEffect(() => {
        (async () => {
            await load();
        })()
    }, []);

    const cb = async () => {
        const data = await fetchCandidatData(configs);
        setConfigs(data)
    }

    const setConfigs = (data) => {
        _setConfigs({
            ...configs,
            rows: data.map((candidat) => ({

                nom: (
                    <div data-bs-toggle="modal" data-bs-target={`#exampleModal-${candidat.id}`}>
                        {candidat.nom}
                    </div>
                ),
                prenoms: (
                    <div data-bs-toggle="modal" data-bs-target={`#exampleModal-${candidat.id}`}>
                        {candidat.prenoms}
                    </div>
                ),
                remunerationmax: (
                    <div data-bs-toggle="modal" data-bs-target={`#exampleModal-${candidat.id}`}>
                        {candidat.remunerationMax}
                    </div>
                ),
                cv: (
                    <div data-bs-toggle="modal" data-bs-target={`#exampleModal-${candidat.id}`}>
                        {candidat.cv_path ?
                            <img className="w-30 me-3 mb-0" src={file} alt="logo" style={{
                                marginLeft: "50px !important",
                                marginRight: "30px !important", width: "30% !important"
                            }} />
                            : ''}
                    </div>
                ),
                poste: (
                    <div data-bs-toggle="modal" data-bs-target={`#exampleModal-${candidat.id}`}>
                        {candidat.poste}
                    </div>
                ),
                localisation: (
                    <div data-bs-toggle="modal" data-bs-target={`#exampleModal-${candidat.id}`}>
                        {candidat.location}
                    </div>
                ),
                statut: (
                    <div data-bs-toggle="modal" data-bs-target={`#exampleModal-${candidat.id}`}>

                        <span className={`badge badge-sm bg-${candidat.statut === 'public' ? 'gradient-success' : 'secondary'}`}>
                            {candidat.statut}
                        </span>
                    </div>


                ),
                action: (
                    <div className='row'>
                        {userFrom === "self" ? 
                        <>
                        {miseEnReseauInvited(candidat.id)}
                        {modifierInvited(candidat.id)}
                        <div className='col-md-4'>
                            <Link
                                title='Supprimer'
                                className='text-secondary font-weight-normal text-xs'
                                data-toggle="tooltip"
                                data-original-title="Supprimer"
                                onClick={() => handleDeleteCandidat(candidat.id, cb)}
                            >
                                <MDBIcon icon="trash-alt" className="mdb-icon-fab mdb-color-danger" />
                            </Link>

                        </div>
                        </>
                        : 
                        <>
                        {matching(candidat.id)}
                        {miseEnReseau(candidat.id)}
                        {modifier(candidat.id)}
                        <div className='col-md-3'>
                            <Link
                                title='Supprimer'
                                className='text-secondary font-weight-normal text-xs'
                                data-toggle="tooltip"
                                data-original-title="Supprimer"
                                onClick={() => handleDeleteCandidat(candidat.id, cb)}
                            >
                                <MDBIcon icon="trash-alt" className="mdb-icon-fab mdb-color-danger" />
                            </Link>

                        </div>
                        </>}
                        <div className="modal fade" id={`exampleModal-${candidat.id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Les informations du candidat</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">

                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <table className="table table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row">NOM</th>
                                                                <td>{candidat.nom}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">PRENOMS</th>
                                                                <td>{candidat.prenoms}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">EMAIL</th>
                                                                <td>{candidat.email}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">TELEPHONE</th>
                                                                <td>{candidat.telephone}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">TYPE REMUNERATION</th>
                                                                <td>{candidat.remunerations.nom}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">REMUNERATION MAX</th>
                                                                <td>{candidat.remunerationMax}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">REMUNERATION MIN</th>
                                                                <td>{candidat.remunerationMin}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">POSTE</th>
                                                                <td>{candidat.poste}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">ADRESSE</th>
                                                                <td>{candidat.location}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">GITHUB</th>
                                                                <td>{candidat.lienGithub ? candidat.lienGithub : 'Aucun'}</td>
                                                            </tr><tr>
                                                                <th scope="row">LINKEDIN</th>
                                                                <td>{candidat.lienLinkedin ? candidat.lienLinkedin : 'Aucun'}</td>
                                                            </tr><tr>
                                                                <th scope="row">PORTE_FOLIO</th>
                                                                <td>{candidat.lienPortefolio ? candidat.lienPortefolio : 'Aucun'}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">INDUSTRIE</th>
                                                                <td>{candidat.industries.nom}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">DOMAINE</th>
                                                                <td>
                                                                    {candidat.domaines.map((domaine) => domaine.nom).join(',\n')}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">OFFICE PRESENCE</th>
                                                                <td>{candidat.office_presences.nom}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">TYPE DE CONTRAT</th>
                                                                <td>{candidat.contrat.nom}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">fermer</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                ),
            }))
        })
    }

    const load = async () => {
        const data = await fetchCandidatData(configs);
        setConfigs(data)
    }

    return [configs, load];
}
