


export default function Choix({ onChange , checked}) {
    return (

        <>
           
            <input className="form-check-input" onChange={onChange} type="checkbox" id="rememberMe" checked={checked}/>
            <label className="form-check-label mb-0 ms-3" htmlFor="rememberMe">Remember me</label>
        </>
    );
}