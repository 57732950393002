import React, {useState, useEffect} from 'react';
import TopNav from "../components/autentification/topnav";
import contact from "../assets/img/contact.jpg"
import Champs from "../components/autentification/champs";
import Textarea from "../components/autentification/textarea";
import Boutons from '../components/autentification/bouton';

export default function Contact() {

    const [nom, setNom] = useState("");
    const [prenoms, setPrenoms] = useState("");
    const [email, setEmail] = useState("");
    const [numero, setNumero] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");


    return (
        <>
            <TopNav />
            <main className="main-content  mt-9">
                <h1 style={{ textAlign: "center" }}>CONTACT</h1>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <form>
                                    <div className="row">
                                        <div className="input-group input-group-outline mb-3">
                                            <Champs TypeChamp="text" value={nom} onChange={(e) => {
                                                setNom(e.target.value)
                                            }} placeholder="Last name" />

                                        </div>
                                        <div className="input-group input-group-outline mb-3">
                                            <Champs TypeChamp="text" value={prenoms} onChange={(e) => {
                                                setPrenoms(e.target.value)
                                            }} placeholder="First names" />

                                        </div>
                                        <div className="input-group input-group-outline mb-3">
                                            <Champs TypeChamp="text" value={numero} onChange={(e) => {
                                                setNumero(e.target.value)
                                            }} placeholder="Phone" />

                                        </div>
                                        <div className="input-group input-group-outline mb-3">
                                            <Champs TypeChamp="email" value={email} onChange={(e) => {
                                                setEmail(e.target.value)
                                            }} placeholder="Email" />

                                        </div>
                                        <div className="input-group input-group-outline mb-3">
                                            <Champs TypeChamp="text" value={subject} onChange={(e) => {
                                                setSubject(e.target.value)
                                            }} placeholder="Subject" />

                                        </div>
                                        <div className="input-group input-group-outline mb-3">
                                            <Textarea name="description" value={message} onChange={(e) => {
                                                setMessage(e.target.value)
                                            }} placeholder="Message" />

                                        </div>
                                        <div className="col-md-3">
                                        <div className="input-group input-group-outline mb-3">
                                            <Boutons type="submit" valeur="Send"/>
                                        </div>
                                    </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-6">
                                <p style={{ textAlign: "justify" }}>
                                For further information or support. Please contact us at the following address: <span style={{ fontWeight: 'bold' }}>info@cerebrotbt.com</span>  Thank you.
                                </p>
                                <img src={contact} alt="Contact" style={{ width: "56%",marginLeft: "138px"}} className="mb-4" />
                            </div>
                        </div>
                    </div>

                </section>
            </main>
        </>
    )
}