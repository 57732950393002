import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import axios from 'axios';
import {MDBDataTable} from 'mdbreact';

import Nav from "../../components/dashboard/nav";
import Champs from "../../components/autentification/champs";
import Boutons from "../../components/autentification/bouton";

import NavigationGauche from '../../components/dashboard/navGauch';

//import DataManager from "../datas/table";
import {useTableManager} from "../../datas/table";
import {fetchIndustriesFromAPI} from "../../datas/industries";
import {fetchDomainesByIndustryFromAPI} from "../../datas/domaines";
import {fetchContratsFromAPI} from '../../datas/contrat';
import {fetchOfficeFromAPI} from '../../datas/office';
import {fetchRemunerationFromAPI} from '../../datas/remuneration';
import {useTableConfig} from '../../datas/dataTable/tableCandidats';
import { useParams } from "react-router-dom";



import ChampsUrl from '../../components/autentification/ChampsUrl';


export default function EditCandidat() {

    const [config, load] = useTableConfig();

    const [isLoading, setIsLoading] = useState(true);
    const [formattedIndustries, setFormattedIndustries] = useState([]);
    const [formattedContrats, setformattedContrats] = useState([]);
    const [formattedOffices, setformattedOffices] = useState([]);
    const [formattedRemunerations, setformattedRemunerations] = useState([]);
    const [candidatId, setCandidatId] = useState(null);
    const [alertMessages, setAlertMessages] = useState([]);


    const { candidatIds } = useParams();


    const [selectedIndustry, setSelectedIndustry] = useState([]);
    const [domainesForSelectedIndustry, setDomainesForSelectedIndustry] = useState([]);
   
    const options = [
        { value: 'privé', label: 'Privé',disabled: true },
        { value: 'public', label: 'Public',disabled: true },
      ];
   const disponibility = [
        { value: 'inprocess', label: 'In process' },
        { value: 'interview', label: 'Interview' },
        { value: 'recruited', label: 'Recruited' },
        { value: 'rejected', label: 'Rejected' },
      
      ];
    const customLanguage = {
        pagination: {
            label: "Affichage de {0}-{1} de {2} éléments",
            first: "Premier",
            last: "Dernier",
            previous: "Précédent",
            next: "Suivant",
        },
        search: "Chercher :",
        info: "Affichage de {0}-{1} de {2} éléments",
        infoEmpty: "Affichage de 0-0 de 0 éléments",
    };

    // a sup
    const [nom, setNom] = useState("");
    const [prenoms, setPrenoms] = useState("");
    const [email, setEmail] = useState("");
    const [telephone, setTelephone] = useState("");
    const [lienLinkedin, setLienLinkedin] = useState("");
    const [lienGithub, setLienGithub] = useState("");
    const [lienPortefolio, setLienPortefolio] = useState("");
    const [location, setLocation] = useState("");
    const [remunerationMin, setRemunerationMin] = useState("");
    const [remunerationMax, setRemunerationMax] = useState("");
    const [poste, setPoste] = useState("");
    const [statut, setStatut] = useState([]);
    const [disponible, setDisponible] = useState({ value: '', label: '' });
    const [cv, setCv] = useState("");
    const [contrat_id, setContrat_id] = useState([]);
    const [officePresence_id, setOfficePresence_id] = useState([]);
    const [remuneration_id, setRemuneration_id] = useState([]);
    const [user, setUser] = useState("");
    const [isDisabled, setIsDisabled] = useState(false);
    const [isCandidatLoading, setIsCandidatLoading] = useState(true);


    const [user_id, setUser_id] = useState(null);
    const [industry_id, setIndustry_id] = useState([]);

    const [selectedDomaines, setSelectedDomaines] = useState([]);

    // fin a sup
    const [candidat, setCandidat] = useState([]);

    const handleFileChange = (e) => {
        // Assurez-vous que vous utilisez le fichier lui-même au lieu de e.target.files[0]
        const file = e.target.value;
        console.log("cv :", file);
        setCv(file);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const token = localStorage.getItem('token');
            const formData = { disponibilite: disponible.value};
            // Créez un objet de configuration pour l'en-tête
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            console.log("Données: ", formData);
            console.log("user_id: ", user);

            // Envoyez la requête POST avec Axios
            const response = await axios.put(`https://cerebrotbt.com/api/admin/candidat/update/${candidatIds}`,formData,
                config
            );
            // Traitez la réponse ici (par exemple, affichez un message de succès)
            alert(response.data.message);

            await load();

        } catch (error) {
            // Gérez les erreurs ici
            if (error.response) {
                // La requête a été faite, mais le serveur a répondu avec un code d'erreur
                console.error('Réponse d\'erreur du serveur:', error.response.data);
                alert(`Erreur: ${error.response.data.message}`);
            } else if (error.request) {
                // La requête a été faite, mais aucune réponse n'a été reçue
                console.error('Aucune réponse reçue:', error.request);
                alert('Aucune réponse du serveur');
            } else {
                // Une erreur s'est produite lors de la configuration de la requête
                console.error('Erreur de configuration de la requête:', error.message);
                alert('Erreur de configuration de la requête');
            }
        }
    };

    const [currentPage, setCurrentPage] = useState(1); // État pour suivre la page actuelle

    // Fonction pour gérer le passage à la page précédente
    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Fonction pour gérer le passage à la page suivante
    const handleNext = () => {
        // Remplacez 5 par le nombre de pages dans votre pagination
        if (currentPage < 5) {
            setCurrentPage(currentPage + 1);
        }
    };
    const removeAlertMessage = () => {
        // Supprimez le premier message de la liste des alertes
        if (alertMessages.length > 0) {
          const updatedMessages = [...alertMessages];
          updatedMessages.shift();
          setAlertMessages(updatedMessages);
        // Actualisez la page après avoir supprimé le message (délai de 5000 millisecondes)
        setTimeout(() => {
          window.location.reload();
        }, 5000);
        }
      };
    const addAlertMessage = (message, type) => {
        // Ajoutez un nouvel message à la liste des alertes
        setAlertMessages([...alertMessages, { message, type }]);
        // Supprimez automatiquement le message après un certain délai (par exemple, 5 secondes)
        setTimeout(() => {
          removeAlertMessage();
        }, 5000); // 5000 millisecondes (5 secondes)
      };

    const [dataManagerConfig, setDataManagerConfig] = useState([]);
    // Fonction pour récupérer les domaines en fonction de l'industrie sélectionnée
    const fetchDomainesForIndustry = async (industryId) => {
        const data = await fetchDomainesByIndustryFromAPI(industryId);
        const formatted = data.domaines?.map((domaine) => ({
            value: domaine.id,
            label: domaine.nom,
        }));
        setDomainesForSelectedIndustry(formatted);
    };
    useEffect(() => {
        setIsCandidatLoading(true);
        if (candidatIds) {
            // Récupérez les données du serveur pour le candidat avec cet ID
            axios
                .get(`https://cerebrotbt.com/api/admin/candidat/show/${candidatIds}`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                })
                .then((response) => {
                    // Mettez à jour l'état du candidat
                    setCandidat(response.data.Candidat);
                    // Mettez à jour l'état de disponible avec la valeur du serveur si disponible
                    if (response.data.Candidat && response.data.Candidat.disponibilite) {
                        setDisponible({ value: response.data.Candidat.disponibilite, label: response.data.Candidat.disponibilite });
                    } else {
                        // Réinitialisez l'état de disponible à une valeur par défaut s'il n'y a pas de disponibilité
                        setDisponible({ value: '', label: '' });
                    }
                })
                .catch((error) => {
                    console.error('Erreur lors de la récupération du candidat :', error);
                    addAlertMessage(`Erreur lors de la modification du candidat . ${error.response.data.message}`, "danger");
                    setIsCandidatLoading(false);
                });
        }

        // Attendez le chargement des données avant de formater les industries
        fetchIndustriesFromAPI().then((data) => {
            const formatted = data.industries?.map(industry => ({
                value: industry.id,
                label: industry.nom,
            }));
            setFormattedIndustries(formatted);
            setIsLoading(false);
        });

        fetchContratsFromAPI().then((data) => {
            const formatted = data.contrats?.map(contrat => ({
                value: contrat.id,
                label: contrat.nom,
            }));
            setformattedContrats(formatted);
            setIsLoading(false);
        });
        fetchOfficeFromAPI().then((data) => {
            const formatted = data.officePresences?.map(office => ({
                value: office.id,
                label: office.nom,
            }));
            setformattedOffices(formatted);
            setIsLoading(false);
        });

        fetchRemunerationFromAPI().then((data) => {
            const formatted = data.remunerations?.map(remuneration => ({
                value: remuneration.id,
                label: remuneration.nom,
            }));
            setformattedRemunerations(formatted);
            setIsLoading(false);
        });
        // Récupérez la chaîne JSON depuis le local storage
        const userInfoJSON = localStorage.getItem("user");

        // Vérifiez si userInfoJSON est défini
        if (userInfoJSON) {
            // Parsez la chaîne JSON en un objet JavaScript
            const parsedUserInfo = JSON.parse(userInfoJSON);
            // Accédez à la propriété "nom" de l'objet
            const id = parsedUserInfo.id;
            setUser(id);
        } else {
            console.log('Aucune donnée utilisateur trouvée dans le local storage');
        }

    }, [candidatIds]);

    const handleRowClick = (entry) => {
        // Gérez les clics de ligne ici
        console.log('Row clicked:', entry);

    };

    if (isLoading) {
        return <div className="loader"></div>;
    }
    return (
        <>

            <NavigationGauche/>

            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
                <Nav/>
                <div className="container-fluid py-4">

                    <div className="row">
                        <div className="col-md-12 mb-lg-0 mb-4">

                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    {/*<div className="col-md-4">
                                        <div className="input-group input-group-outline mb-3">
                                            <Champs TypeChamp="text" value={candidat.nom} onChange={(e) => setNom({ ...candidat, nom: e.target.value})} placeholder="Nom" disabled/>
                                        </div>
                                        <div className="input-group input-group-outline mb-3">
                                            <Champs TypeChamp="text" value={candidat.prenoms} onChange={(e) =>  setPrenoms({ ...candidat, prenoms:e.target.value})} placeholder="Prénom(s)" disabled/>

                                        </div>
                                        <div className="input-group input-group-outline mb-3">
                                            <Champs TypeChamp="email" value={candidat.email} onChange={(e) => {
                                                setEmail(e.target.value)
                                            }} placeholder="Adresse email" disabled/>
                                        </div>
                                        <div className="input-group input-group-outline mb-3">
                                            <Champs TypeChamp="tel" value={candidat.telephone}
                                                    onChange={(e) => setTelephone(e.target.value)}
                                                    placeholder="Téléphone" disabled/>

                                        </div>
                                        <div className="input-group input-group-outline mb-3">
                                            <Champs TypeChamp="text" value={candidat.location}
                                                    onChange={(e) => setLocation(e.target.value)}
                                                    placeholder="Localisation" disabled/>
                                        </div>
                                        <div className="input-group input-group-outline mb-3">
                                            <Champs TypeChamp="text" value={candidat.poste}
                                                    onChange={(e) => setPoste(e.target.value)} placeholder="Poste" disabled/>

                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="input-group input-group-outline mb-3">
                                          
                                            <Select
                                                options={formattedIndustries}
                                                isSearchable
                                                onChange={(selectedOption) => {
                                                    setSelectedIndustry(selectedOption);
                                                    // Appelez la fonction pour récupérer les domaines en fonction de l'industrie
                                                    fetchDomainesForIndustry(selectedOption.value);
                                                }}
                                                value={selectedIndustry}
                                                name="industry_id"
                                                placeholder="Industrie"
                                                className="col-md"
                                                disabled/>

                                        </div>
                                        <div className="input-group input-group-outline mb-3">
                                            <Select
                                                options={domainesForSelectedIndustry}
                                                isSearchable
                                                isMulti={true}
                                                onChange={(selectedOption) => {
                                                    setSelectedDomaines(selectedOption)
                                                }}
                                                value={selectedDomaines}
                                                name="domaine_id"
                                                placeholder="Domaine"
                                                className="col-md"
                                            />

                                        </div>
                                        <div className="input-group input-group-outline mb-3">
                                            <Select
                                                options={formattedContrats}
                                                isSearchable
                                                onChange={(selectedOption) => setContrat_id(selectedOption)}
                                                value={contrat_id}
                                                name="contrat_id"
                                                placeholder="Type de contrat"
                                                className="col-md"
                                            />
                                        </div>
                                        <div className="input-group input-group-outline mb-3">
                                            <Select
                                                options={formattedOffices}
                                                isSearchable
                                                onChange={(selectedOption) => setOfficePresence_id(selectedOption)}
                                                value={officePresence_id}
                                                name="officePresence_id"
                                                placeholder="Type de présence au bureau"
                                                className="col-md"
                                            />
                                        </div>
                                        <div className="input-group input-group-outline mb-3">
                                            <Select
                                                options={formattedRemunerations}
                                                isSearchable
                                                onChange={(selectedOption) => setRemuneration_id(selectedOption)}
                                                value={remuneration_id}
                                                name="remuneration_id"
                                                placeholder="Type de rémunération"
                                                className="col-md"
                                            />
                                        </div>
                                        <div className="input-group input-group-outline mb-3">
                                            <Champs TypeChamp="text" value={candidat.remunerationMin}
                                                    onChange={(e) => setRemunerationMin(e.target.value)}
                                                    placeholder="Rémunération min." disabled/>

                                        </div>
                                    </div>*/}

                                    <div className="col-md-4">


                                       {/* <div className="input-group input-group-outline mb-3">
                                            <input
                                                type="file"
                                                id="cvFile"
                                                className="form-control"
                                                onChange={handleFileChange}
                                                accept=".pdf"
                                            />
                                        </div>

                                        <div className="input-group input-group-outline mb-3">
                                            <ChampsUrl TypeChamp="url" value={candidat.lienLinkedin}
                                                       onChange={(e) => setLienLinkedin(e.target.value)}
                                                       placeholder="lien linkedin" pattern="https://.*" disabled/>
                                        </div>
                                        <div className="input-group input-group-outline mb-3">
                                            <ChampsUrl TypeChamp="url" value={candidat.lienGithub}
                                                       onChange={(e) => setLienGithub(e.target.value)}
                                                       placeholder="lien github" pattern="https://.*" disabled/>
                                        </div>
                                        <div className="input-group input-group-outline mb-3">
                                            <ChampsUrl TypeChamp="url" value={candidat.lienPortefolio}
                                                       onChange={(e) => setLienPortefolio(e.target.value)}
                                                       placeholder="lien de portefolio" pattern="https://.*" disabled/>
                                        </div>
                                        <div className="input-group input-group-outline mb-3">
                                            <Champs TypeChamp="text" value={candidat.remunerationMax}
                                                    onChange={(e) => setRemunerationMax(e.target.value)}
                                                    placeholder="Rémunération max." disabled/>
                                        </div>
                                        <div className="input-group input-group-outline mb-3">
                                        <Select
                                                options={options}
                                                isSearchable
                                                onChange={(selectedOption) => setStatut(selectedOption)}
                                                value={statut}
                                                name="statut"
                                                placeholder="Choisir un statut"
                                                className="col-md"
                                                isOptionDisabled={(option) => option.disabled}/>
                                        </div>*/}
                                        <div className="input-group input-group-outline mb-3">
                                        <h5>Change availability</h5>
                                        </div>
                                        <div className="input-group input-group-outline mb-3">
                                        <Select
                                                options={disponibility}
                                                isSearchable
                                                onChange={(selectedOption) => setDisponible(selectedOption)}
                                                value={disponible}
                                                name="disponibilite"
                                                placeholder="Choisir une disponibilite"
                                                className="col-md"
                                                />
                                        </div>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="input-group input-group-outline mb-3">
                                            <Boutons type="submit" valeur="Enregistrer"/>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>

                    
                </div>
            </main>


        </>
    );
}
