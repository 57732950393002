import axios from 'axios';
import { MDBIcon } from 'mdbreact';
import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import Select from 'react-select';
import { Button } from 'primereact/button';
import Boutons from '../../components/autentification/bouton';




const dataPoste = {
  columns: [
    {
      label: 'Last name & First name',
      field: 'nom',
      sort: 'asc',
      width: 150,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'Job',
      field: 'poste',
      sort: 'asc',
      width: 270,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'Company',
      field: 'societes',
      sort: 'asc',
      width: 200,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'Location',
      field: 'localisation',
      sort: 'asc',
      width: 100,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'Salary',
      field: 'salaire',
      sort: 'asc',
      width: 100,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'Action',
      field: 'action',
      sort: 'asc',
      width: 150,
      attributes: {
        className: 'text-center',
      },
    },
  ],
  rows: [], // Initialisez avec un tableau vide pour les lignes
};



export async function fetchPosteData() {
  try {
    const authToken = localStorage.getItem('token'); // Remplacez par le jeton d'authentification approprié

    // Effectuez une demande GET Axios à l'API
    const response = await axios.get('https://cerebrotbt.com/api/admin/matching/listeAccepter', {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    const postes = response.data.data ?? [];
    console.log('matching: ', postes)
    return postes;
    // En supposant que les données de réponse sont un tableau d'objets client, mettez à jour les lignes.
    // Ajustez cette partie en fonction de la structure réelle de la réponse de l'API.
  } catch (error) {
    console.error('Erreur lors de la récupération des données depuis l\'API :', error);
    return [];
  }
};
function modifier(id) {
  // Définissez l'URL de modification en fonction de l'ID du domaine
  const urlModification = `/edit/candidat/${id}`; // Remplacez cette URL par la route de modification appropriée

  return (
    <div className='col-md-3'>
      <Link title='Modification' to={urlModification} className="text-secondary font-weight-normal text-xs" data-toggle="tooltip" data-original-title="Edit">
      <span className="badge badge-sm bg-warning" style={{paddingTop: "8.94px",
  paddingBottom: "9.94px",
  paddingLeft: "9.72px",
  paddingRight: "9.72px"}}>change availability</span>
      </Link>
    </div>
  );
}
function interview(id) {
  // Définissez l'URL de modification en fonction de l'ID du domaine
  const urlModification = `/edit/candidat/${id}`; // Remplacez cette URL par la route de modification appropriée

  return (
    <div className='col-md-3'>
      <Link title='Modification' to={urlModification} className="text-secondary font-weight-normal text-xs" data-toggle="tooltip" data-original-title="Edit">
      <span  className="badge badge-sm bg-dark" style={{paddingTop: "8.94px",
  paddingBottom: "9.94px",
  paddingLeft: "9.72px",
  paddingRight: "9.72px"}}>Interview</span>
      </Link>
    </div>
  );
}
export function useTableMatchingEnAccepter() {
  const [configs, _setConfigs] = useState(dataPoste);
  const [disponibilite, setDisponibilite] = useState({});
  const [selectedPoste, setSelectedPoste] = useState(null); // Ajout de l'état pour stocker le poste sélectionné
  const [candidatId, setCandidatId] = useState({});
  const options = [
    { value: 'inprocess', label: 'In process' },
    { value: 'interview', label: 'Interview' },
    { value: 'recruited', label: 'Recruited' },
    { value: 'rejected', label: 'Rejected' },
  
  ];
  const [formData, setFormData] = useState({
    disponibilite: null,
  });
  const handleInputChange = (selectedOption) => {
    setFormData({
      //console.log('formData:',formData),
      ...formData,
      disponibilite: selectedOption,
    });
    console.log('dispo:', selectedOption)
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const authToken = localStorage.getItem('token');
      // Vérifiez si formData.disponibilite est défini et n'est pas null avant d'accéder à sa propriété value
      if (formData.disponibilite && formData.disponibilite.value) {
        const reponse = await axios.put(`https://cerebrotbt.com/api/admin/candidat/update/${candidatId}`, {
          disponibilite: formData.disponibilite.value
        }, {
          headers: {
            'Authorization': `Bearer ${authToken}`
          }
        });
        alert(reponse.data.message)
        // Recharger les données après la mise à jour
      } else {
        console.error('La disponibilité sélectionnée est null ou non définie.');
        // Gérer cette condition de manière appropriée dans votre application
      }
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la disponibilité du candidat :', error);
      // Gérer les erreurs de mise à jour de la disponibilité ici
    }
}


  console.log('Disponibilite sélectionnée dans le set:', disponibilite)


  useEffect(() => {
    (async () => {
      await load();
    })()
  }, []);

  const cb = async (poste) => {
    setSelectedPoste(poste); // Mettre à jour l'état avec le poste sélectionné
    const data = await fetchPosteData(configs);
    setConfigs(data)
  }
  const handleRowClick = (postId) => {
    const selectedPoste = configs.rows.find(poste => poste.id === postId);
    setSelectedPoste(selectedPoste);
  };
  
  
  const setConfigs = (data) => {
    _setConfigs({
      ...configs,
      rows: data.map((poste) => ({
        id: poste.id,
        nom: (
          <div data-bs-toggle="modal" data-bs-target={`#exampleModal-${poste.id}`}>
            {poste.candidat.nom} {poste.candidat.prenoms} {setCandidatId(poste.candidat.id)}
          </div>
        ),
        poste: (
          <div data-bs-toggle="modal" data-bs-target={`#exampleModal-${poste.id}`}>
            {poste.poste_libre.libelle}
          </div>
        ),
        societes: (
          <div data-bs-toggle="modal" data-bs-target={`#exampleModal-${poste.id}`}>
            {poste.client.nom}
          </div>
        ),
        localisation: (
          <div data-bs-toggle="modal" data-bs-target={`#exampleModal-${poste.id}`}>
            {poste.candidat.location}
          </div>
        ),
        salaire: (
          <div data-bs-toggle="modal" data-bs-target={`#exampleModal-${poste.id}`}>
            {poste.candidat.remunerationMax} €
          </div>
        ),
        action: (
          <div className='row'>
            <div className='col-md-12'>
{
  (() => {
    switch (poste.candidat.disponibilite) {
      case "inprogress":
        // Permettre de modifier le statut
        return modifier(poste.candidat.id);

      case "recruited":
        return (
          <span className="badge badge-sm bg-success">
            Recruited
          </span>
        );

      case "rejected":
        return (
          <span className="badge badge-sm bg-danger">
            Rejected
          </span>
        );

      case "interview":
        return interview(poste.candidat.id);

      default:
        return modifier(poste.candidat.id);

    }
  })()
}


            </div>
            <div className="modal fade" id={`exampleModal-${poste.id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Les informations du client</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">

                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <table className="table table-bordered">
                            <tbody>
                              <tr>
                                <th scope="row">NOM</th>
                                <td>{poste.candidat.nom} {poste.candidat.prenoms}</td>
                              </tr>
                              <tr>
                                <th scope="row">POSTES</th>
                                <td>{poste.poste_libre.libelle}</td>
                              </tr>
                              <tr>
                                <th scope="row">SOCIETES</th>
                                <td>{poste.client.nom}</td>
                              </tr>
                              <tr>
                                <th scope="row">LOCALISATION</th>
                                <td>{poste.candidat.location}</td>
                              </tr>
                              <tr>
                                <th scope="row">SALAIRE</th>
                                <td>{poste.candidat.remunerationMax} €</td>
                              </tr>
                              <tr>
                                <th scope="row">STATUT</th>
                                <td>
                                  <span className={`badge badge-sm bg-${poste.statut === "2" ? 'success' : poste.statut === "1" ? 'danger' : 'warning'}`}>
                                    {poste.statut === "2" ? 'Accepter' : poste.statut === "1" ? 'Rejeter' : 'En cours'}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th scope='row'>
                                  CV<br />
                                  <a style={{ marginLeft: "48px" }} target='_blanck' href={`https://cerebrotbt.com/storage/${poste.candidat.cv_path}`}>
                                    <MDBIcon icon="download" className="mdb-icon-fab mdb-color-danger" />
                                  </a>
                                </th>
                                <td>
                                  {poste.candidat.cv_path ?
                                    <embed src={`https://cerebrotbt.com/storage/${poste.candidat.cv_path}`} type="application/pdf" width="100%" height="100px" />
                                    : <p> Aucun CV</p>}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">fermer</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal fade" id={`exampleMod-${poste.id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog">
              <form onSubmit={handleSubmit}>

                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">change availability</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">

                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                            <Select
                              options={options}
                              isSearchable
                              onChange={handleInputChange}
                              value={formData.disponibilite}
                              name="disponibilite"
                              placeholder="Choose a disponibility"
                              className="col-md"
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary" data-bs-dismiss="modal">Save</button>
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">fermer</button>
                  </div>
                </div>
                </form>
              </div>
            </div>
          </div>

        ),

      }))

    })
  }

  const load = async () => {
    const data = await fetchPosteData(configs);
    setConfigs(data)
  }

  return [configs, load];
}
