// PrivateRoutes.js
import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

const PrivateRoute = ({element}) => {
    const isAuthenticated = localStorage.getItem('token') !== null;

    return (
        isAuthenticated ? element : <Navigate to="/"/>
    );
};

export default PrivateRoute;
