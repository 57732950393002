import axios from 'axios';
import { MDBIcon } from 'mdbreact';
import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';



const dataClient = {
  columns: [
    {
      label: 'Last name',
      field: 'nom',
      sort: 'asc',
      width: 150,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'Industries',
      field: 'industries',
      sort: 'asc',
      width: 270,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'Jobs',
      field: 'postes',
      sort: 'asc',
      width: 200,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'Description',
      field: 'description',
      sort: 'asc',
      width: 100,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'Number of jobs',
      field: 'nbrespostes',
      sort: 'asc',
      width: 100,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'Status',
      field: 'statut',
      sort: 'asc',
      width: 150,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'Action',
      field: 'action',
      sort: 'asc',
      width: 150,
      attributes: {
        className: 'text-center',
      },
    },
  ],
  rows: [], // Initialisez avec un tableau vide pour les lignes
};

function handleDeletePost(id, callback) {
  if (window.confirm("Are you sure you want to delete this customer?")) {
    // Demandez une confirmation avant de supprimer le poste
    const authToken = localStorage.getItem('token');

    axios.get(`https://cerebrotbt.com/api/admin/client/delete/${id}`, {
      headers: {
        'Authorization': `Bearer ${authToken}`
      },
    })
      .then((response) => {
        // Gérez la réponse si nécessaire
        alert('The customer has been successfully deleted');
        if (typeof callback === 'function') callback();

        // Vous pouvez également mettre à jour votre liste de postes ici
      })
      .catch((error) => {
        // Gérez les erreurs en cas de problème lors de la suppression
        console.error('Error when deleting customer :', error);
        if (typeof callback === 'function') callback();

      });
  }
}
function modifier(id) {
  // Définissez l'URL de modification en fonction de l'ID du domaine
  const urlModification = `/edit/client/${id}`; // Remplacez cette URL par la route de modification appropriée

  return (
    <div className='col-md-4'>
      <Link to={urlModification} className="text-secondary font-weight-normal text-xs" data-toggle="tooltip" data-original-title="Edit">
        <MDBIcon icon="edit" className="mdb-icon-fab mdb-color-success" />
      </Link>
    </div>
  );
}
const fetchClientData = async () => {
  try {
    const authToken = localStorage.getItem('token'); // Remplacez par le jeton d'authentification approprié

    // Effectuez une demande GET Axios à l'API
    const response = await axios.get('https://cerebrotbt.com/api/admin/client/index', {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    // En supposant que les données de réponse sont un tableau d'objets client, mettez à jour les lignes.
    // Ajustez cette partie en fonction de la structure réelle de la réponse de l'API.
    // Vérifiez si response.data et response.data.clients sont définis avant d'accéder aux données
    const clients = response.data?.clients ?? [];
    return clients;
  } catch (error) {
    console.error('Error retrieving data from the API:', error);
    return [];
  }
}

export function useDataClient() {

  const [configs, _setConfigs] = useState(dataClient);

  useEffect(() => {
    (async () => {
      await load();
    })()
  }, []);

  const cb = async () => {
    const data = await fetchClientData(configs);
    setConfigs(data)
  }

  const setConfigs = (data) => {
    _setConfigs({
      ...configs,
      rows: data.map((client) => ({
        nom: (
          <div data-bs-toggle="modal" data-bs-target={`#exampleModal-${client.id}`}>
            {client.nom}
          </div>
        ),
        industries: (
          <div data-bs-toggle="modal" data-bs-target={`#exampleModal-${client.id}`}>
            {client.industries.map((industry) => industry.nom).join(', ')}
          </div>
        ),
        postes: (
          <div data-bs-toggle="modal" data-bs-target={`#exampleModal-${client.id}`}>
            {client.postes_libres.map((poste) => poste.libelle).join(', ')}
          </div>
        ),
        description: (
          <div data-bs-toggle="modal" data-bs-target={`#exampleModal-${client.id}`}>
            {client.description}
          </div>
        ),
        nbrespostes: (
          <div data-bs-toggle="modal" data-bs-target={`#exampleModal-${client.id}`}>
            {client.nbr_postes_libres}
          </div>
        ),
        statut: (
          <span className={`badge badge-sm ${client.statut === 'prospect' ? 'bg-gradient-primary' :
            client.statut === 'active' ? 'bg-gradient-success' :
              client.statut === 'lead' ? 'bg-gradient-warning' :
              client.statut === 'close' ? 'bg-gradient-danger' :
              'bg-gradient-success'
            }`}>
            {client.statut}
          </span>
        ),
        action: (
          <div className='row'>
            <div className='col-md-4'>
              <Link className="text-secondary font-weight-normal text-xs" data-original-title="View" data-bs-toggle="modal" data-bs-target={`#exampleModal-${client.id}`}>
                <MDBIcon icon="eye" className="mdb-icon-fab mdb-color-primary" />
              </Link>

              <div className="modal fade" id={`exampleModal-${client.id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">Customer information</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">

                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <table className="table table-bordered">
                              <tbody>
                                <tr>
                                  <th scope="row">LAST NAME</th>
                                  <td>{client.nom}</td>
                                </tr>
                                <tr>
                                  <th scope="row">INDUSTIES</th>
                                  <td>{client.industries.map((industry, index) => (
                                    <span key={index}>
                                      {industry.nom}
                                      {(index + 1) % 3 === 0 && <br />}
                                      {index !== client.industries.length - 1 && ', '}
                                    </span>
                                  ))}</td>
                                </tr>
                                <tr>
                                  <th scope="row">JOBS</th>
                                  <td>{client.postes_libres.map((poste) => poste.libelle).join(', ')}</td>
                                </tr>
                                <tr>
                                  <th scope="row">DESCRIPTION</th>
                                  <td>{client.description ? client.description : "Aucun"}</td>
                                </tr>
                                <tr>
                                  <th scope="row">NUMBER OF JOBS</th>
                                  <td>{client.nbr_postes_libres}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">close</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {modifier(client.id)} {/* Appel de la fonction modifier avec l'ID du domaine */}
            <div className='col-md-4'>
              <Link className='text-secondary font-weight-normal text-xs' data-toggle="tooltip" data-original-title="Delete" onClick={() => handleDeletePost(client.id, cb)}>
                <MDBIcon icon="trash-alt" className="mdb-icon-fab mdb-color-danger" />
              </Link>
            </div>
          </div>

        ),
      }))

    })
  }

  const load = async () => {
    const data = await fetchClientData(configs);
    setConfigs(data)
  }

  return [configs, load];

}
