import React, { useState, useEffect } from 'react';
import axios from 'axios';

import NavigationGauche from "../components/dashboard/navGauch";
import Nav from "../components/dashboard/nav";

// Importation relative au tableau
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { Slider } from 'primereact/slider';
import { Tag } from 'primereact/tag';
import { CustomerService } from '../datas/CustomerService';
// Fin Importation relative au tableau


import {
  MDBIcon,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,

} from 'mdb-react-ui-kit';

import Card from "../components/card/card";
import RejetCard from '../components/card/rejetCard';
import AcceptCard from '../components/card/acceptCard';
import { useTableMatchingEncours } from '../datas/dataTable/matchingEnCours';
import { useTableMatchingEnAttente } from '../datas/dataTable/matchingEnAttente';
import { useTableMatchingEnAccepter } from '../datas/dataTable/matchingEnAccepter';
import { useTableMatchingEnRejeter } from '../datas/dataTable/matchingEnRejeter';
import { MDBDataTable } from 'mdbreact';



export default function Matching() {

  const [iconsActive, setIconsActive] = useState('tab1');
  const [basicActive, setBasicActive] = useState('tab1');
  const [nbreRejet, setNbreRejet] = useState(0);
  const [nbreAttente, setNbreAttente] = useState(0);
  const [nbreAccepte, setNbreAccepte] = useState(0);
  const [alertMessages, setAlertMessages] = useState([]);
  const [demandes, setDemandes] = useState([]);
  const [config, load] = useTableMatchingEncours();
  const [config1, load1] = useTableMatchingEnAttente();
  const [configAt, loadAt] = useTableMatchingEnAccepter();
  const [configRe, loadRe] = useTableMatchingEnRejeter();

  const handleIconsClick = (value) => {
    if (value === iconsActive) {
      return;
    }

    setIconsActive(value);
  };

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };
  // Fonction pour afficher le message d'alerte une seule fois
  const fetchData = async () => {
    try {
      const authToken = localStorage.getItem('token');
      const response = await axios.get("https://cerebrotbt.com/api/admin/matching/index", {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      });
      setDemandes(response.data.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des données:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  // TABLEAU

  // Declarations
  const [customers, setCustomers] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    representative: { value: null, matchMode: FilterMatchMode.IN },
    date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
    balance: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    activity: { value: null, matchMode: FilterMatchMode.BETWEEN }
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [representatives] = useState([
    { name: 'Amy Elsner', image: 'amyelsner.png' },
    { name: 'Anna Fali', image: 'annafali.png' },
    { name: 'Asiya Javayant', image: 'asiyajavayant.png' },
    { name: 'Bernardo Dominic', image: 'bernardodominic.png' },
    { name: 'Elwin Sharvill', image: 'elwinsharvill.png' },
    { name: 'Ioni Bowcher', image: 'ionibowcher.png' },
    { name: 'Ivan Magalhaes', image: 'ivanmagalhaes.png' },
    { name: 'Onyama Limba', image: 'onyamalimba.png' },
    { name: 'Stephen Shaw', image: 'stephenshaw.png' },
    { name: 'XuXue Feng', image: 'xuxuefeng.png' }
  ]);
  const [statuses] = useState(['unqualified', 'qualified', 'new', 'negotiation', 'renewal']);

  const getSeverity = (status) => {
    switch (status) {
      case 'unqualified':
        return 'danger';

      case 'qualified':
        return 'success';

      case 'new':
        return 'info';

      case 'negotiation':
        return 'warning';

      case 'renewal':
        return null;
    }
  };

  useEffect(() => {
    CustomerService.getCustomersLarge().then((data) => setCustomers(getCustomers(data)));

    // Fonction pour récupérer le nombre de rejets depuis l'API
    const fetchNbreRejet = async () => {
      try {
        const authToken = localStorage.getItem('token');
        const response = await axios.get("https://cerebrotbt.com/api/admin/matching/nbreRejet", {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        });
        setNbreRejet(response.data.count);
      } catch (error) {
        console.error("Erreur lors de la récupération du nombre de rejets:", error);
      }
    };
    // Fonction pour récupérer le nombre de rejets depuis l'API
    const fetchNbreAccept = async () => {
      try {
        const authToken = localStorage.getItem('token');
        const response = await axios.get("https://cerebrotbt.com/api/admin/matching/nbreAccept", {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        });
        setNbreAccepte(response.data.count);
      } catch (error) {
        console.error("Erreur lors de la récupération du nombre d'accepte:", error);
      }
    };
    // Fonction pour récupérer le nombre de rejets depuis l'API
    const fetchNbreAttent = async () => {
      try {
        const authToken = localStorage.getItem('token');
        const response = await axios.get("https://cerebrotbt.com/api/admin/matching/nbreAttente", {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        });
        console.log('Attente: ', response.data.count)
        setNbreAttente(response.data.count);
      } catch (error) {
        console.error("Erreur lors de la récupération du nombre d'attente:", error);
      }
    };
    // Appel de la fonction fetchNbreRejet pour récupérer le nombre de rejets
    fetchNbreRejet();
    // Appel de la fonction fetchNbreAccept pour récupérer le nombre de rejets
    fetchNbreAccept();
    // Appel de la fonction fetchNbreAttent pour récupérer le nombre de rejets
    fetchNbreAttent();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getCustomers = (data) => {
    return [...(data || [])].map((d) => {
      d.date = new Date(d.date);

      return d;
    });
  };

  const addAlertMessage = (message, type) => {
    setAlertMessages([...alertMessages, { message, type }]);
    setTimeout(() => {
      removeAlertMessage();
    }, 5000);
  };

  const removeAlertMessage = () => {
    if (alertMessages.length > 0) {
      const updatedMessages = [...alertMessages];
      updatedMessages.shift();
      setAlertMessages(updatedMessages);
    }
  };

  const formatCurrency = (value) => {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex flex-wrap gap-2 justify-content-between align-items-center">
        {/* <h4 className="m-0">Customers</h4> */}
        <span className="p-input-icon-left">
          {/* <i className="pi pi-search" /> */}
          <InputText type='search' value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Rechercher" />
        </span>
      </div>
    );
  };

  const countryBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        <img alt="flag" src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`flag flag-${rowData.country.code}`} style={{ width: '24px' }} />
        <span>{rowData.country.name}</span>
      </div>
    );
  };



  const balanceBodyTemplate = (rowData) => {
    return formatCurrency(rowData.balance);
  };

  const balanceFilterTemplate = (options) => {
    return <InputNumber value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} mode="currency" currency="USD" locale="en-US" />;
  };

  const statusBodyTemplate = (rowData) => {
    return <Tag value={rowData.status} severity={getSeverity(rowData.status)} />;
  };

  const statusFilterTemplate = (options) => {
    return <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} placeholder="Select One" className="p-column-filter" showClear />;
  };

  const statusItemTemplate = (option) => {
    return <Tag value={option} severity={getSeverity(option)} />;
  };

  const activityBodyTemplate = (rowData) => {
    return <ProgressBar value={rowData.activity} showValue={false} style={{ height: '6px' }}></ProgressBar>;
  };

  const activityFilterTemplate = (options) => {
    return (
      <>
        <Slider value={options.value} onChange={(e) => options.filterCallback(e.value)} range className="m-3"></Slider>
        <div className="flex align-items-center justify-content-between px-2">
          <span>{options.value ? options.value[0] : 0}</span>
          <span>{options.value ? options.value[1] : 100}</span>
        </div>
      </>
    );
  };
  const actionBodyTemplate = () => {
    return (
      <>
        <div className='row'>
          <div className='col-md-4'>
            <Button className='btn'
              style={{
                padding: "1px",
              }}>
              <i class="material-icons opacity-10">visibility</i>
            </Button>
          </div>
          <div className='col-md-4'>
            <Button className='btn'
              style={{
                padding: "1px",
              }}>
              <i class="material-icons opacity-10">edit</i>
            </Button>
          </div>
          <div className='col-md-4'>
            <Button className='btn'
              style={{
                padding: "1px",
              }}>
              <i class="material-icons opacity-10">delete</i>
            </Button>
          </div>
        </div>
      </>
    );
  };
  const handleRowClick = (entry) => {
    // Gérez les clics de ligne ici
    console.log('Row clicked:', entry);

  };
  const header = renderHeader();
  return (
    <>
      <NavigationGauche />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
        <Nav />
        <div className="container-fluid py-4">

          {alertMessages.map((alert, index) => (
            <div key={index} className={`alert alert-${alert.type}`} role="alert">
              {alert.message}
              {console.log('message: ', alert.message)}
            </div>
          ))}

          <div className="row">
            <div className="col">
              <MDBTabs className='mb-3'>
                <MDBTabsItem>
                  <MDBTabsLink onClick={() => handleIconsClick('tab1')} active={iconsActive === 'tab1'}>
                    <MDBIcon fas icon='chart-pie' className='me-2' /> My matchings
                  </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                  <MDBTabsLink onClick={() => handleIconsClick('tab2')} active={iconsActive === 'tab2'}>
                    <MDBIcon fas icon='chart-line' className='me-2' /> Match follow-up
                  </MDBTabsLink>
                </MDBTabsItem>
              </MDBTabs>
              <MDBTabsContent>
                {/* Contenu Tab Mes matchings */}
                <MDBTabsPane show={iconsActive === 'tab1'}>
                  <MDBTabs className='mb-3'>
                    <MDBTabsItem>
                      <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
                        Pending requests({nbreAttente})
                      </MDBTabsLink>
                    </MDBTabsItem>
                    <MDBTabsItem>
                      <MDBTabsLink onClick={() => handleBasicClick('tab3')} active={basicActive === 'tab3'}>
                        Accepted({nbreAccepte})
                      </MDBTabsLink>
                    </MDBTabsItem>
                    <MDBTabsItem>
                      <MDBTabsLink onClick={() => handleBasicClick('tab4')} active={basicActive === 'tab4'}>
                        Rejected({nbreRejet})
                      </MDBTabsLink>
                    </MDBTabsItem>
                  </MDBTabs>
                  <MDBTabsContent>
                    <MDBTabsPane show={basicActive === 'tab1'}>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="row px-2">
                            <Card addAlertMessage={addAlertMessage} />
                          </div>
                        </div>
                      </div>
                    </MDBTabsPane>
                    <MDBTabsPane show={basicActive === 'tab3'}>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="row px-2">
                            <AcceptCard />
                          </div>
                        </div>
                      </div>
                    </MDBTabsPane>
                    <MDBTabsPane show={basicActive === 'tab4'}>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="row px-2">
                            <RejetCard />
                          </div>
                        </div>
                      </div>
                    </MDBTabsPane>
                  </MDBTabsContent>
                </MDBTabsPane>
                {/* Fin Contenu Tab Mes matchings */}
                {/* Contenu Tab Suivi matchings */}
                <MDBTabsPane show={iconsActive === 'tab2'}>
                  <MDBTabs className='mb-3'>
                    <MDBTabsItem>
                      <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
                        All({nbreAttente + nbreAccepte + nbreRejet})
                      </MDBTabsLink>
                    </MDBTabsItem>
                    <MDBTabsItem>
                      <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
                        In progress({nbreAttente})
                      </MDBTabsLink>
                    </MDBTabsItem>
                    <MDBTabsItem>
                      <MDBTabsLink onClick={() => handleBasicClick('tab3')} active={basicActive === 'tab3'}>
                        Accept({nbreAccepte})
                      </MDBTabsLink>
                    </MDBTabsItem>
                    <MDBTabsItem>
                      <MDBTabsLink onClick={() => handleBasicClick('tab4')} active={basicActive === 'tab4'}>
                        Refused({nbreRejet})
                      </MDBTabsLink>
                    </MDBTabsItem>
                  </MDBTabs>
                  <MDBTabsContent>
                    <MDBTabsPane show={basicActive === 'tab1'}>
                      <div className="row">
                        <div className="col-lg-12">

                          {/* Elements du tableau */}
                          <div className="card">
                            {
                              config
                                ? (
                                  <MDBDataTable
                                    striped
                                    bordered
                                    small
                                    entriesLabel="See entries"
                                    infoLabel={["Show", "to", "from", "elements", "Display of {0}-{1} of {2} items",]}
                                    searchLabel="search"
                                    noRecordsFoundLabel="No record found"
                                    noBottomColumns
                                    paginationLabel={["P", "S"]}
                                    data={config}
                                    onRowClick={handleRowClick}
                                  />
                                ) :
                                <div className="loader" />
                            }
                          </div>


                        </div>
                      </div>
                    </MDBTabsPane>
                    <MDBTabsPane show={basicActive === 'tab2'}>
                      <div className="row">
                        <div className="col-lg-12">
                          {/* Elements du tableau */}
                          <div className="card">
                            {
                              config1
                                ? (
                                  <MDBDataTable
                                    striped
                                    bordered
                                    small
                                    entriesLabel="See entries"
                                    infoLabel={["Show", "to", "from", "elements", "Display of {0}-{1} of {2} items",]}
                                    searchLabel="search"
                                    noRecordsFoundLabel="No record found"
                                    noBottomColumns
                                    paginationLabel={["P", "S"]}
                                    data={config1}
                                    onRowClick={handleRowClick}
                                  />
                                ) :
                                <div className="loader" />
                            }
                          </div>
                        </div>
                      </div>
                    </MDBTabsPane>
                    <MDBTabsPane show={basicActive === 'tab3'}>
                      <div className="row">
                        <div className="col-lg-12">

                          {/* Elements du tableau */}
                          <div className="card">
                            {
                              configAt
                                ? (
                                  <MDBDataTable
                                    striped
                                    bordered
                                    small
                                    entriesLabel="See entries"
                                    infoLabel={["Show", "to", "from", "elements", "Display of {0}-{1} of {2} items",]}
                                    searchLabel="search"
                                    noRecordsFoundLabel="No record found"
                                    noBottomColumns
                                    paginationLabel={["P", "S"]}
                                    data={configAt}
                                    onRowClick={handleRowClick}
                                  />
                                ) :
                                <div className="loader" />
                            }
                          </div>
                        </div>
                      </div>
                    </MDBTabsPane>
                    <MDBTabsPane show={basicActive === 'tab4'}>
                      <div className="row">
                        <div className="col-lg-12">

                          {/* Elements du tableau */}
                          <div className="card">
                            {
                              configRe
                                ? (
                                  <MDBDataTable
                                    striped
                                    bordered
                                    small
                                    entriesLabel="See entries"
                                    infoLabel={["Show", "to", "from", "elements", "Display of {0}-{1} of {2} items",]}
                                    searchLabel="search"
                                    noRecordsFoundLabel="No record found"
                                    noBottomColumns
                                    paginationLabel={["P", "S"]}
                                    data={configRe}
                                    onRowClick={handleRowClick}
                                  />
                                ) :
                                <div className="loader" />
                            }
                          </div>
                        </div>
                      </div>
                    </MDBTabsPane>
                  </MDBTabsContent>
                </MDBTabsPane>
              </MDBTabsContent>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}