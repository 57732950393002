import axios from 'axios';

// Fonction pour récupérer les données depuis l'API RESTful
export async function fetchPostesLibresFromAPI(clientId) {
  try {
    const authToken = localStorage.getItem('token'); // Remplacez par le jeton d'authentification approprié
    const response = await axios.get(`https://cerebrotbt.com/api/admin/client/${clientId}/postes-libres`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    // Mettez à jour le tableau de postesLibres avec les données de l'API
    return { postesLibres: response.data.postesLibres };

  } catch (error) {
    console.error('Erreur lors de la récupération des données depuis l\'API :', error);
    return { error };
  }
}
