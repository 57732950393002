import { useState, useEffect } from "react";
import Select from 'react-select';
import axios from 'axios';
import NavigationGauche from "../components/dashboard/navGauch";
import Nav from "../components/dashboard/nav";
import Champs from "../components/autentification/champs";
import Boutons from "../components/autentification/bouton";
import PasswordField from "../components/autentification/passwordfield";
import { useTablePoste } from "../datas/dataTable/dataPostes";




export default function CreateUsers() {

  const [isLoading, setIsLoading] = useState(true);
  const [alertMessages, setAlertMessages] = useState([]);
  const [config, load] = useTablePoste();
  const [formData, setFormData] = useState({
    nom: "",
    prenoms: "",
    telephone: "",
    email: "",
    password: "",
    confirmedpassword: "",
    role_id: "",
    from: null

  });

  const options = [
    { value: '2', label: 'Talent Coach' },
    { value: '3', label: 'Recruteur' },
    { value: '4', label: 'Business Developpeur' },
  ];


  function HandleChange(value, name, fieldType) {
    if (fieldType === "text" || fieldType === "email" || fieldType === "tel" || fieldType === "password") {
      // Gestion des champs de texte, email et téléphone
      setFormData({ ...formData, [name]: value });
    } else if (fieldType === "select") {
      // Gestion des Select
      // Ici, vous pouvez utiliser fieldName pour déterminer quel champ de Select doit être mis à jour
      switch (name) {
        case "role_id":
          setFormData({ ...formData, role_id: value ? value.value : "" });
          break;
        // Ajoutez d'autres cas pour d'autres champs de Select si nécessaire
        default:
          // Gérez le champ par défaut (si nécessaire)
          break;
      }
    }
  }
  const handleInputChange = (inputValue) => {
    // Vous pouvez ici effectuer une recherche ou un filtrage
    // en fonction de la valeur saisie dans le champ de recherche.
    // Par exemple, filtrer les options en fonction de inputValue.
  };

  const authToken = localStorage.getItem('token');
  const headers = {
    'Authorization': `Bearer ${authToken}`
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://cerebrotbt.com/api/user/add_user', { ...formData, from:"reseau" },
        { headers: headers, 'Content-Type': 'application/json', });

      // Gérer la réponse ici, par exemple, afficher un message de succès ou effectuer une redirection.
      console.log('Réponse du serveur:', response.data);
      if (response.status === 201) {
        addAlertMessage("https://cerebrotbt.com/api/user/add_user !", "success");
      }
      await load();
    } catch (error) {
      // Gérer les erreurs ici, par exemple, afficher un message d'erreur.
      console.error('Erreur lors de l\'envoi du formulaire:', error);
      addAlertMessage(`Erreur lors de la création du client . ${error.response.data.message}`, "danger");

    }
  };

  const addAlertMessage = (message, type) => {
    // Ajoutez un nouvel message à la liste des alertes
    setAlertMessages([...alertMessages, { message, type }]);

    // Supprimez automatiquement le message après un certain délai (par exemple, 5 secondes)
    setTimeout(() => {
      removeAlertMessage();
    }, 5000); // 5000 millisecondes (5 secondes)
  };
  const removeAlertMessage = () => {
    // Supprimez le premier message de la liste des alertes
    if (alertMessages.length > 0) {
      const updatedMessages = [...alertMessages];
      updatedMessages.shift();
      setAlertMessages(updatedMessages);

      // Actualisez la page après avoir supprimé le message (délai de 5000 millisecondes)
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  };

  return (
    <>
      <NavigationGauche />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
        <Nav />
        <div className="container-fluid py-4">

          <div className="row">
            {alertMessages.map((alert, index) => (
              <div key={index} className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
                {alert.message}
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => removeAlertMessage(index)}
                  aria-label="Close"
                ></button>
              </div>
            ))}
            <form role="form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 mb-lg-0 mb-4">
                  <div className="input-group input-group-outline mb-3">

                    <Champs TypeChamp="text" name="nom" value={formData.nom} onChange={(e) => { HandleChange(e.target.value, "nom", "text") }} placeholder="Name" />

                  </div>
                  <div className="input-group input-group-outline mb-3">
                    <Champs TypeChamp="text" name="prenoms" value={formData.prenoms} onChange={(e) => { HandleChange(e.target.value, "prenoms", "text") }} placeholder="First names" />

                  </div>
                  <div className="input-group input-group-outline mb-3">
                    <Champs TypeChamp="text" name="telephone" value={formData.telephone} onChange={(e) => { HandleChange(e.target.value, "telephone", "text") }} placeholder="Phone" />

                  </div>
                  <div className="input-group input-group-outline mb-3">
                    <Champs TypeChamp="text" name="email" value={formData.email} onChange={(e) => { HandleChange(e.target.value, "email", "text") }} placeholder="Email" />

                  </div>
                  <div className="input-group input-group-outline mb-3">
                    <Select
                      options={options}
                      isSearchable
                      onInputChange={handleInputChange}
                      onChange={(selectedOption) => HandleChange(selectedOption, "role_id", "select")}
                      value={formData.role_id ? formData.role_id.label : null}
                      name="role_id"
                      placeholder="Choose a role"
                      className="col-md"
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-lg-0 mb-4">
                  <div className="input-group input-group-outline mb-3">
                    <PasswordField TypeChamp="password" name="password" value={formData.password} onChange={(e) => { HandleChange(e.target.value, "password", "text") }} placeholder="Password" />
                  </div>
                  <div className="input-group input-group-outline mb-3">
                    <PasswordField TypeChamp="password" name="confirmedpassword" value={formData.confirmedpassword} onChange={(e) => { HandleChange(e.target.value, "confirmedpassword", "text") }} placeholder="Confirm Password" />
                  </div>
                  <Boutons style={{ paddingBottom: "7px", paddingTop: "9px" }} type="submit" valeur="Save" />
                  {/*<div className="input-group input-group-outline mb-3">
                                <Champs TypeChamp="text" value={location} onChange={(e) => { setLocation(e.target.value) }} placeholder="User" diseabled/>
                        </div>*/}
                </div>
              </div>
            </form>
          </div>
        </div>

      </main>
    </>
  )
}