import axios from 'axios';
import {MDBIcon} from 'mdbreact';
import {Link} from "react-router-dom";
import {useState, useEffect} from 'react';


const dataDomaine = {
  columns: [
    {
      label: 'Domaine',
      field: 'domaine',
      sort: 'asc',
      width: 150,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'Industries',
      field: 'industries',
      sort: 'asc',
      width: 270,
      attributes: {
        className: 'text-center',
      },
    },
    {
      label: 'Action',
      field: 'action',
      sort: 'asc',
      width: 150,
      attributes: {
        className: 'text-center',
      },
    },
  ],
  rows: [], // Initialisez avec un tableau vide pour les lignes
};
function handleDeletePost(id, callback) {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce poste ?")) {
      // Demandez une confirmation avant de supprimer le poste
      const authToken = localStorage.getItem('token');

      axios.get(`https://cerebrotbt.com/api/admin/domaine/delete/${id}`,{
        headers : {
          'Authorization': `Bearer ${authToken}`
        },
      })
        .then((response) => {
          // Gérez la réponse si nécessaire
          alert('Le poste a été supprimé avec succès');
          if (typeof callback === 'function') callback();
          // Vous pouvez également mettre à jour votre liste de postes ici
        })
        .catch((error) => {
          // Gérez les erreurs en cas de problème lors de la suppression
          console.error('Erreur lors de la suppression du poste :', error);
          if (typeof callback === 'function') callback();

        });
    }
  }
  function modifier(id) {
    // Définissez l'URL de modification en fonction de l'ID du domaine
    const urlModification = `/update-domaine/${id}`; // Remplacez cette URL par la route de modification appropriée
  
    return (
      <div className='col-md-6'>
        <Link to={urlModification} className="text-secondary font-weight-normal text-xs" data-toggle="tooltip" data-original-title="Modifier">
          <MDBIcon icon="edit" className="mdb-icon-fab mdb-color-success" />
        </Link>
      </div>
    );
  }
  
  export async function fetchDomaineData(){
  try {
    const authToken = localStorage.getItem('token'); // Remplacez par le jeton d'authentification approprié

    // Effectuez une demande GET Axios à l'API
    const response = await axios.get('https://cerebrotbt.com/api/admin/domaine/index', {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    // En supposant que les données de réponse sont un tableau d'objets client, mettez à jour les lignes.
    // Ajustez cette partie en fonction de la structure réelle de la réponse de l'API.
    return response.data?.Domaine ?? [];
  } catch (error) {
    console.error('Erreur lors de la récupération des données depuis l\'API :', error);
  }
};

export function useTableDomaine(){

  const [configs, _setConfigs] = useState(dataDomaine);

  useEffect(() => {
      (async () => {
          await load();
      })()
  }, []);

  const cb = async () => {
      const data = await fetchDomaineData(configs);
      setConfigs(data)
  }
  const setConfigs = (data) => {
    _setConfigs({
        ...configs,
        rows : data.map((domaine) => ({
          domaine: domaine.nom,
          industries: domaine.industry ? domaine.industry.nom : "",
          action:(
            <div className='row'>
               {modifier(domaine.id)} {/* Appel de la fonction modifier avec l'ID du domaine */}
            <div className='col-md-6'>
              <Link className='text-secondary font-weight-normal text-xs' data-toggle="tooltip" data-original-title="Supprimer" onClick={() => handleDeletePost(domaine.id, cb)}>
                <MDBIcon icon="trash-alt" className="mdb-icon-fab mdb-color-danger" />
              </Link>
            </div>
          </div>
      
          ),
        }))
      
      })
      }
  const load = async () => {
    const data = await fetchDomaineData(configs);
    setConfigs(data)
}

return [configs, load];
}
