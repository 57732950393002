import axios from 'axios';
import Cookies from "js-cookie";



// Fonction pour récupérer les données depuis l'API RESTful
export async function fetchUsersFromAPI() {
  try {
    const authToken = localStorage.getItem('token'); // Remplacez par le jeton d'authentification approprié
    const response = await axios.get('https://cerebrotbt.com/api/admin/user/index', {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

        // Assurez-vous que response.data est défini et qu'il contient la propriété clients
    const users = response.data?.users ?? [];
    return users;

  } catch (error) {
    console.error('Erreur lors de la récupération des données depuis l\'API :', error);
    return [] ;
  }
}

