import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import Boutons from '../components/autentification/bouton';
import { useParams } from 'react-router-dom';
import { fetchCandidatsFromAPI } from '../datas/candidat';
import { fetchPostesLibresFromAPI } from "../datas/PostesLibres";
import { useLocation } from "react-router-dom";
import file from "../assets/img/file.png";





export default function MatchingPoste() {
    const { posteId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingCandidat, setIsLoadingCandidat] = useState(true);
    const [selectedClient, setSelectedClient] = useState(null);
    const [candidatData, setCandidatData] = useState(null);
    const [formattedClients, setFormattedClients] = useState([]);
    const [formattedPostes, setFormattedPostes] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [matching, setMatching] = useState({});
    const [alertMessages, setAlertMessages] = useState([]);
    const [user, setUser] = useState("");
    const location = useLocation();
    const [showMore, setShowMore] = useState(false);



    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().slice(0, 19).replace("T", " ");

    const [formData, setFormData] = useState({
        poste: "",
    });

    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    const handleInputChange = (inputValue) => {
        // Faites ici une recherche ou un filtrage en fonction de la valeur saisie dans le champ de recherche
    };

    const addAlertMessage = (message, type) => {
        // Ajoutez un nouvel message à la liste des alertes
        setAlertMessages([...alertMessages, { message, type }]);
        // Supprimez automatiquement le message après un certain délai (par exemple, 5 secondes)
        setTimeout(() => {
            removeAlertMessage();
        }, 5000); // 5000 millisecondes (5 secondes)
    };

    const removeAlertMessage = () => {
        // Supprimez le premier message de la liste des alertes
        if (alertMessages.length > 0) {
            const updatedMessages = [...alertMessages];
            updatedMessages.shift();
            setAlertMessages(updatedMessages);
            // Actualisez la page après avoir supprimé le message (délai de 5000 millisecondes)
            setTimeout(() => {
                window.location.reload();
            }, 5000);
        }
    };

    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        setMatching((prevMatching) => ({
            ...prevMatching,
            candidat_id: selectedOption ? selectedOption.value : "",
        }));

        if (selectedOption) {
            console.log('candidat_id:',selectedOption.value);
            fetchPostesLibresFromAPI(candidatData.Poste.client_id).then((data) => {
                console.log('data.postesLibres:',data);
                const formatted = data.postesLibres.map(postesLibre => ({
                    value: postesLibre.id,
                    label: postesLibre.libelle,
                }));
                setFormattedPostes(formatted);
            });
        } else {
            // Réinitialisez les domaines si aucun client n'est sélectionné
            setFormattedPostes([]);
        }
    };

    const authToken = localStorage.getItem('token');

    const handleSubmit = async (e) => {
        e.preventDefault(); // Empêcher le formulaire de se soumettre par défaut

        try {
            console.log("Request Data:", {
                poste_id: parseInt(posteId),
                candidat_id: selectedOption ? selectedOption.value : '',
                poste_libre_id: candidatData.Poste ? candidatData.Poste.id : '',
                statut: 0,
                date_validation: formattedDate,
                user_id: user,
            });

            const response = await axios.post(
                'https://cerebrotbt.com/api/admin/matching/add_matching',
                {
                    poste_id: parseInt(posteId),
                    candidat_id: selectedOption ? selectedOption.value : '',
                    client_id: candidatData.Poste ? candidatData.Poste.client_id : '',
                    poste_libre_id: candidatData.Poste ? candidatData.Poste.id : '',
                    statut: 0,
                    date_validation: formattedDate,
                    user_id: user,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${authToken}`
                    }
                }
            );
            if (response.status === 201) {
                addAlertMessage("Matching enregistré avec succès !", "success");
            }
            // Vous pouvez gérer la réponse ici (par exemple, afficher un message de succès)
            console.log('Matching enregistré avec succès:', response.data);
        } catch (error) {
            // Afficher des messages d'erreur spécifiques dans la console pour le débogage
            addAlertMessage(`Erreur lors de l'enregistrement du matching: ${error.response.data.message}`, "danger");
            console.error('Erreur lors de l\'enregistrement du matching:', error.message);
            console.error('Réponse de l\'erreur:', error.response);
        }
    };

    function HandleChange(value, name, fieldType) {
        switch (name) {
            case "poste":
                setFormData({ ...formData, poste: value ? value.value : "" });
                break;
            case "client_id":
                setFormData({ ...formData, client_id: value ? value.value : "" });
                break;
            default:
                // Gérez le champ par défaut (si nécessaire)
                break;
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://cerebrotbt.com/api/admin/poste/show/${posteId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                        },
                    });
                const data = response.data;
                console.log('postes: ', data);
                setCandidatData(data);
            } catch (error) {
                console.error('Erreur lors de la récupération des données:', error.message);
            }
        };
        //fetchData();
        const userInfoJSON = localStorage.getItem("user");
        // Vérifiez si userInfoJSON est défini
        if (userInfoJSON) {
            // Parsez la chaîne JSON en un objet JavaScript
            const parsedUserInfo = JSON.parse(userInfoJSON);
            // Accédez à la propriété "nom" de l'objet
            const id = parsedUserInfo.id;
            setUser(id);
        } else {
            console.log('Aucune donnée utilisateur trouvée dans le local storage');
        }


        fetchCandidatsFromAPI().then((data) => {
            const formatted = data ? data.map(candidat => ({
                value: candidat.id,
                label: candidat.nom +" "+candidat.prenoms,
            })) : [];
            setFormattedClients(formatted);
            setIsLoading(false);
        });
        fetchData();
    }, [posteId]); // Mettez à jour lorsque candidatId change

    const handleRetour = () => {
        if (location.pathname !== "/edit/") {
            // Si l'URL courante n'est pas "/edit/", utilisez le hook history pour revenir à la page précédente
            window.history.back();
        } else {
            // Sinon, effectuez l'action que vous souhaitez pour afficher le retour (par exemple, ne rien faire ici)
        }
    };

    return (
        <div>
            <h6 className="font-weight-bolder mb-0" style={{ marginLeft: "20px" }}>
                <span onClick={handleRetour} style={{ cursor: "pointer", color: "#939191" }}>
                    {location.pathname !== "/edit/" && "Retour | "}
                </span>
                {"Maching de poste"}
            </h6>
            {candidatData ? (
                <div className='container'>
                    {alertMessages.map((alert, index) => (
                        <div key={index} className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
                            {alert.message}
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => removeAlertMessage(index)}
                                aria-label="Close"
                            ></button>
                        </div>
                    ))}
                    <div className='row'>

                        <div className='col-md-2'>



                        </div>
                    </div>
                    <div class="container">
                        <h6 style={{ textAlign: "center" }}>INFORMATIONS POSTE</h6>
                        <div class="row" style={{ marginRight: "35px", marginLeft: "35px" }}>
                            <div class="col">
                                <table class="table table-striped table-bordered">

                                    <tbody>
                                        <tr><td>Entreprise</td></tr>
                                        <tr><td>Contrat</td></tr>
                                        <tr><td>Description</td></tr>
                                        <tr><td>Adresse</td></tr>
                                        <tr><td>Office Présence</td></tr>
                                        <tr><td>Type de rémunération</td></tr>
                                        <tr><td>Intitulé du poste</td></tr>
                                        <tr>
                                            <td>
                                                <form onSubmit={handleSubmit}>
                                                    <Select
                                                        options={formattedClients}
                                                        isSearchable
                                                        onInputChange={handleInputChange}
                                                        onChange={handleChange}
                                                        value={console.log('selectedOption:',selectedOption)}
                                                        name="client_id"
                                                        placeholder="Rechercher un candidat..."
                                                        className="col-md"
                                                    />
                                                </form>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col">
                                <table class="table table-striped table-bordered">

                                    <tbody>
                                        <tr><td><span style={{ fontWeight: 'bold' }}>{candidatData.Poste.client.nom}</span></td></tr>
                                        <tr><td> <span style={{ fontWeight: 'bold' }}>{candidatData.Poste.contrat.nom}</span></td></tr>

                                        <tr>
                                            <td>
                                                <span style={{ fontWeight: 'bold' }}>
                                                    {showMore ? candidatData.Poste.description : candidatData.Poste.description.substring(0, 100).match(/.{1,71}/g).map((line, index) => (
                                                        <React.Fragment key={index}>
                                                            {line}
                                                            <br />
                                                        </React.Fragment>
                                                    ))}
                                                    {!showMore && candidatData.Poste.description.length > 500 && '...'}
                                                </span>
                                                <br />
                                                {candidatData.Poste.description.length > 500 && (
                                                    <button onClick={toggleShowMore} style={{
                                                        borderRadius: "20px",
                                                        backgroundColor: "#f80",
                                                        border: "#f80",
                                                        display: "flex",
                                                        color: "#fff", margin: "auto"
                                                    }}>
                                                        {showMore ? 'Voir moins' : 'Voir plus'}
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                        <tr><td><span style={{ fontWeight: 'bold' }}>{candidatData.Poste.location}</span></td></tr>
                                        <tr><td><span style={{ fontWeight: 'bold' }}>{candidatData.Poste.office.nom}</span></td></tr>
                                        <tr><td><span style={{ fontWeight: 'bold' }}>{candidatData.Poste.remuneration.nom}</span></td></tr>
                                        <tr><td><span style={{ fontWeight: 'bold' }}>{candidatData.Poste.titre}</span></td></tr>
                                        <tr>
                                            <td scope="2">
                                                <form onSubmit={handleSubmit}>
                                                    <Boutons type="submit" valeur="Matching" />
                                                </form>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="loader"></div>
            )}
        </div>
    );
}
