import { useState, useEffect } from "react";
import Select from 'react-select';
import axios from 'axios';
import NavigationGauche from "../components/dashboard/navGauch";
import Nav from "../components/dashboard/nav";
import Champs from "../components/autentification/champs";
import Boutons from "../components/autentification/bouton";
import { MDBDataTable } from 'mdbreact';
import { useTablePoste } from "../datas/dataTable/dataPostes";
import {fetchClientsFromAPI} from "../datas/clients";
import { fetchRemunerationFromAPI } from '../datas/remuneration';
import { fetchContratsFromAPI } from '../datas/contrat';
import { fetchOfficeFromAPI } from '../datas/office';
import { fetchPostesLibresFromAPI } from "../datas/PostesLibres";


import Textarea from "../components/autentification/textarea";


export default function Post() {
  
  const [config, load] = useTablePoste();

  const [location, setLocation] = useState("");
  const [basicModal, setBasicModal] = useState(false);
  const [selectedPoste, setSelectedPoste] = useState(null);
  const [alertMessages, setAlertMessages] = useState([]);


  const [user, setUser] = useState("");
  const [formattedClients, setFormattedClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  const [formattedRemunerations, setformattedRemunerations] = useState([]);
  const [formattedContrats, setformattedContrats] = useState([]);
  const [formattedOffices, setformattedOffices] = useState([]);
  const [formattedPostesLibres, setFormattedPostesLibres] = useState([]);
  const [selectedClients, setSelectedClients] = useState(null);
  const [userFrom, setUserFrom] = useState(null);




  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [clients, setClients] = useState([]);
  

  const toggleShow = (poste) => {
    setSelectedPoste(poste);
    setBasicModal(!basicModal);
  };

  const addAlertMessage = (message, type) => {
    // Ajoutez un nouvel message à la liste des alertes
    setAlertMessages([...alertMessages, { message, type }]);
  
    // Supprimez automatiquement le message après un certain délai (par exemple, 5 secondes)
    setTimeout(() => {
      removeAlertMessage();
    }, 5000); // 5000 millisecondes (5 secondes)
  };
  const removeAlertMessage = () => {
    // Supprimez le premier message de la liste des alertes
    if (alertMessages.length > 0) {
      const updatedMessages = [...alertMessages];
      updatedMessages.shift();
      setAlertMessages(updatedMessages);

    // Actualisez la page après avoir supprimé le message (délai de 5000 millisecondes)
    setTimeout(() => {
      window.location.reload();
    }, 5000);
    }
  };
  const handleInputChange = (inputValue) => {
    // Vous pouvez ici effectuer une recherche ou un filtrage
    // en fonction de la valeur saisie dans le champ de recherche.
    // Par exemple, filtrer les options en fonction de inputValue.
  };
  // const [industrie, setIndustrie] = useState("");
  const [statut, setStatut] = useState("");
  const [description, setDescription] = useState("");
  const [postes, setPostes] = useState([]);
  const [formData, setFormData] = useState({
    description: "",
    location: "",
    office_id: null,
    contrat_id: null,
    remuneration_id: null,
    client_id: null,
    titre:"",
    statut:"",
    user_id:null
  });

  const madataireOptions = [
    { value: 'brouillon', label: 'Brouillon' },
    { value: 'privé', label: 'Privé' },
    { value: 'public', label: 'Public' },
    { value: 'fermer', label: 'Fermer' },

];

const inviteOptions = [
    { value: 'brouillon', label: 'Brouillon' },
    { value: 'public', label: 'Public' },
    { value: 'fermer', label: 'Fermer' },

];
  function HandleChange(value, name, fieldType) {
    if (fieldType === "text" || fieldType === "email" || fieldType === "tel") {
      // Gestion des champs de texte, email et téléphone
      setFormData({ ...formData, [name]: value });
    } else if (fieldType === "select") {
      // Gestion des Select
      // Ici, vous pouvez utiliser fieldName pour déterminer quel champ de Select doit être mis à jour
      switch (name) {
        case "industry_id":
          setFormData({ ...formData, industry_id: value ?  value.value : ""});
          break;
        case "domaine_id":
          setFormData({ ...formData, domaine_id: value ?  value.value : "" });
          break;
        case "contrat_id":
          setFormData({ ...formData, contrat_id: value ?  value.value : "" });
          break;
        case "office_id":
          setFormData({ ...formData, office_id: value ?  value.value : "" });
          break;
        case "remuneration_id":
          setFormData({ ...formData, remuneration_id: value ?  value.value : "" });
          break;  
        case "titre":
          setFormData({ ...formData, titre: value ?  value.label : "" });
            break;
        case "client_id":
          setFormData({ ...formData, client_id: value ?  value.value : "" });
            break;
        case "statut":
          setFormData({ ...formData, statut: value ?  value.value : "" });
            break;
        // Ajoutez d'autres cas pour d'autres champs de Select si nécessaire
        default:
          // Gérez le champ par défaut (si nécessaire)
          break;
      }
    }
  }
  const authToken = localStorage.getItem('token');
  const headers = {
    'Authorization': `Bearer ${authToken}`
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log('formData:',formData)
      const response = await axios.post('https://cerebrotbt.com/api/admin/poste/add_poste', {...formData, user_id: user},
       {headers: headers,'Content-Type': 'application/json',});

      // Gérer la réponse ici, par exemple, afficher un message de succès ou effectuer une redirection.
      if (response.status === 201) {
        addAlertMessage("Job successfully created !", "success");
        setDescription("");
        setStatut("");
        setDescription("");
        setUser("");
        setLocation("");
      }
      await load();
    } catch (error) {
      // Gérer les erreurs ici, par exemple, afficher un message d'erreur.
      console.error('Error when sending the form:', error);
      addAlertMessage(`Job creation error . ${error.response.data.message}`, "danger");

    }
  };

  const handleChange = (selectedOption) => {
    setSelectedClients(selectedOption);
    setFormData({ ...formData, client_id: selectedOption ? selectedOption.value : "" });
        // Effectuez une requête pour récupérer les domaines en fonction de l'industrie sélectionnée

        if (selectedOption) {
          fetchPostesLibresFromAPI(selectedOption.value).then((data) => {
            const formatted = data.postesLibres.map(postesLibre => ({
              value: postesLibre.id,
              label: postesLibre.libelle,
            }));
            setFormattedPostesLibres(formatted);
          });
        } else {
          // Réinitialisez les domaines si aucun client n'est sélectionné
         setFormattedPostesLibres([]);
        }
  };



  useEffect(() => {

    // Effectuez une requête pour récupérer les domaines en fonction de l'industrie sélectionnée
    fetchClientsFromAPI().then((data) => {
      const formatted = data ? data.map(client => ({
        value: client.id,
        label: client.nom,
      })) : [];
      setFormattedClients(formatted);
      setIsLoading(false);
    });

    fetchRemunerationFromAPI().then((data) => {
      const formatted = data.remunerations.map(remuneration => ({
        value: remuneration.id,
        label: remuneration.nom,
      }));
      setformattedRemunerations(formatted);
      setIsLoading(false);
    });

    fetchContratsFromAPI().then((data) => {
      const formatted = data.contrats ? data.contrats.map(contrat => ({
        value: contrat.id,
        label: contrat.nom,
      })) :[];
      setformattedContrats(formatted);
      setIsLoading(false);
    });
    fetchOfficeFromAPI().then((data) => {
      const formatted = data.officePresences.map(office => ({
        value: office.id,
        label: office.nom,
      }));
      setformattedOffices(formatted);
      setIsLoading(false);
    });

     // Récupérez la chaîne JSON depuis le local storage
     const userInfoJSON = localStorage.getItem("user");

    // Vérifiez si userInfoJSON est défini
    if (userInfoJSON) {
      // Parsez la chaîne JSON en un objet JavaScript
      const parsedUserInfo = JSON.parse(userInfoJSON);
      // Accédez à la propriété "nom" de l'objet
      const from = parsedUserInfo.from;
      const id = parsedUserInfo.id;
      setUserFrom(from);
      setUser(id);
    } else {
      console.log('Aucune donnée utilisateur trouvée dans le local storage');
    }

    const authToken = localStorage.getItem('token');
     
        // Effectuez une requête GET pour récupérer les postes depuis votre API
        axios.get('https://cerebrotbt.com/api/admin/poste/index', {
          headers : {
            'Authorization': `Bearer ${authToken}`
          },
        })
          .then((response) => {
            setPostes(response.data.postes);
          })
          .catch((error) => {
            console.error('Erreur lors de la récupération des postes :', error);
          });

}, []);
 // Gestionnaire d'événements pour le changement de l'industrie sélectionnée
 const handleClientChange = (selectedOption) => {
  setFormattedClients(selectedOption);
};
const handleRowClick = (entry) => {
  // Gérez les clics de ligne ici
  console.log('Row clicked:', entry);

};
if (isLoading) {
  return <div className="loader"></div>;
}

  return (
    <>

      <NavigationGauche />


      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
        <Nav />
        <div className="container-fluid py-4">

          <div className="row">
          {alertMessages.map((alert, index) => (
          <div key={index} className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
            {alert.message}
            <button
              type="button"
              className="btn-close"
              onClick={() => removeAlertMessage(index)}
              aria-label="Close"
            ></button>
          </div>
        ))}
              <form role="form" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6 mb-lg-0 mb-4">
                          <div className="input-group input-group-outline mb-3">
                                
                                <Select
                                  options={formattedClients}
                                  isSearchable
                                  onInputChange={handleInputChange}
                                  onChange={handleChange} 
                                  value={selectedClients}
                                  name="client_id"
                                  placeholder="Customer search..."
                                  className="col"
                                />
                          </div>
                          <div className="input-group input-group-outline mb-3">
                                <Select
                                  options={formattedPostesLibres}
                                  isSearchable
                                  onInputChange={handleInputChange}
                                  onChange={(selectedOption) => HandleChange(selectedOption, "titre", "select")} 
                                  name="titre"
                                  value={formData.titre ? formData.titre.label : null}
                                  placeholder="Free job"
                                  className="col"
                                />
                          </div>
                          <div className="input-group input-group-outline mb-3">
                          <Select
                              options={formattedRemunerations}
                              isSearchable
                              onInputChange={handleInputChange}
                              onChange={(selectedOption) => HandleChange(selectedOption, "remuneration_id", "select")} 
                              value={formData.remuneration_id ? formData.remuneration_id.label  : null}
                              name="remuneration_id"
                              placeholder="Type of remuneration"
                              className="col"
                            />
                          </div>
                          <div className="input-group input-group-outline mb-3">
                          <Select
                            options={formattedContrats}
                            isSearchable
                            onInputChange={handleInputChange}
                            onChange={(selectedOption) => HandleChange(selectedOption, "contrat_id", "select")} 
                            value={formData.contrat_id ? formData.contrat_id.label : null}
                            name="contrat_id"
                            placeholder="Contract type"
                            className="col"
                          />
                              </div>
                          <div className="input-group input-group-outline mb-3">
                          <Select
                            options={formattedOffices}
                            isSearchable
                            onInputChange={handleInputChange}
                            onChange={(selectedOption) => HandleChange(selectedOption, "office_id", "select")} 
                            value={formData.office_id ? formData.office_id.label : null}
                            name="office_id"
                            placeholder="Type of office presence"
                            className="col-md"
                          />
                          </div>
                    </div>
                    <div className="col-md-6 mb-lg-0 mb-4">
                        <div className="input-group input-group-outline mb-3">
                                <Champs TypeChamp="text" name="location" value={formData.location} onChange={(e) => { HandleChange(e.target.value, "location", "text") }} placeholder="Location" />
                        </div>
                        <div className="input-group input-group-outline mb-3">
                                <Textarea name="description" value={formData.description} onChange={(e) => { HandleChange(e.target.value, "description", "text") }} placeholder="Description" />
                        </div>
                        <div className="input-group input-group-outline mb-3">
                                     <Select
                                        options={userFrom === "self" ? inviteOptions : madataireOptions}
                                        isSearchable
                                        onInputChange={handleInputChange}
                                        onChange={(selectedOption) => HandleChange(selectedOption, "statut", "select")} 
                                        value={formData.statut ? formData.statut.label : null}
                                        name="statut"
                                        placeholder="Status"
                                        className="col"
                                      />
                        </div>
                            <Boutons style={{paddingBottom: "7px",paddingTop: "9px"}}  type="submit" valeur="Save" />
                        {/*<div className="input-group input-group-outline mb-3">
                                <Champs TypeChamp="text" value={location} onChange={(e) => { setLocation(e.target.value) }} placeholder="User" diseabled/>
                        </div>*/}
                    </div>
                </div>
              </form>
          </div>

          <div className="row mt-4" >
            <div className="col-12">
              <p className="font-weight-bold">LIST OF JOBS</p>
              <MDBDataTable
                striped
                bordered
                small
                entriesLabel="See entries"
                infoLabel={["Show", "to", "from", "elements", "Display of {0}-{1} of {2} items",]}
                searchLabel="search"
                noRecordsFoundLabel="No record found"
                noBottomColumns
              paginationLabel={["P", "S"]}
              data={config}
              onRowClick={handleRowClick}
              />
            </div>
          </div>


        </div>
      
      </main>

     
    </>
  );
}