import React, { useEffect, useState } from "react";
import axios from "axios";
import "./card.css";


export default function Card(props) {

    const [demandes, setDemandes] = useState([]);
    const [alertMessages, setAlertMessages] = useState([]);


    useEffect(() => {
        // Fonction pour récupérer les données depuis l'API
        const fetchData = async () => {
            try {
                const authToken = localStorage.getItem('token');
                const response = await axios.get("https://cerebrotbt.com/api/admin/matching/index", {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                });
                setDemandes(response.data.data);
            } catch (error) {
                console.error("Erreur lors de la récupération des données:", error);
            }
        };

        // Appel de la fonction fetchData pour récupérer les données
        fetchData();
    }, []);

    const addAlertMessage = (message, type) => {
        // Ajoutez un nouvel message à la liste des alertes
        setAlertMessages([...alertMessages, { message, type }]);
        //alert([...alertMessages, { message, type }]);
        //console.log('message1: ',message);
        // Supprimez automatiquement le message après un certain délai (par exemple, 5 secondes)
        setTimeout(() => {
            removeAlertMessage();
        }, 5000); // 5000 millisecondes (5 secondes)
    };

    const removeAlertMessage = () => {
        // Supprimez le premier message de la liste des alertes
        if (alertMessages.length > 0) {
            const updatedMessages = [...alertMessages];
            updatedMessages.shift();
            setAlertMessages(updatedMessages);
            // Actualisez la page après avoir supprimé le message (délai de 5000 millisecondes)
            setTimeout(() => {
                window.location.reload();
            }, 5000);
        }
    };
    // Fonction pour rejeter une demande
    const rejeterDemande = async (id) => {
        try {
            const authToken = localStorage.getItem('token');
            const response = await axios.post(`https://cerebrotbt.com/api/admin/matching/update/${id}`, {
                statut: "1" // Remplacez "1" par le statut que vous souhaitez définir pour le rejet
            }, {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            });
            console.log('reponse: ', response)
            if (response.status === 200) {
                //addAlertMessage("Matching rejeter avec succès !", "success");
                props.addAlertMessage("Matching accepté avec succès !", "success");
                console.log('reponse1: ',  props.addAlertMessage("Matching accepté avec succès !", "success") )

             }
            // Rafraîchir les données après le rejet de la demande
            const updatedDemandes = demandes.filter(demande => demande.id !== id);
            console.log('statut: ', updatedDemandes)
            setDemandes(updatedDemandes);
        } catch (error) {
            console.error("Erreur lors du rejet de la demande:", error);
        }
    };

    // Fonction pour rejeter une demande
    const accepterDemande = async (id) => {
        try {
            const authToken = localStorage.getItem('token');
            const response = await axios.post(`https://cerebrotbt.com/api/admin/matching/update/${id}`, {
                statut: "2" // Remplacez "1" par le statut que vous souhaitez définir pour le rejet
            }, {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            });
            console.log('reponse: ', response)
            if (response.status === 200) {
                addAlertMessage("Matching accepter avec succès !", "success");
                window.location.reload(); // Actualiser la page après un succès
            }
            // Rafraîchir les données après le rejet de la demande
            const updatedDemandes = demandes.filter(demande => demande.id !== id);
            console.log('statut: ', updatedDemandes)
            setDemandes(updatedDemandes);
        } catch (error) {
            console.error("Erreur lors du accepter de la demande:", error);
        }
    };

    return (
        <>
            {demandes.map(demande => (

                <div key={demande.id} className="card col-md-4">
                    <div className="card-body">
                        {console.log("matching: ",demande.candidat)}
                        <h5 className="card-title"><strong>{demande.candidat.nom} {demande.candidat.prenoms}</strong></h5>
                        <h5 className="card-title"><strong>{demande.candidat.poste}</strong></h5>
                        <p className="card-text">
                            <i className="fas fa-briefcase"></i> {demande.client.nom}<br />
                            <i className="fas fa-map-marker-alt"></i> {demande.candidat.location}<br />
                            <i className="fas fa-coins"></i> {demande.candidat.remunerationMin + " €"} - {demande.candidat.remunerationMax + " €"}
                        </p>
                        <div className="row">
                            <div className="col">
                                <button className="btn rejeter" onClick={() => rejeterDemande(demande.id)}>Reject</button>
                                <button data-bs-toggle="modal" data-bs-target={`#exampleModal-${demande.id}`} className="btn matcher">Matcher</button>
                                <div className="modal fade" id={`exampleModal-${demande.id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="exampleModalLabel">Candidate information</h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">

                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <table className="table table-bordered">
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row">NAME & FIRST NAMES </th>
                                                                        <td>{demande.candidat.nom} {demande.candidat.prenoms}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">JOB</th>
                                                                        <td>{demande.candidat.poste}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">MIN. REMUNERATION</th>
                                                                        <td>{demande.candidat.remunerationMin + " €"}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">MAX. REMUNERATION</th>
                                                                        <td>{demande.candidat.remunerationMax + " €"}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">EMAIL</th>
                                                                        <td>{demande.candidat.email}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">LOCATION</th>
                                                                        <td>{demande.candidat.location}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                <button type="button"  className="btn matcher" onClick={() => accepterDemande(demande.id)}>Matcher</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
}