import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";



import TopNav from "../components/autentification/topnav";
import background from "../assets/img/9430573_4151160.jpg";
import Champs from "../components/autentification/champs";
import ChoixMultiple from "../components/choixMultiple";
import PasswordField from "../components/autentification/passwordfield";
import Boutons from "../components/autentification/bouton";


export default function Inscription() {

  const [formData, setFormData] = useState({
    nom: "",
    prenoms: "",
    email: "",
    telephone: "",
    password: "",
    confirmedpassword: "",
    role_id: "",
    from: null

  });
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null); // Nouvel état pour le message de succès
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleRegistration = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("https://cerebrotbt.com/api/user/add_user", { ...formData, from:"self", role_id:5 });
      // Gestion du succès

      if (response.status === 200) {
        setSuccessMessage("Inscription réussie ! Vous pouvez maintenant vous connecter.");
        setError(null);
        // Redirection vers la page de connexion après un délai (par exemple, 2 secondes)
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        // L'inscription a échoué, traitez l'erreur ici
        setError(response.data.message || "Une erreur s'est produite lors de l'inscription.");
        setSuccessMessage(null);
      }
    } catch (error) {
      // Une erreur s'est produite lors de la requête
      console.error("Erreur lors de la requête :", error);
      setError(`Une erreur s'est produite lors de l'inscription: ${error.response.data.message}`);
      setSuccessMessage(null);
    }
  };



  return (
    <>
      <TopNav />

      <main className="main-content  mt-0">
        <section>
          <div className="page-header min-vh-100">
            <div className="container">
              <div className="row">
                <div className="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column">
                  <div className="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center" style={{ backgroundImage: `url(${background})`, backgroundSize: "cover" }}>

                  </div>
                </div>
                <div className="col-xl-4 col-lg-5 col-md-7 d-flex flex-column ms-auto me-auto ms-lg-auto me-lg-5">
                  <div className="card card-plain regis">
                    <div className="card-header" style={{ paddingBottom: "0px", textAlign: "center" }}>
                      <h4 className="font-weight-bolder">Welcome to Cerebro<sup style={{color:" #ff8800"}}>*</sup></h4>
                      <p className="mb-0">Let's get to know each other, please enter your details.</p>
                    </div>
                    <div className="card-body" style={{ paddingTop: "0px" }}>
                      <form onSubmit={handleRegistration}>
                        {error != "L'utilisateur a été créé avec succès" ?
                          null
                          : error === "L'utilisateur a été créé avec succès" ?
                            (<div
                              className="alert alert-success"
                              role="alert"
                              style={{ color: "#fff" }}
                            >
                              {error}
                            </div>) : (<div
                              className="alert alert-danger"
                              role="alert"
                              style={{ color: "#fff" }}
                            >
                              {error}
                            </div>)}


                        <div className="container">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="input-group input-group-outline mb-3">

                                <Champs type="text" name="nom" value={formData.nom} onChange={handleInputChange} placeholder="Last name" />

                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="input-group input-group-outline mb-3">

                                <Champs type="text" name="prenoms" value={formData.prenoms} onChange={handleInputChange} placeholder="First name(s)" />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="input-group input-group-outline mb-3">

                                <Champs type="text" name="telephone" value={formData.telephone} onChange={handleInputChange} placeholder="Phone" />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="input-group input-group-outline mb-3">

                                <Champs TypeChamp="email" name="email" value={formData.email} onChange={handleInputChange} placeholder="Email address" />


                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">

                              <div className="input-group input-group-outline mb-3">
                                <PasswordField TypeChamp="password" name="password" value={formData.password} onChange={handleInputChange} placeholder="Password" />
                              </div>

                            </div>
                            <div className="col-md-6">
                              <div className="input-group input-group-outline mb-3">
                                <PasswordField TypeChamp="password" name="confirmedpassword" value={formData.confirmedpassword} onChange={handleInputChange} placeholder="Confirm password" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-check form-check-info text-start ps-0">
                          <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                          <label className="form-check-label" htmlFor="flexCheckDefault">
                          I accept the <a href="" className="text-dark font-weight-bolder">Terms and Conditions of Use</a>
                          </label>
                        </div>
                        <div className="text-center">
                          <Boutons valeur="Register" />
                        </div>
                      </form>
                    </div>
                    <div className="card-footer text-center pt-0 px-lg-2 px-1">
                      <p className="mb-2 text-sm mx-auto">
                      Already have an account?
                        <Link to="/" className="text-primary text-gradient font-weight-bold"> Log on to</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}