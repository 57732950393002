import React, { useEffect, useState } from "react";
import axios from "axios";
import "./card.css";


export default function RejetCard() {

    const [demandes, setDemandes] = useState([]);
    const [alertMessages, setAlertMessages] = useState([]);


    useEffect(() => {
        // Fonction pour récupérer les données depuis l'API
        const fetchData = async () => {
            try {
                const authToken = localStorage.getItem('token');
                const response = await axios.get("https://cerebrotbt.com/api/admin/matching/listeRejeter", {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                });
                console.log("rejeter: ",response.data)
                setDemandes(response.data.data);
            } catch (error) {
                console.error("Erreur lors de la récupération des données:", error);
            }
        };

        // Appel de la fonction fetchData pour récupérer les données
        fetchData();
    }, []);
   
    const addAlertMessage = (message, type) => {
        // Ajoutez un nouvel message à la liste des alertes
        setAlertMessages([...alertMessages, { message, type }]);
        // Supprimez automatiquement le message après un certain délai (par exemple, 5 secondes)
        setTimeout(() => {
          removeAlertMessage();
        }, 5000); // 5000 millisecondes (5 secondes)
      };
    
      const removeAlertMessage = () => {
        // Supprimez le premier message de la liste des alertes
        if (alertMessages.length > 0) {
          const updatedMessages = [...alertMessages];
          updatedMessages.shift();
          setAlertMessages(updatedMessages);
          // Actualisez la page après avoir supprimé le message (délai de 5000 millisecondes)
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
      };

    return (
        <>
        {demandes.map(demande => (
            
            <div key={demande.id} className="card col-md-4">
                {alertMessages.map((alert, index) => (
                <div key={index} className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
                  {alert.message}
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => removeAlertMessage(index)}
                    aria-label="Close"
                  ></button>
                </div>
              ))}
                <div className="card-body">
                    <h5 className="card-title"><strong>{demande.candidat.nom} {demande.candidat.prenoms}</strong></h5>
                    <h5 className="card-title"><strong>{demande.candidat.poste}</strong></h5>
                    <p className="card-text">
                        <i className="fas fa-briefcase"></i> {demande.client.nom}<br />
                        <i className="fas fa-map-marker-alt"></i> {demande.candidat.location}<br />
                        <i className="fas fa-coins"></i> {demande.candidat.remunerationMin +" €"} - {demande.candidat.remunerationMax +" €"}
                    </p>
                    
                </div>
            </div>
        ))}
        </>
    );
}