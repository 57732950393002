import React, { useState, useEffect } from "react";
import axios from 'axios';
import makeAnimated from 'react-select/animated';
import { Link, useParams } from "react-router-dom";
import NavigationGauche from "../../components/dashboard/navGauch";
import Nav from "../../components/dashboard/nav";
import Champs from "../../components/autentification/champs";
import Boutons from "../../components/autentification/bouton";

export default function EditIndustrie() {
  const { industryId } = useParams();
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [nom, setNom] = useState("");
  const [alertMessages, setAlertMessages] = useState([]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = { nom };
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.post('https://cerebrotbt.com/api/admin/industry/add_industry', formData, { headers });

      const newIndustry = response.data;
      setSelectedIndustry(newIndustry);
      setNom('');
    } catch (error) {
      console.error('Erreur lors de la soumission du formulaire :', error);
    }
  };
  const addAlertMessage = (message, type) => {
    // Ajoutez un nouvel message à la liste des alertes
    setAlertMessages([...alertMessages, { message, type }]);
    // Supprimez automatiquement le message après un certain délai (par exemple, 5 secondes)
    setTimeout(() => {
      removeAlertMessage();
    }, 5000); // 5000 millisecondes (5 secondes)
  };
  const removeAlertMessage = () => {
    // Supprimez le premier message de la liste des alertes
    if (alertMessages.length > 0) {
      const updatedMessages = [...alertMessages];
      updatedMessages.shift();
      setAlertMessages(updatedMessages);
    // Actualisez la page après avoir supprimé le message (délai de 5000 millisecondes)
    setTimeout(() => {
      window.location.reload();
    }, 5000);
    }
  };
  const handleUpdateIndustry = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        nom: selectedIndustry.nom,
      };

      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.post(`https://cerebrotbt.com/api/admin/industry/update/${selectedIndustry.id}`, formData, { headers })
   
       if(response.status === 200){
        addAlertMessage("Industrie mise à jour avec succès", "success");
        setNom('');
       }
  
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'industrie :', error);
      addAlertMessage(`Erreur lors de la modification de l'industrie . ${error.response.data.message}`, "danger");

    }
  };

  useEffect(() => {
    axios
      .get(`https://cerebrotbt.com/api/admin/industry/show/${industryId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        setSelectedIndustry(response.data.Industry);
      })
      .catch((error) => {
        console.error('Erreur lors de la récupération des données de l\'industrie :', error);
      });
  }, [industryId]);

  return (
    <>
      <NavigationGauche />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
        <Nav />
        <div className="container py-4">
          <div className="row mx-7">
          {alertMessages.map((alert, index) => (
          <div key={index} className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
            {alert.message}
            <button
              type="button"
              className="btn-close"
              onClick={() => removeAlertMessage(index)}
              aria-label="Close"
            ></button>
          </div>
        ))}
            <div className="col-md-12 mb-lg-0 mb-4">
              {selectedIndustry && (
                <form role="form" onSubmit={handleUpdateIndustry}>
                  <div className="row">
                    <div className="col-md-9">
                      <div className="input-group input-group-outline mb-3">
                        <Champs
                          TypeChamp="text"
                          value={selectedIndustry.nom}
                          onChange={(e) => setSelectedIndustry({ ...selectedIndustry, nom: e.target.value })}
                          placeholder="Nom"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="input-group input-group-outline mb-3">
                        <Boutons type="submit" valeur="Mise à jour" />
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
