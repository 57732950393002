import axios from 'axios';
import Cookies from "js-cookie";



// Fonction pour récupérer les données depuis l'API RESTful
export async function fetchRolesFromAPI() {
  try {
    const authToken = localStorage.getItem('token'); // Remplacez par le jeton d'authentification approprié
    const response = await axios.get('https://cerebrotbt.com/api/admin/role/index', {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    // Mettez à jour le tableau Industries avec les données de l'API
     return {roles:response.data.Rôle} ;

    
    
  } catch (error) {
    console.error('Erreur lors de la récupération des données depuis l\'API :', error);
    return {error} ;
  }
}

