import { useState, useEffect } from "react";
import axios from 'axios';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import { fetchIndustriesFromAPI } from "../../datas/industries";

import NavigationGauche from "../../components/dashboard/navGauch";
import Nav from "../../components/dashboard/nav";

import Champs from "../../components/autentification/champs";
import Boutons from "../../components/autentification/bouton";
import { useParams } from "react-router-dom";

export default function EditDomaine() {
  const [formattedIndustries, setFormattedIndustries] = useState([]);
  const [domaine, setDomaine] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isDomaineLoading, setIsDomaineLoading] = useState(true);
  const animatedComponents = makeAnimated();
  const [alertMessages, setAlertMessages] = useState([]);
  const [industry, setIndustry] = useState(null);
  const { domaineId } = useParams();
  const [nom, setNom] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = { nom: domaine.nom, industry_id: domaine.industry.id };
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.post(`https://cerebrotbt.com/api/admin/domaine/update/${domaineId}`, formData, { headers });

      const updatedDomaine = response.data;
      /*setDomaine(updatedDomaine);
      setNom(''); */
    } catch (error) {
      console.error('Erreur lors de la soumission du formulaire :', error);
    }
  };
  const addAlertMessage = (message, type) => {
    // Ajoutez un nouvel message à la liste des alertes
    setAlertMessages([...alertMessages, { message, type }]);
    // Supprimez automatiquement le message après un certain délai (par exemple, 5 secondes)
    setTimeout(() => {
      removeAlertMessage();
    }, 5000); // 5000 millisecondes (5 secondes)
  };
  const removeAlertMessage = () => {
    // Supprimez le premier message de la liste des alertes
    if (alertMessages.length > 0) {
      const updatedMessages = [...alertMessages];
      updatedMessages.shift();
      setAlertMessages(updatedMessages);
    // Actualisez la page après avoir supprimé le message (délai de 5000 millisecondes)
    setTimeout(() => {
      window.location.reload();
    }, 5000);
    }
  };
  useEffect(() => {
    fetchIndustriesFromAPI().then((data) => {
      const formatted = data.industries.map(industry => ({
        value: industry.id,
        label: industry.nom,
      }));
      setFormattedIndustries(formatted);
      setIsLoading(false);
    });

    setIsDomaineLoading(true);
    axios
      .get(`https://cerebrotbt.com/api/admin/domaine/show/${domaineId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then((response) => {
        addAlertMessage("Domaine modifier avec succès !", "success");
        setDomaine(response.data.Domaine); // Assurez-vous que le nom de la propriété est correct (domaine vs Domaine)
        setIsDomaineLoading(false);
      })
      .catch((error) => {
        console.error('Erreur lors de la récupération des domaines :', error);
        addAlertMessage(`Erreur lors de la modification du domaine . ${error.response.data.message}`, "danger");
        setIsDomaineLoading(false);
      });
  }, [domaineId]);

  if (isLoading || isDomaineLoading) {
    return <div className="loader"></div>;
  }

  return (
    <>
      <NavigationGauche />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
        <Nav />
        <div className="container py-4">
          <div className="row mx-7">
          {alertMessages.map((alert, index) => (
          <div key={index} className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
            {alert.message}
            <button
              type="button"
              className="btn-close"
              onClick={() => removeAlertMessage(index)}
              aria-label="Close"
            ></button>
          </div>
        ))}
            <div className="col-md-12 mb-lg-0 mb-4">
              <form role="form" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-group input-group-outline mb-3">
                      <Select
                        options={formattedIndustries}
                        isSearchable
                        onChange={(selectedOption) => setIndustry(selectedOption)}
                        value={{ value:domaine.industry ? domaine.industry.id : "", 
                          label: domaine.industry ? domaine.industry.nom : ""}}
                        name="industry_id"
                        placeholder="Industrie"
                        className="col-md"
                      />
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="input-group input-group-outline mb-3">
                      <Champs TypeChamp="text" value={domaine.nom} onChange={(e) => setDomaine({ ...domaine, nom: e.target.value })} placeholder="Nom" />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="input-group input-group-outline mb-3">
                      <Boutons type="submit" valeur="Enregistrer" />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
