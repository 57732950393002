import React, { useState, useEffect, useRef } from "react";
import Axios from 'axios';
import makeAnimated from 'react-select/animated';
import { MDBDataTable } from 'mdbreact';

import { useDataClient } from "../datas/dataTable/tableClients";
import NavigationGauche from "../components/dashboard/navGauch";
import Nav from "../components/dashboard/nav";
import Champs from "../components/autentification/champs";
import { useDataUser } from "../datas/dataTable/tableUsers";


import { fetchIndustriesFromAPI } from "../datas/industries";

export default function AllUsers() {

  const [config, load] = useDataUser();


  const [isLoading, setIsLoading] = useState(true);
  const animatedComponents = makeAnimated();
  const [alertMessages, setAlertMessages] = useState([]);

  const [formattedIndustries, setFormattedIndustries] = useState([]);
  const [nom, setNom] = useState("");
  const [description, setDescription] = useState("");
  const [postes, setPostes] = useState(1);
  const [statut, setStatut] = useState("");
  const [editorContent, setEditorContent] = useState('<p>Description</p>');
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [clients, setClients] = useState([]);
  const [posteValues, setPosteValues] = useState([]);

  const options = [
    { value: 'lead', label: 'Lead' },
    { value: 'prospect', label: 'Prospect' },
    { value: 'active', label: 'Active' },
    { value: 'closed', label: 'Closed' },

  ];

  const customLanguage = {
    pagination: {
      label: "Affichage de {0}-{1} de {2} éléments",
      first: "Premier",
      last: "Dernier",
      previous: "Précédent",
      next: "Suivant",
    },
    search: "Chercher :",
    info: "Affichage de {0}-{1} de {2} éléments",
    infoEmpty: "Affichage de 0-0 de 0 éléments",
  };


  const handlePosteInputChange = (index, value) => {
    const newPosteValues = [...posteValues];
    newPosteValues[index] = value;
    setPosteValues(newPosteValues);
  };
  const champsInputs = [];
  for (let i = 1; i <= postes; i++) {
    champsInputs.push(
      <div key={i} className="col-md-12">
        <div className="input-group input-group-outline mb-3">
          <Champs
            TypeChamp="text"
            placeholder={`Titre poste ${i}`}
            name={`poste_${i}`} // Ajout de l'attribut name
            value={posteValues[i - 1] || ''}
            onChange={(e) => handlePosteInputChange(i - 1, e.target.value)}
          />
        </div>
      </div>
    );
  }

  const editorRef = useRef(null);

  useEffect(() => {
    fetchIndustriesFromAPI().then((data) => {
      const formatted = data.industries.map(industry => ({
        value: industry.id,
        label: industry.nom,
      }));
      setFormattedIndustries(formatted);
      setIsLoading(false);
    });
    const authToken = localStorage.getItem('token');
    const headers = {
      'Authorization': `Bearer ${authToken}`
    };
    // Récupérer la liste des clients
    Axios.get("https://cerebrotbt.com/api/admin/user/index", { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          // Mettez à jour l'état des clients avec les données reçues
          setClients(response.data.clients);

        }
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération de la liste des utilisateurs :", error);
      });
  }, []);


  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  if (isLoading) {
    return <div className="loader"></div>;
  }

  return (
    <>
      <NavigationGauche />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
        <Nav />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div >
                <span className="font-weight-bold mr-4">LIST OF USERS</span>
              </div>
              {
                config
                  ? (
                    <MDBDataTable
                      striped
                      bordered
                      small
                      entriesLabel="See entries"
                      infoLabel={["Show", "to", "from", "elements", "Display of {0}-{1} of {2} items",]}
                      searchLabel="search"
                      noRecordsFoundLabel="No record found"
                      noBottomColumns
                      paginationLabel={["P", "S"]}
                      data={config}
                    />
                  ) :
                  <div className="loader" />
              }
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
