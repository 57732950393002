import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import Boutons from '../components/autentification/bouton';
import { useParams } from 'react-router-dom';
import { fetchUsersFromAPI } from '../datas/user';

export default function MiseEnReseauCandidat() {
  const { candidatId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCandidat, setIsLoadingCandidat] = useState(true);
  const [candidatData, setCandidatData] = useState(null);
  const [formattedUsers, setFormattedUsers] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [matching, setMatching] = useState({});
  const [alertMessages, setAlertMessages] = useState([]);
  const [user, setUser] = useState("");


  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().slice(0, 19).replace("T", " ");


  const handleInputChange = (inputValue) => {
    // Faites ici une recherche ou un filtrage en fonction de la valeur saisie dans le champ de recherche
  };

  const addAlertMessage = (message, type) => {
    // Ajoutez un nouvel message à la liste des alertes
    setAlertMessages([...alertMessages, { message, type }]);
    // Supprimez automatiquement le message après un certain délai (par exemple, 5 secondes)
    setTimeout(() => {
      removeAlertMessage();
    }, 5000); // 5000 millisecondes (5 secondes)
  };

  const removeAlertMessage = () => {
    // Supprimez le premier message de la liste des alertes
    if (alertMessages.length > 0) {
      const updatedMessages = [...alertMessages];
      updatedMessages.shift();
      setAlertMessages(updatedMessages);
      // Actualisez la page après avoir supprimé le message (délai de 5000 millisecondes)
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setMatching((prevMatching) => ({
      ...prevMatching,
      sharedUserId: selectedOption ? selectedOption.value : "",
    }));
  };

  const authToken = localStorage.getItem('token');

  const handleSubmit = async (e) => {
    e.preventDefault(); // Empêcher le formulaire de se soumettre par défaut

    try {
      console.log("Request Data:", {
        candidat_id: parseInt(candidatId),
        sharedUserId: matching ? matching.sharedUserId : '',
        user_id: user,
      });

      const response = await axios.post(
        'https://cerebrotbt.com/api/admin/candidat/miseEnReseau',
        {
            candidat_id: parseInt(candidatId),
            sharedUserId: matching ? matching.sharedUserId : '',
            user_id: user,
        },
        {
          headers: {
            'Authorization': `Bearer ${authToken}`
          }
        }
      );
      if (response.status === 200) {
        addAlertMessage(response.data.message, "success");
      }
      // Vous pouvez gérer la réponse ici (par exemple, afficher un message de succès)
      console.log('Mise en réseau effectué avec succès:', response.data);
    } catch (error) {
      // Afficher des messages d'erreur spécifiques dans la console pour le débogage
      addAlertMessage(`Erreur lors de la mise en réseau: ${error.response.data.message}`, "danger");
      console.error('Erreur lors de la mise en réseau:', error.message);
      console.error('Réponse de l\'erreur:', error.response);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://cerebrotbt.com/api/admin/candidat/show/${candidatId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
        const data = response.data;
        setCandidatData(data);
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error.message);
      }
    };
    //fetchData();
    const userInfoJSON = localStorage.getItem("user");
    // Vérifiez si userInfoJSON est défini
    if (userInfoJSON) {
      // Parsez la chaîne JSON en un objet JavaScript
      const parsedUserInfo = JSON.parse(userInfoJSON);
      // Accédez à la propriété "nom" de l'objet
      const id = parsedUserInfo.id;
      setUser(id);
  } else {
      console.log('Aucune donnée utilisateur trouvée dans le local storage');
  }
  
    fetchUsersFromAPI().then((data) =>{
         const formatted = data ? data.map(user =>({
            value: user.id,
            label: user.nom +' '+user.prenoms
         })) : [];
        setFormattedUsers(formatted);
         setIsLoading(false);
    }) ;

    fetchData();
  }, [candidatId]); // Mettez à jour lorsque candidatId change

  const handleRetour = () => {
    window.history.back();
  };

  return (
    <div>
      <h1 style={{ marginLeft: "50px" }}> <span onClick={handleRetour} title='retour' style={{ marginRight: "20px" }}>&laquo;</span>Partage du  Candidat</h1>
      {candidatData ? (
        <div className='container'>
          {alertMessages.map((alert, index) => (
            <div key={index} className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
              {alert.message}
              <button
                type="button"
                className="btn-close"
                onClick={() => removeAlertMessage(index)}
                aria-label="Close"
              ></button>
            </div>
          ))}
          <div className='row'>
            <div className='col-md-5'>
              <p>Nom: <span style={{ fontWeight: 'bold' }}>{candidatData.Candidat.nom}</span></p>
              <p>Prénoms: <span style={{ fontWeight: 'bold' }}>{candidatData.Candidat.prenoms}</span></p>
              <p>Email: <span style={{ fontWeight: 'bold' }}>{candidatData.Candidat.email}</span></p>
              <p>Téléphone: <span style={{ fontWeight: 'bold' }}>{candidatData.Candidat.telephone}</span></p>
              <p>Adresse: <span style={{ fontWeight: 'bold' }}>{candidatData.Candidat.location}</span></p>
              <p>Poste: <span style={{ fontWeight: 'bold' }}>{candidatData.Candidat.poste}</span></p>
              <p>Industrie: <span style={{ fontWeight: 'bold' }}>{candidatData.Candidat.industries ? candidatData.Candidat.industries.nom : ""}</span></p>
              <p>Domaines: <span style={{ fontWeight: 'bold' }}>{candidatData.Candidat.domaines ? candidatData.Candidat.domaines.map((domaine) => domaine.nom).join(', ') : ""}</span></p>
            </div>
            <div className='col-md-5'>
              <p>Contrat: <span style={{ fontWeight: 'bold' }}>{candidatData.Candidat.contrat ? candidatData.Candidat.contrat.nom : 'N/A'}</span></p>
              <p>Office présence: <span style={{ fontWeight: 'bold' }}>{candidatData.Candidat.office_presences ? candidatData.Candidat.office_presences.nom : ""}</span></p>
              <p>Type de rémunération: <span style={{ fontWeight: 'bold' }}>{candidatData.Candidat.remunerations ? candidatData.Candidat.remunerations.nom : 'N/A'}</span></p>
              <p>Rémuneration Minimal: <span style={{ fontWeight: 'bold' }}>{candidatData.Candidat.remunerationMin}</span></p>
              <p>Rémuneration Max: <span style={{ fontWeight: 'bold' }}>{candidatData.Candidat.remunerationMax}</span></p>
              <p>Linkedin: <span style={{ fontWeight: 'bold' }}>{candidatData.Candidat.lienLinkedin ? candidatData.Candidat.lienLinkedin : 'N/A'}</span></p>
              <p>Github: <span style={{ fontWeight: 'bold' }}>{candidatData.Candidat.lienGithub ? candidatData.Candidat.lienGithub : 'N/A'}</span></p>
              <p>Portefolio: <span style={{ fontWeight: 'bold' }}>{candidatData.Candidat.lienPortefolio ? candidatData.Candidat.lienPortefolio : 'N/A'}</span></p>
              <embed type="application/pdf" src={candidatData.Candidat.cv_path} width="300" height="200" />
            </div>
            <div className='col-md-2'>
              <form onSubmit={handleSubmit}>
                <div className="input-group input-group-outline mb-3">
                  <Select
                    options={formattedUsers}
                    isSearchable
                    onInputChange={handleInputChange}
                    onChange={handleChange}
                    value={selectedOption}
                    name="sharedUserId"
                    placeholder="Rechercher un utilisateur..."
                    className="col-md"
                  />
                </div>
                <Boutons type="submit" valeur="Mise en réseau" />
              </form>
            </div>
          </div>
        </div>
      ) : (
        <p>Chargement en cours...</p>
      )}
    </div>
  );
}
