import React, { useState, useEffect, useRef } from "react";
import Select from 'react-select';
import Axios from 'axios';
import { MDBDataTable } from 'mdbreact';
import NavigationGauche from "../components/dashboard/navGauch";
import Nav from "../components/dashboard/nav";
import Champs from "../components/autentification/champs";
import Boutons from "../components/autentification/bouton";
import Options from "../datas/search";
import { fetchClientsFromAPI } from "../datas/clients";
import { useTableManager } from "../datas/table";


export default function Manager() {
  const [config, load] = useTableManager();

  const [isLoading, setIsLoading] = useState(true);
  const [alertMessages, setAlertMessages] = useState([]);
  const [manager, setManager] = useState({
    nom: "",
    prenoms: "",
    email: "",
    telephone: "",
    poste: "",
    client_id: ""
  });
  const [formattedClients, setFormattedClients] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [error, setError] = useState(null);
  const [user, setUser] = useState("");


  const addAlertMessage = (message, type) => {
    // Ajoutez un nouvel message à la liste des alertes
    setAlertMessages([...alertMessages, { message, type }]);
    
    // Supprimez automatiquement le message après un certain délai (par exemple, 5 secondes)
    setTimeout(() => {
      removeAlertMessage();
    }, 5000); // 5000 millisecondes (5 secondes)
  };
  
  const removeAlertMessage = () => {
    // Supprimez le premier message de la liste des alertes
    if (alertMessages.length > 0) {
      const updatedMessages = [...alertMessages];
      updatedMessages.shift();
      setAlertMessages(updatedMessages);
    }
  };
  
  function removeAlertMessageWithTimeout() {
    // Supprimez automatiquement le message après un certain délai
    setTimeout(() => {
      removeAlertMessage();
    }, 500);
  }
  

  function handleSubmit(evt) {
    evt.preventDefault();

    // Assurez-vous que manager.client_id est défini avant de soumettre le formulaire
    if (!manager.client_id) {
      setError("Please select a customer.");
      return;
    }

    // Vous pouvez personnaliser les données que vous envoyez dans la requête POST ici
    const data = {
      nom: manager.nom,
      prenoms: manager.prenoms,
      email: manager.email,
      telephone: manager.telephone,
      poste: manager.poste,
      client_id: manager.client_id ,// Utilisez la valeur du client_id mis à jour
      user_id : user,
    };
    const authToken = localStorage.getItem('token');
    const headers = {
      'Authorization': `Bearer ${authToken}`
    };
    console.log('donnée: ',data);
    // Envoyez les données du formulaire vers l'URL spécifiée
    Axios.post("https://cerebrotbt.com/api/admin/manager/add_manager", data, { headers: headers, 'Content-Type': 'multipart/form-data' })
      .then((response) => {
        // Gérez la réponse réussie ici (par exemple, réinitialisez le formulaire)
        if (response.status === 201) {
        addAlertMessage("Manager successfully created!", "success");
        removeAlertMessageWithTimeout(); // Ajoutez cette ligne
        setManager({
          nom: "",
          prenoms: "",
          email: "",
          telephone: "",
          poste: "",
          client_id: ""
        });
        setSelectedOption(null);
        setError(null);
      }
       load()
      })
      .catch((error) => {
        // Gérez les erreurs ici
        console.error("Error adding manager :", error);
        addAlertMessage(`Manager creation error . ${error.response.data.errors}`, "danger");
        removeAlertMessageWithTimeout(); // Ajoutez cette ligne
      });
  }

  function HandleChange(evt) {
    const { name, value } = evt.target;
    setManager({ ...manager, [name]: value });
  }

  const handleInputChange = (inputValue) => {
    // Faites ici une recherche ou un filtrage en fonction de la valeur saisie dans le champ de recherche
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setManager({ ...manager, client_id: selectedOption ? selectedOption.value : "" });
  };
  const [dataManagerConfig, setDataManagerConfig] = useState([]);
  /*const fetchData = async () => {
    const dataManagerConfigData = await DataManager({ onRowClick: handleRowClick });
    setDataManagerConfig(dataManagerConfigData);
    console.log('data:', dataManagerConfig);

    // Maintenant que nous avons les données, vous pouvez les utiliser pour configurer votre composant MDBDataTable
    const myData = {
      columns: dataManagerConfig.columns,
      rows: dataManagerConfig.rows,
    };

    return myData;
  };*/
  useEffect(() => {
    fetchClientsFromAPI().then((data) => {
      const formatted = data ? data.map(client => ({
        value: client.id,
        label: client.nom,
      })) : [];
      setFormattedClients(formatted);
      setIsLoading(false);
    });
    //fetchData();
    const userInfoJSON = localStorage.getItem("user");
    // Vérifiez si userInfoJSON est défini
    if (userInfoJSON) {
      // Parsez la chaîne JSON en un objet JavaScript
      const parsedUserInfo = JSON.parse(userInfoJSON);
      // Accédez à la propriété "nom" de l'objet
      const id = parsedUserInfo.id;
      setUser(id);
  } else {
      console.log('Aucune donnée utilisateur trouvée dans le local storage');
  }
  }, []);
 
  const handleRowClick = (entry) => {
    // Gérez les clics de ligne ici
    console.log('Row clicked:', entry);
  };
  if (isLoading) {
    return <div className="loader"></div>;
  }
  return (
    <>
      <NavigationGauche />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
        <Nav />
        <div className="container-fluid">
          <div className="row">
          {alertMessages.map((alert, index) => (
          <div key={index} className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
            {alert.message}
            <button
              type="button"
              className="btn-close"
              onClick={() => removeAlertMessage(index)}
              aria-label="Close"
            ></button>
          </div>
        ))}
            <div className="col-md-12 mb-lg-0 mb-4">
              <form role="form" onSubmit={(evt) => handleSubmit(evt)}>
                <div className="row mx-8">
                  <div className="col-md-12">
                    <div className="input-group input-group-outline mb-3">
                      <Champs TypeChamp="text" name="nom" value={manager.nom} onChange={(evt) => HandleChange(evt)} placeholder="Last name" />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-group input-group-outline mb-3">
                      <Champs TypeChamp="text" name="prenoms" value={manager.prenoms} onChange={(evt) => HandleChange(evt)} placeholder="First name(s)" />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-group input-group-outline mb-3">
                      <Champs TypeChamp="email" name="email" value={manager.email} onChange={(evt) => HandleChange(evt)} placeholder="Email" />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-group input-group-outline mb-3">
                      <Champs TypeChamp="text" name="telephone" value={manager.telephone} onChange={(evt) => HandleChange(evt)} placeholder="Phone" />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-group input-group-outline mb-3">
                      <Champs TypeChamp="text" name="poste" value={manager.poste} onChange={(evt) => HandleChange(evt)} placeholder="Job" />
                    </div>
                  </div>
                  {/* Ajoutez ici les autres champs similaires (prénom, email, téléphone, poste) */}
                  {/* ... */}
                  <div className="col-md-12">
                    <div className="input-group input-group-outline">
                      <Select
                        options={formattedClients}
                        isSearchable
                        onInputChange={handleInputChange}
                        onChange={handleChange}
                        value={selectedOption}
                        name="client_id"
                        placeholder="Customer search..."
                        className="col-md"
                      />
                    </div>
                  </div>
                </div>
                <div className="row mx-8" >
                  <div className="col-md">
                    <div className="mb-3" style={{ display: "flex", backgroundColor: "#fb8c0014", borderRadius: "5px", padding: "10px 8px" }}>
                      <p className="mb-0">Selected customer : {selectedOption ? selectedOption.label : 'No'}</p>
                    </div>
                  </div>
                </div>
                <div className="row mx-8">
                  <div className="col-md-3">
                      <Boutons type="submit" valeur="Save" />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="row mt-4 pt-4" >
            <div className="col-md-12">
              <div >
                <span className="font-weight-bold mr-4">LIST OF MANAGERS</span> 
              </div>
              {/*Tableau de manager*/}
              {config ? (
              <MDBDataTable
                striped
                bordered
                small
                entriesLabel="See entries"
                infoLabel={["Show", "to", "from", "elements", "Display of {0}-{1} of {2} items",]}
                searchLabel="search"
                noRecordsFoundLabel="No record found"
                noBottomColumns
                paginationLabel={["P", "S"]}
               data={config}
               onRowClick={handleRowClick}
              />
              ):(<div className="loader"></div>)}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}