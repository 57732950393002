

import TopNav from "../components/autentification/topnav";
import about from "../assets/img/About.jpg";

export default function About() {


    return (
        <>
            <TopNav />

            <main className="main-content  mt-9">
                <h1 style={{ textAlign: "center" }}>ABOUT US</h1>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <img src={about} alt="About" style={{ width: "90%" }} className="mb-4"/>
                            </div>
                            <div className="col-md-6">
                                <p style={{ textAlign: "justify",paddingTop: '134px' }}>
                                Cerebro_TBT is a revolutionary talent marketplace, offering candidates an unrivalled platform for optimizing their job search. With an innovative approach, it not only uncovers opportunities, but also leverages personal networks to access coveted positions. What's more, this powerful tool enables Techbytech's guests and agents to effectively manage their opportunities, while being rewarded for their efforts.                 
                                 </p>
                            </div>
                        </div>
                    </div>

                </section>
            </main>

        </>
    );
}
