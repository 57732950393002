import { useState, useEffect,useRef } from "react";
import axios from 'axios';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import { MDBDataTable } from 'mdbreact';
import {fetchIndustriesFromAPI} from "../datas/industries";
import { useTableDomaine } from "../datas/dataTable/tableDomaine";


import DataDomaine from "../datas/dataTable/tableDomaine";
import NavigationGauche from "../components/dashboard/navGauch";
import Nav from "../components/dashboard/nav";

import Champs from "../components/autentification/champs";
import Boutons from "../components/autentification/bouton";

import { Link } from "react-router-dom";

export default function Domaine() {
 
  const [config, load] = useTableDomaine();


  const [formattedIndustries, setFormattedIndustries] = useState([]);
  const [domaines, setDomaines] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDomainesLoading, setIsDomainesLoading] = useState(true);
  const animatedComponents = makeAnimated();
  const [industry, setIndustry] = useState(null); // Initialisez-le à null ou à une valeur par défaut si nécessaire



  const [nom, setNom] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault(); // Empêche la soumission par défaut du formulaire

    try {
      // Créez un objet contenant les données que vous souhaitez envoyer
      const formData = { nom, industry_id: industry.value };

      // Définissez votre jeton d'authentification Bearer ici
      const token = localStorage.getItem('token');

      // Configurez l'en-tête d'autorisation avec le jeton Bearer
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      // Envoyez la requête POST à l'URL spécifiée avec les données du formulaire et l'en-tête d'autorisation
      const response = await axios.post('https://cerebrotbt.com/api/admin/domaine/add_domaine', formData, { headers });

      // Traitez la réponse ici, par exemple, affichez un message de succès
      console.log('Réponse du serveur :', response.data);
       // Ajoutez la nouvelle industrie à la liste existante
    const newDomaine = response.data; // Assurez-vous que response.data contient les détails de la nouvelle industrie
    setDomaines(prevDomaines => [...prevDomaines, newDomaine]);
      // Réinitialisez le champ "nom" après la soumission réussie
      setNom('');
      await load();
    } catch (error) {
      // Gérez les erreurs ici, par exemple, affichez un message d'erreur
      console.error('Erreur lors de la soumission du formulaire :', error);
    }
  };

  useEffect(() => {
    
    fetchIndustriesFromAPI().then((data) => {
        const formatted = data.industries.map(industry => ({
          value: industry.id,
          label: industry.nom,
        }));
        setFormattedIndustries(formatted);
        setIsLoading(false);
    });

    setIsDomainesLoading(true); // Démarrez le chargement des domaines
    // Effectuez une requête HTTP GET pour obtenir la liste des industries
    axios.get('https://cerebrotbt.com/api/admin/domaine/index', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`, // Assurez-vous d'ajuster l'en-tête d'authentification selon votre API
      }
    })
    .then(response => {
      // Mettez à jour l'état des industries avec les données reçues
      setDomaines(response.data.Domaine);
      setIsDomainesLoading(false); // Terminez le chargement
    })
    .catch(error => {
      console.error('Erreur lors de la récupération des industries :', error);
      setIsDomainesLoading(false); // Terminez le chargement même en cas d'erreur
    });
  }, []);
  if (isLoading) {
    return <div className="loader"></div>;
  }
  return (
    <>

      <NavigationGauche />


      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
        <Nav />
        <div className="container py-4">

          <div className="row mx-7">
            <div className="col-md-12 mb-lg-0 mb-4">

              <form role="form" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-12">
                        <div className="input-group input-group-outline mb-3">
                        <Select
                        options={formattedIndustries}
                        isSearchable
                        onChange={(selectedOption) => setIndustry(selectedOption)} // Assurez-vous d'avoir un état `industry` pour stocker la sélection
                        value={industry}
                        name="industry_id"
                        placeholder="Industry"
                        className="col-md"
                        />

                        </div>
                    </div>
                    <div className="col-md-10">
                      <div className="input-group input-group-outline mb-3">
                        <Champs TypeChamp="text" value={nom} onChange={(e) => { setNom(e.target.value) }} placeholder="Name" />
                    
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="input-group input-group-outline mb-3">
                        <Boutons type="submit" valeur="Save" />

                      </div>
                    </div>

                  </div>
              </form>
            </div>
          </div>

          <div className="row mt-4" >
            <div className="col-12">
            <p className="font-weight-bold">LIST OF DOMAINS</p>
             {isDomainesLoading ? (
                <div className="loader"></div>
             ):(
              <>
              <MDBDataTable
                striped
                bordered
                small
                entriesLabel="See entries"
                infoLabel={["Show", "to", "from", "elements", "Display of {0}-{1} of {2} items",]}
                searchLabel="search"
                noRecordsFoundLabel="No record found"
                noBottomColumns
              paginationLabel={["P", "S"]}
                data={config}
              />
               {/*<div className="card my-2">
                <div className="card-body px-0 pb-2">
                  <div className="table-responsive p-0">
                    <table className="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nom</th>
                          <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">actions</th>
                        </tr>
                      </thead>
                      <tbody>
                      {domaines.map(domaine => (
                          <tr key={domaine.id}>
                            <td>
                              <div className="d-flex px-2 py-1">
                                <div className="d-flex flex-column justify-content-center">
                                  <h6 className="mb-0 text-sm">{domaine.nom}</h6>
                                </div>
                              </div>
                            </td>
                            <td className="align-middle text-center text-sm">
                              <Link to="" className="text-secondary font-weight-normal text-xs" data-toggle="tooltip" data-original-title="View">
                                <i className="material-icons opacity-10">visibility</i>
                              </Link>
                              <Link to="" className="text-secondary font-weight-normal text-xs" data-toggle="tooltip" data-original-title="Edit">
                                <i className="material-icons opacity-10">edit</i>
                              </Link>
                              <Link to="" className="text-dander font-weight-normal text-xs" data-toggle="tooltip" data-original-title="Delete">
                                <i className="material-icons opacity-10">delete</i>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>*/}
             </>
              )}
            </div>
          </div>


        </div>
      </main>


    </>
  );
}