import axios from 'axios';
import Cookies from "js-cookie";



// Fonction pour récupérer les données depuis l'API RESTful
export async function fetchDomainesByIndustryFromAPI(industryId) {
  try {
    const authToken = localStorage.getItem('token'); // Remplacez par le jeton d'authentification approprié
    const response = await axios.get(`https://cerebrotbt.com/api/admin/domaine/index?industry_id=${industryId}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return { domaines: response.data.Domaine };
  } catch (error) {
    console.error('Erreur lors de la récupération des données depuis l\'API :', error);
    return { error };
  }
}


