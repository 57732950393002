import { useState, useEffect } from "react";
import { Carousel, Button } from 'react-bootstrap';

import Cookies from "js-cookie";
import axios from "axios";
import ivana from "../assets/img/ivana-squares.jpg"
import NavigationGauche from "../components/dashboard/navGauch";
import Nav from "../components/dashboard/nav";

export default function Dashboard() {
  const [index, setIndex] = useState(0);
  const [indexLaste, setIndexLaste] = useState(0);
  const [user, setUser] = useState(null);
  const [nombrePostes, setNombrePostes] = useState(null);
  const [loading, setLoading] = useState(true);
  const [candidats, setCandidats] = useState([]);
  const [matching, setMatching] = useState([]);
  const [interview, setInterview] = useState([]);
  const [lastes, setLastes] = useState([]);
  const [postes, setPostes] = useState([]);
  const [recruited, setRecruited] = useState([]);
  const [from, setFrom] = useState([]);
  const ind = 0;

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const handleSelectLaste = (selectedIndex, e) => {
    setIndexLaste(selectedIndex);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Avant la requête Axios');
        const authToken = localStorage.getItem('token');
        const userString = localStorage.getItem("user");
        let userObject = null;

        if (userString) {
          try {
            userObject = JSON.parse(userString);
            setUser(userObject);
          } catch (error) {
            console.error("Erreur lors de la conversion de la chaîne JSON en objet :", error);
          }
        }

        if (authToken && userObject) {
          const userId = userObject.id;

          const [profilResponse, nombrePostesResponse] = await Promise.all([
            axios.get(`https://cerebrotbt.com/api/user/show/${userId}`, {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }).catch(error => {
              console.error('Erreur dans la requête Axios pour profilResponse:', error);
              return { data: null }; // Retourne une réponse vide pour continuer le traitement
            }),
            axios.get(`https://cerebrotbt.com/api/admin/poste/nbrePostes`, {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }).catch(error => {
              console.error('Erreur dans la requête Axios pour nombrePostesResponse:', error);
              return { data: null }; // Retourne une réponse vide pour continuer le traitement
            }),
          ]);
          console.log('profil: ', profilResponse.data.user.from);
          if (profilResponse.data && profilResponse.data.user.from) {
            const venuDe = profilResponse.data.user.from;
            setFrom(venuDe);
            setLoading(false);
          } else {
            console.error('Erreur dans la requête Axios pour venu de:', profilResponse.data);
          }

          // Ajoute ces console.log pour débugger
          if (nombrePostesResponse.data && nombrePostesResponse.data.nombre_postes) {
            const nbrePostes = nombrePostesResponse.data.nombre_postes;
            setNombrePostes(nbrePostes);
            setLoading(false);
          } else {
            console.error('Erreur dans la requête Axios pour nombrePostes:', nombrePostesResponse.data);
          }
        }

        console.log('Après la requête Axios');
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }

      /*try {
        const authToken = localStorage.getItem('token');
        console.log('authToken: ', authToken)
        const response = await axios.get('https://cerebrotbt.com/api/admin/candidat/index', {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        setCandidats(response.data.candidats); // Supposant que la réponse contient un tableau de candidats
      } catch (error) {
        console.error('Error fetching data:', error);
      }*/
      try {
        const authToken = localStorage.getItem('token');
        console.log('authToken: ', authToken)
        const response = await axios.get('https://cerebrotbt.com/api/admin/poste/latest', {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        setPostes(response.data.lastes); // Supposant que la réponse contient un tableau de candidats
      } catch (error) {
        console.error('Error fetching data:', error);
      }
       try {
        const authToken = localStorage.getItem('token');
        console.log('authToken: ', authToken)
        const response = await axios.get('https://cerebrotbt.com/api/admin/candidat/latest', {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        setLastes(response.data.lastes); // Supposant que la réponse contient un tableau de candidats
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      try {
        const authToken = localStorage.getItem('token');
        console.log('authToken: ', authToken)
        const response = await axios.get('https://cerebrotbt.com/api/admin/candidat/interview/show', {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        console.log('cand1:', response.data.candidats);
      
        // Vérifier si response.data.candidats est un tableau
        if (Array.isArray(response.data.candidats)) {
          setCandidats(response.data.candidats); // Supposant que la réponse contient un tableau de candidats
        } else {
          console.error('Error: response.data.candidats is not an array');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      

      try {
        const authToken = localStorage.getItem('token');
        console.log('authToken: ', authToken)
        const response = await axios.get('https://cerebrotbt.com/api/admin/matching/nbreDemd', {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        console.log('cand:', response.data)
        setMatching(response.data.total_demandes); // Supposant que la réponse contient un tableau de candidats
      } catch (error) {
        console.error('Error fetching data:', error);
      }

      try {
        const authToken = localStorage.getItem('token');
        console.log('authToken: ', authToken)
        const response = await axios.get('https://cerebrotbt.com/api/admin/candidat/interview/count', {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        console.log('interview:', response.data)
        setInterview(response.data.nombre_candidats_interview); // Supposant que la réponse contient un tableau de candidats
      } catch (error) {
        console.error('Error fetching data:', error);
      }

      try {
        const authToken = localStorage.getItem('token');
        console.log('authToken: ', authToken)
        const response = await axios.get('https://cerebrotbt.com/api/admin/candidat/recruited/count', {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        console.log('recruited:', response.data)
        setRecruited(response.data.nombre_candidats_recruited); // Supposant que la réponse contient un tableau de candidats
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  if (!user) {
    return <div className="loader"></div>;
  }

  return (
    <>

      <NavigationGauche />


      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
        <Nav />
        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
              <div className="card">
                <div className="card-header p-3 pt-2">
                  <div className="icon icon-lg icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl position-absolute">
                    <i className="material-icons opacity-10">work</i>
                  </div>
                  <div className="text-end pt-1">
                    <p className="text-sm mb-0 text-capitalize">Jobs</p>
                    <h4 className="mb-0">{nombrePostes !== null ? nombrePostes : 0}{/*<div className="loader_data "></div>*/}</h4>
                  </div>
                </div>
                <hr className="dark horizontal my-0" />
                {/*<div className="card-footer p-3">
                  <p className="mb-0"><span className="text-success text-sm font-weight-bolder">+55% </span>que la semaine dernière</p>
                </div>*/}
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
              <div className="card">
                <div className="card-header p-3 pt-2">
                  <div className="icon icon-lg icon-shape bg-gradient-success shadow-success text-center border-radius-xl position-absolute">
                    <i className="material-icons opacity-10">join_inner</i>
                  </div>
                  <div className="text-end pt-1">
                    <p className="text-sm mb-0 text-capitalize">Matching</p>
                    {console.log('matching: ', matching)}
                    <h4 className="mb-0">{matching !== null ? matching : 0}</h4>
                  </div>
                </div>
                <hr className="dark horizontal my-0" />
                {/*<div className="card-footer p-3">
                  <p className="mb-0"><span className="text-danger text-sm font-weight-bolder">-2%</span> qu'hier</p>
                </div>*/}
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div className="card">
                <div className="card-header p-3 pt-2">
                  <div className="icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl  position-absolute">
                    <i className="material-icons opacity-10">support_agent</i>
                  </div>
                  <div className="text-end pt-1">
                    <p className="text-sm mb-0 text-capitalize">Interview</p>
                    <h4 className="mb-0">{interview !== null ? interview : 0}</h4>
                  </div>
                </div>
                <hr className="dark horizontal my-0" />
                {/*<div className="card-footer p-3">
                  <p className="mb-0"><span className="text-success text-sm font-weight-bolder">+5% </span>qu'hier</p>
                </div>*/}
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
              <div className="card">
                <div className="card-header p-3 pt-2">
                  <div className="icon icon-lg icon-shape bg-gradient-primary shadow-primary text-center border-radius-xl position-absolute">
                    <i className="material-icons opacity-10">people</i>
                  </div>
                  <div className="text-end pt-1">
                    <p className="text-sm mb-0 text-capitalize">Recruit</p>
                    <h4 className="mb-0">{recruited !== null ? recruited : 0}</h4>
                  </div>
                </div>
                <hr className="dark horizontal my-0" />
                {/*<div className="card-footer p-3">
                  <p className="mb-0"><span className="text-success text-sm font-weight-bolder">+3% </span>que le mois dernier</p>
                </div>*/}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-lg-0 mb-4">
              <div className="card mt-4">
                <div className="card-header pb-0 p-3">
                  <div className="row">
                    <div className="col-6 d-flex align-items-center">
                      <h6 className="mb-0">Next interview</h6>
                    </div>
                  </div>
                </div>
                <div className="card-body p-3">
                  <Carousel activeIndex={index} onSelect={handleSelect} interval={null}>
                    {console.log("candidats: ",candidats)}
                    {candidats.map((candidat, i) => (
                      <Carousel.Item key={i}>
                        <div className="row">
                          {[i, i + 1, i + 2].map(index => {
                            if (index < candidats.length) {
                              const candidat = candidats[index];
                              return (
                                <div key={candidat.id} className="col-md-4">
                                  <div className="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row">
                                   {/* <img className="w-30 me-3 mb-0" src={candidat.avatar} alt={`${candidat.nom} ${candidat.prenom}`} style={{ borderRadius: "20px", width: "30% !important" }} />*/}
                                    <div>
                                      <h6 className="mb-0">Full name : {candidat.nom} {candidat.prenoms}</h6>
                                      <p className="mb-0">Job title : {candidat.poste}</p>
                                      <p className="mb-0">Email : {candidat.email}</p>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                            return null;
                          })}
                        </div>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                  <div className="d-flex justify-content-between mt-3">
                    <Button className="btn btn-lg bg-gradient-primary btn-lg mb-0" onClick={() => setIndex(index === 0 ? candidats.length - 1 : index - 1)}>Previous</Button>
                    <Button className="btn btn-lg bg-gradient-primary btn-lg  mb-0" onClick={() => setIndex(index === candidats.length - 3 ? 0 : index + 1)}>Next</Button>
                  </div>
                </div>

              </div>
            </div>
          </div>
          {from === "self" ?
            <>
              <div className="row">
                <div className="col-md-12 mb-lg-0 mb-4">
                  <div className="card mt-4">
                    <div className="card-header pb-0 p-3">
                      <div className="row">
                        <div className="col-6 d-flex align-items-center">
                          <h6 className="mb-0">My latest posts</h6>
                        </div>
                      </div>
                    </div>
                    <div className="card-body p-3">
                      <Carousel activeIndex={index} onSelect={handleSelect} interval={null}>
                        {postes.map((candidat, k) => (
                          <Carousel.Item key={k}>
                            <div className="row">
                              {[k, k + 1, k + 2].map(index => {
                                if (index < postes.length) {
                                  const poste = postes[index];
                                  return (
                                    <div key={poste.id} className="col-md-4">
                                      <div className="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row">
                                        <div>
                                          <h6 className="mb-0">Job title : {poste.titre}</h6>
                                          <p className="mb-0">Adress : {poste.location}</p>
                                          <p className="mb-0">Company : {poste.client.nom}</p>
                                          <p className="mb-0">Contract type : {poste.contrat.nom}</p>
                                          <p className="mb-0">Type of presence : {poste.office.nom}</p>


                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                                return null;
                              })}
                            </div>
                          </Carousel.Item>
                        ))}
                      </Carousel>
                      <div className="d-flex justify-content-between mt-3">
                        <Button className="btn btn-lg bg-gradient-primary btn-lg mb-0" onClick={() => setIndex(index === 0 ? lastes.length - 1 : index - 1)}>Previous</Button>
                        <Button className="btn btn-lg bg-gradient-primary btn-lg  mb-0" onClick={() => setIndex(index === lastes.length - 3 ? 0 : index + 1)}>Next</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mb-lg-0 mb-4">
                  <div className="card mt-4">
                    <div className="card-header pb-0 p-3">
                      <div className="row">
                        <div className="col-6 d-flex align-items-center">
                          <h6 className="mb-0">My latest candidates</h6>
                        </div>
                      </div>
                    </div>
                    <div className="card-body p-3">
                      <Carousel activeIndex={indexLaste} onSelect={handleSelectLaste} interval={null}>
                        {lastes.map((candidat, k) => (
                          <Carousel.Item key={k}>
                            <div className="row">
                              {[k, k + 1, k + 2].map(ind => {
                                if (indexLaste < lastes.length) {
                                  const candidat = lastes[indexLaste];
                                  return (
                                    <div key={candidat.id} className="col-md-4">
                                      <div className="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row">
                                        <div>
                                        <h6 className="mb-0">Full name : {candidat.nom} {candidat.prenoms}</h6>
                                      <p className="mb-0">Job title : {candidat.poste}</p>
                                      <p className="mb-0">Email : {candidat.email}</p>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                                return null;
                              })}
                            </div>
                          </Carousel.Item>
                        ))}
                      </Carousel>
                      <div className="d-flex justify-content-between mt-3">
                        <Button className="btn btn-lg bg-gradient-primary btn-lg mb-0" onClick={() => setIndex(indexLaste === 0 ? lastes.length - 1 : indexLaste - 1)}>Previous</Button>
                        <Button className="btn btn-lg bg-gradient-primary btn-lg  mb-0" onClick={() => setIndex(indexLaste === lastes.length - 3 ? 0 : indexLaste + 1)}>Next</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
            :
            <>
              <div className="row">
                <div className="col-md-12 mb-lg-0 mb-4">
                  <div className="card mt-4">
                    <div className="card-header pb-0 p-3">
                      <div className="row">
                        <div className="col-6 d-flex align-items-center">
                          <h6 className="mb-0">Latest public posts</h6>
                        </div>
                      </div>
                    </div>
                    <div className="card-body p-3">
                      <Carousel activeIndex={index} onSelect={handleSelect} interval={null}>
                        {postes.map((candidat, k) => (
                          <Carousel.Item key={k}>
                            <div className="row">
                              {[k, k + 1, k + 2].map(index => {
                                if (index < postes.length) {
                                  const poste = postes[index];
                                  return (
                                    <div key={poste.id} className="col-md-4">
                                      <div className="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row">
                                        <div>
                                          <h6 className="mb-0">Job title : {poste.titre}</h6>
                                          <p className="mb-0">Adress : {poste.location}</p>
                                          <p className="mb-0">Company : {poste.client.nom}</p>
                                          <p className="mb-0">Contract type : {poste.contrat.nom}</p>
                                          <p className="mb-0">Type of presence : {poste.office.nom}</p>


                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                                return null;
                              })}
                            </div>
                          </Carousel.Item>
                        ))}
                      </Carousel>
                      <div className="d-flex justify-content-between mt-3">
                        <Button className="btn btn-lg bg-gradient-primary btn-lg mb-0" onClick={() => setIndex(index === 0 ? lastes.length - 1 : index - 1)}>Previous</Button>
                        <Button className="btn btn-lg bg-gradient-primary btn-lg  mb-0" onClick={() => setIndex(index === lastes.length - 3 ? 0 : index + 1)}>Next</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mb-lg-0 mb-4">
                  <div className="card mt-4">
                    <div className="card-header pb-0 p-3">
                      <div className="row">
                        <div className="col-6 d-flex align-items-center">
                          <h6 className="mb-0">Last public candidates</h6>
                        </div>
                      </div>
                    </div>
                    <div className="card-body p-3">
                      <Carousel activeIndex={indexLaste} onSelect={handleSelectLaste} interval={null}>
                        {lastes.map((candidat, k) => (
                          <Carousel.Item key={k}>
                            <div className="row">
                              {[k, k + 1, k + 2].map(ind => {
                                if (indexLaste < lastes.length) {
                                  const candidat = lastes[indexLaste];
                                  return (
                                    <div key={candidat.id} className="col-md-4">
                                      <div className="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row">
                                        <div>
                                        <h6 className="mb-0">Full name : {candidat.nom} {candidat.prenoms}</h6>
                                      <p className="mb-0">Job title : {candidat.poste}</p>
                                      <p className="mb-0">Email : {candidat.email}</p>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                                return null;
                              })}
                            </div>
                          </Carousel.Item>
                        ))}
                      </Carousel>
                      <div className="d-flex justify-content-between mt-3">
                        <Button className="btn btn-lg bg-gradient-primary btn-lg mb-0" onClick={() => setIndex(indexLaste === 0 ? lastes.length - 1 : indexLaste - 1)}>Previous</Button>
                        <Button className="btn btn-lg bg-gradient-primary btn-lg  mb-0" onClick={() => setIndex(indexLaste === lastes.length - 3 ? 0 : indexLaste + 1)}>Next</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        </div>
      </main>


    </>
  );
}