import React, { useState, useEffect, useRef } from "react";
import Select from 'react-select';
import Axios from 'axios';
import makeAnimated from 'react-select/animated';
import { Link } from "react-router-dom";
import { MDBDataTable } from 'mdbreact';

import { useDataClient } from "../datas/dataTable/tableClients";
import NavigationGauche from "../components/dashboard/navGauch";
import Nav from "../components/dashboard/nav";
import Champs from "../components/autentification/champs";
import Boutons from "../components/autentification/bouton";
import Textarea from '../components/autentification/textarea';

import { fetchIndustriesFromAPI } from "../datas/industries";

export default function Client() {
  const [config, load] = useDataClient();

  const [isLoading, setIsLoading] = useState(true);
  const animatedComponents = makeAnimated();
  const [alertMessages, setAlertMessages] = useState([]);

  const [formattedIndustries, setFormattedIndustries] = useState([]);
  const [nom, setNom] = useState("");
  const [description, setDescription] = useState("");
  const [postes, setPostes] = useState(1);
  const [statut, setStatut] = useState("");
  const [editorContent, setEditorContent] = useState('<p>Description</p>');
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [clients, setClients] = useState([]);
  const [posteValues, setPosteValues] = useState([]);
  const [user, setUser] = useState("");
  const [userFrom, setUserFrom] = useState(null);



  const options = [
    { value: 'lead', label: 'Lead' },
    { value: 'prospect', label: 'Prospect' },
    { value: 'active', label: 'Active' },
    { value: 'closed', label: 'Closed' },
  ];

  const madataireOptions = [
    { value: 'prospect', label: 'Prospect' },
    { value: 'privé', label: 'Privé' },
    { value: 'public', label: 'Public' },
    { value: 'fermer', label: 'Fermer' },

];

const inviteOptions = [
    { value: 'prospect', label: 'Prospect' },
    { value: 'public', label: 'Public' },
    { value: 'fermer', label: 'Fermer' },

];

  const addAlertMessage = (message, type) => {
    // Ajoutez un nouvel message à la liste des alertes
    setAlertMessages([...alertMessages, { message, type }]);
    // Supprimez automatiquement le message après un certain délai (par exemple, 5 secondes)
    setTimeout(() => {
      removeAlertMessage();
    }, 5000); // 5000 millisecondes (5 secondes)
  };
  const removeAlertMessage = () => {
    // Supprimez le premier message de la liste des alertes
    if (alertMessages.length > 0) {
      const updatedMessages = [...alertMessages];
      updatedMessages.shift();
      setAlertMessages(updatedMessages);
      // Actualisez la page après avoir supprimé le message (délai de 5000 millisecondes)
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  };

  const handlePostesChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    if (!isNaN(newValue) && newValue >= 1) {
      setPostes(newValue);
    } else {
      setPostes(1);
    }
  };
  const handleEditorChange = (content) => {
    // Cette fonction sera appelée lorsque le contenu de l'éditeur TinyMCE changera
    setEditorContent(console.log("description: ", content));
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (!selectedIndustries) {
      console.error("Le champ 'industries' est requis. ");
      return;
    }
    // Assurez-vous que 'statut' est une chaîne de caractères avant de l'ajouter à formData
    const statutValue = statut ? statut.value : '';
    const formData = new FormData();
    formData.append('nom', nom);
    formData.append('description', description);
    formData.append('statut', statutValue);
    formData.append('nbr_postes_libres', postes);
    formData.append('user_id', user);

    selectedIndustries.forEach((selectedIndustries) => {
      formData.append('industries[]', selectedIndustries.value);
    });
    for (let i = 0; i < postes; i++) {
      formData.append(`postes_libres[${i}]`, posteValues[i] || ''); // Ajouter name attribut
    }
    const authToken = localStorage.getItem('token');
    const headers = {
      'Authorization': `Bearer ${authToken}`
    };
    Axios.post("https://cerebrotbt.com/api/admin/client/add_client", formData, { headers: headers, 'Content-Type': 'multipart/form-data' })
      .then((response) => {
        if (response.status === 201) {
          addAlertMessage("Client créé avec succès !", "success");
          setNom("");
          setDescription("");
          setStatut("");
          setPostes(1);
          setSelectedIndustries([]);
          setPosteValues([]);
        }
        load()
      })
      .catch((error) => {
        console.error("Erreur lors de la création du client :", error);
        addAlertMessage(`Erreur lors de la création du client . ${error.response.data.message}`, "danger");
      });
  };
  const handlePosteInputChange = (index, value) => {
    const newPosteValues = [...posteValues];
    newPosteValues[index] = value;
    setPosteValues(newPosteValues);
  };
  const champsInputs = [];
  for (let i = 1; i <= postes; i++) {
    champsInputs.push(
      <div key={i} className="col-md-12">
        <div className="input-group input-group-outline mb-3">
          <Champs
            TypeChamp="text"
            placeholder={`Job title ${i}`}
            name={`poste_${i}`} // Ajout de l'attribut name
            value={posteValues[i - 1] || ''}
            onChange={(e) => handlePosteInputChange(i - 1, e.target.value)}
          />
        </div>
      </div>
    );
  }

  const editorRef = useRef(null);

  useEffect(() => {
    fetchIndustriesFromAPI().then((data) => {
      const formatted = data.industries ? data.industries.map(industry => ({
        value: industry.id,
        label: industry.nom,
      })) : [];
      setFormattedIndustries(formatted);
      setIsLoading(false);
    });
    const authToken = localStorage.getItem('token');
    const headers = {
      'Authorization': `Bearer ${authToken}`
    };
    const userInfoJSON = localStorage.getItem("user");
    // Vérifiez si userInfoJSON est défini
    if (userInfoJSON) {
      // Parsez la chaîne JSON en un objet JavaScript
      const parsedUserInfo = JSON.parse(userInfoJSON);
      // Accédez à la propriété "nom" de l'objet
      const from = parsedUserInfo.from;
      const id = parsedUserInfo.id;
      setUserFrom(from);
      setUser(id);
    } else {
      console.log('Aucune donnée utilisateur trouvée dans le local storage');
    }

    // Récupérer la liste des clients
    Axios.get("https://cerebrotbt.com/api/admin/client/index", { headers: headers })
      .then((response) => {
        console.log("clients :", response.data?.clients)
        setClients(response.data?.clients ?? []);
        setIsLoading(false);
        /*if (response.status === 200) {
          // Mettez à jour l'état des clients avec les données reçues
          setClients(response.data?.clients ?? []);
        }else if(response.status === 404){
          setClients("Aucun client trouvé pour l'utilisateur connecté.");
        } else {
          setClients("Erreur lors de la récupération de la liste des clients.");
        }
        console.log("clients:: ",response);*/

      })
      .catch((error) => {
        console.error("Erreur lors de la récupération de la liste des clients :", error);
      });
  }, []);


  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };
  const handleRowClick = (entry) => {
    // Gérez les clics de ligne ici
    console.log('Row clicked:', entry);
  };

  if (isLoading) {
    return <div className="loader"></div>;
  }

  return (
    <>
      <NavigationGauche />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
        <Nav />
        <div className="container-fluid">
          <div className="row mx-7">
            {alertMessages.map((alert, index) => (
              <div key={index} className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
                {alert.message}
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => removeAlertMessage(index)}
                  aria-label="Close"
                ></button>
              </div>
            ))}

            <div className="col-md-12 mb-lg-0 mb-4">
              <form role="form" onSubmit={handleFormSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-group input-group-outline mb-3">
                      <Champs TypeChamp="text" value={nom} onChange={(e) => { setNom(e.target.value) }} placeholder="Last name" />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-group input-group-outline mb-3">
                      <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isSearchable
                        isMulti
                        name="industry_id"
                        onChange={(selectedOption) => {
                          setSelectedIndustries(selectedOption); // Met à jour le state
                          console.log('VAleur industrie  : ', selectedOption)

                        }}
                        value={selectedIndustries}
                        placeholder="Industry"
                        options={formattedIndustries}
                        className="col"
                      />
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="input-group input-group-outline mb-3">
                      <Champs TypeChamp="number" value={postes} onChange={handlePostesChange} placeholder="Number of vacancies" />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="input-group input-group-outline mb-3">
                      <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        options={userFrom === "self" ? inviteOptions : madataireOptions}
                        value={statut}
                        name="statut"
                        onChange={(selectedOption) => setStatut(selectedOption)}
                        placeholder="Status"
                        className="col"
                      />
                    </div>
                  </div>
                  {champsInputs}
                </div>
                <div className="row">
                  <div className="col-md">
                    <div className="input-group input-group-outline mb-3">
                      {/* Appel du composant TinyMCEEditor avec les propriétés name, value et onChange */}
                      {/* <TinyMCEEditor
                        name="description" // Le nom de votre champ
                        value={editorContent} // La valeur du champ
                        onChange={handleEditorChange} // La fonction onChange
                      />*/}
                      {/*<textarea 
                    name="description" // Le nom de votre champ
                        value={editorContent} // La valeur du champ
                        onChange={handleEditorChange} // La fonction onChange
                        ></textarea>*/}
                      <Textarea TypeChamp="text" value={description} onChange={(e) => { setDescription(e.target.value) }} placeholder="Description" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <Boutons type="submit" valeur="Save" />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="row mt-4 pt-4" >
            <div className="col-md-12">
              <div >
                <span className="font-weight-bold mr-4">CUSTOMER LIST</span>
              </div>
              {/*Tableau de manager*/}

              <MDBDataTable
                striped
                bordered
                small
                entriesLabel="See entries"
                infoLabel={["Show", "to", "from", "elements", "Display of {0}-{1} of {2} items",]}
                searchLabel="search"
                noRecordsFoundLabel="No record found"
                noBottomColumns
                paginationLabel={["P", "S"]}
                data={config}
                onRowClick={handleRowClick}
              />
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
