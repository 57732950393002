import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import Boutons from '../components/autentification/bouton';
import { useParams } from 'react-router-dom';
import { fetchClientsFromAPI } from "../datas/clients";
import { fetchPostesLibresFromAPI } from "../datas/PostesLibres";
import { useLocation } from "react-router-dom";
import file from "../assets/img/file.png";





export default function MatchingCandidat() {
  const { candidatId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCandidat, setIsLoadingCandidat] = useState(true);
  const [selectedClient, setSelectedClient] = useState(null);
  const [candidatData, setCandidatData] = useState(null);
  const [formattedClients, setFormattedClients] = useState([]);
  const [formattedPostes, setFormattedPostes] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [matching, setMatching] = useState({});
  const [alertMessages, setAlertMessages] = useState([]);
  const [user, setUser] = useState("");
  const location = useLocation();


  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().slice(0, 19).replace("T", " ");

  const [formData, setFormData] = useState({
    poste: "",
  });

  const handleInputChange = (inputValue) => {
    // Faites ici une recherche ou un filtrage en fonction de la valeur saisie dans le champ de recherche
  };

  const addAlertMessage = (message, type) => {
    // Ajoutez un nouvel message à la liste des alertes
    setAlertMessages([...alertMessages, { message, type }]);
    // Supprimez automatiquement le message après un certain délai (par exemple, 5 secondes)
    setTimeout(() => {
      removeAlertMessage();
    }, 5000); // 5000 millisecondes (5 secondes)
  };

  const removeAlertMessage = () => {
    // Supprimez le premier message de la liste des alertes
    if (alertMessages.length > 0) {
      const updatedMessages = [...alertMessages];
      updatedMessages.shift();
      setAlertMessages(updatedMessages);
      // Actualisez la page après avoir supprimé le message (délai de 5000 millisecondes)
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setMatching((prevMatching) => ({
      ...prevMatching,
      client_id: selectedOption ? selectedOption.value : "",
    }));

    if (selectedOption) {
      fetchPostesLibresFromAPI(selectedOption.value).then((data) => {
        const formatted = data.postesLibres.map(postesLibre => ({
          value: postesLibre.id,
          label: postesLibre.libelle,
        }));
        setFormattedPostes(formatted);
      });
    } else {
      // Réinitialisez les domaines si aucun client n'est sélectionné
      setFormattedPostes([]);
    }
  };

  const authToken = localStorage.getItem('token');

  const handleSubmit = async (e) => {
    e.preventDefault(); // Empêcher le formulaire de se soumettre par défaut

    try {
      console.log("Request Data:", {
        candidat_id: parseInt(candidatId),
        client_id: matching ? matching.client_id : '',
        poste_libre_id: formData ? formData.poste : '',
        statut: 0,
        date_validation: formattedDate,
        user_id: user,
      });

      const response = await axios.post(
        'https://cerebrotbt.com/api/admin/matching/add_matching',
        {
          candidat_id: parseInt(candidatId),
          client_id: matching ? matching.client_id : '',
          poste_libre_id: formData ? formData.poste : '',
          statut: 0,
          date_validation: formattedDate,
          user_id: user,
        },
        {
          headers: {
            'Authorization': `Bearer ${authToken}`
          }
        }
      );
      if (response.status === 201) {
        addAlertMessage("Matching enregistré avec succès !", "success");
      }
      // Vous pouvez gérer la réponse ici (par exemple, afficher un message de succès)
      console.log('Matching enregistré avec succès:', response.data);
    } catch (error) {
      // Afficher des messages d'erreur spécifiques dans la console pour le débogage
      addAlertMessage(`Erreur lors de l'enregistrement du matching: ${error.response.data.message}`, "danger");
      console.error('Erreur lors de l\'enregistrement du matching:', error.message);
      console.error('Réponse de l\'erreur:', error.response);
    }
  };

  function HandleChange(value, name, fieldType) {
    switch (name) {
      case "poste":
        setFormData({ ...formData, poste: value ? value.value : "" });
        break;
      case "client_id":
        setFormData({ ...formData, client_id: value ? value.value : "" });
        break;
      default:
        // Gérez le champ par défaut (si nécessaire)
        break;
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://cerebrotbt.com/api/admin/candidat/show/${candidatId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
        const data = response.data;
        setCandidatData(data);
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error.message);
      }
    };
    //fetchData();
    const userInfoJSON = localStorage.getItem("user");
    // Vérifiez si userInfoJSON est défini
    if (userInfoJSON) {
      // Parsez la chaîne JSON en un objet JavaScript
      const parsedUserInfo = JSON.parse(userInfoJSON);
      // Accédez à la propriété "nom" de l'objet
      const id = parsedUserInfo.id;
      setUser(id);
    } else {
      console.log('Aucune donnée utilisateur trouvée dans le local storage');
    }


    fetchClientsFromAPI().then((data) => {
      const formatted = data ? data.map(client => ({
        value: client.id,
        label: client.nom,
      })) : [];
      setFormattedClients(formatted);
      setIsLoading(false);
    });
    fetchData();
  }, [candidatId]); // Mettez à jour lorsque candidatId change

  const handleRetour = () => {
    if (location.pathname !== "/edit/") {
      // Si l'URL courante n'est pas "/edit/", utilisez le hook history pour revenir à la page précédente
      window.history.back();
    } else {
      // Sinon, effectuez l'action que vous souhaitez pour afficher le retour (par exemple, ne rien faire ici)
    }
  };

  return (
    <div>
      <h6 className="font-weight-bolder mb-0" style={{ marginLeft: "20px" }}>
        <span onClick={handleRetour} style={{ cursor: "pointer", color: "#939191" }}>
          {location.pathname !== "/edit/" && "Retour | "}
        </span>
        {"Maching de candidat"}
      </h6>
      {candidatData ? (
        <div className='container'>
          {alertMessages.map((alert, index) => (
            <div key={index} className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
              {alert.message}
              <button
                type="button"
                className="btn-close"
                onClick={() => removeAlertMessage(index)}
                aria-label="Close"
              ></button>
            </div>
          ))}
          <div className='row'>

            <div className='col-md-2'>



            </div>
          </div>
          <div class="container">
            <h6 style={{ textAlign: "center" }}>INFORMATIONS CANDIDAT</h6>
            <div class="row" style={{ marginRight: "35px", marginLeft: "35px" }}>
              <div class="col">
                <table class="table table-striped table-bordered">

                  <tbody>
                    <tr><td>Nom</td></tr>
                    <tr><td>Prénoms</td></tr>
                    <tr><td>Email</td></tr>
                    <tr><td>Téléphone</td></tr>
                    <tr><td>Adresse</td></tr>
                    <tr><td>Poste</td></tr>
                    <tr><td>Industrie</td></tr>
                    <tr><td>Domaines</td></tr>
                    <tr><td>Contrat</td></tr>
                    <tr><td>Office présence</td></tr>
                    <tr><td>Type de rémunération</td></tr>
                    <tr><td>Rémunération Min</td></tr>
                    <tr><td>Rémunération Max</td></tr>
                    <tr><td>Linkedin</td></tr>
                    <tr><td>Github</td></tr>
                    <tr><td>Portefolio</td></tr>
                    <tr><td>CV</td></tr>
                    <tr>
                      <td>
                        <form onSubmit={handleSubmit}>
                          <Select
                            options={formattedClients}
                            isSearchable
                            onInputChange={handleInputChange}
                            onChange={handleChange}
                            value={selectedOption}
                            name="client_id"
                            placeholder="Rechercher un client..."
                            className="col-md"
                          />
                        </form>

                      </td>
                    </tr>
                    <tr>
                      <td>
                        <form onSubmit={handleSubmit}>

                          <Select
                            options={formattedPostes}
                            isSearchable
                            onInputChange={handleInputChange}
                            onChange={(selectedOption) => HandleChange(selectedOption, "poste", "select")}
                            name="poste"
                            value={formData.poste ? formattedPostes.find(option => option.value === formData.poste) : null}
                            placeholder="Poste libre"
                            className="col"
                          />
                        </form>

                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col">
                <table class="table table-striped table-bordered">

                  <tbody>
                    <tr><td><span style={{ fontWeight: 'bold' }}>{candidatData.Candidat.nom}</span></td></tr>
                    <tr><td> <span style={{ fontWeight: 'bold' }}>{candidatData.Candidat.prenoms}</span></td></tr>
                    <tr><td><span style={{ fontWeight: 'bold' }}>{candidatData.Candidat.email}</span></td></tr>
                    <tr><td><span style={{ fontWeight: 'bold' }}>{candidatData.Candidat.telephone}</span></td></tr>
                    <tr><td><span style={{ fontWeight: 'bold' }}>{candidatData.Candidat.location}</span></td></tr>
                    <tr><td><span style={{ fontWeight: 'bold' }}>{candidatData.Candidat.poste}</span></td></tr>
                    <tr><td><span style={{ fontWeight: 'bold' }}>{candidatData.Candidat.industries ? candidatData.Candidat.industries.nom : ""}</span></td></tr>
                    <tr><td><span style={{ fontWeight: 'bold' }}>{candidatData.Candidat.domaines ? candidatData.Candidat.domaines.map((domaine) => domaine.nom).join(', ') : ""}</span></td></tr>
                    <tr><td><span style={{ fontWeight: 'bold' }}>{candidatData.Candidat.contrat ? candidatData.Candidat.contrat.nom : 'N/A'}</span></td></tr>
                    <tr><td><span style={{ fontWeight: 'bold' }}>{candidatData.Candidat.office_presences ? candidatData.Candidat.office_presences.nom : ""}</span></td></tr>
                    <tr><td><span style={{ fontWeight: 'bold' }}>{candidatData.Candidat.remunerations ? candidatData.Candidat.remunerations.nom : 'N/A'}</span></td></tr>
                    <tr><td> <span style={{ fontWeight: 'bold' }}>{candidatData.Candidat.remunerationMin}</span></td></tr>
                    <tr><td> <span style={{ fontWeight: 'bold' }}>{candidatData.Candidat.remunerationMax}</span></td></tr>
                    <tr><td> <span style={{ fontWeight: 'bold' }}>{candidatData.Candidat.lienLinkedin ? candidatData.Candidat.lienLinkedin : 'N/A'}</span></td></tr>
                    <tr><td> <span style={{ fontWeight: 'bold' }}>{candidatData.Candidat.lienGithub ? candidatData.Candidat.lienGithub : 'N/A'}</span></td></tr>
                    <tr><td><span style={{ fontWeight: 'bold' }}>{candidatData.Candidat.lienPortefolio ? candidatData.Candidat.lienPortefolio : 'N/A'}</span></td></tr>
                    <tr><td>
                      {candidatData.Candidat.cv_path ? <embed className="me-3 mb-0" src={`https://cerebrotbt.com/storage/${candidatData.Candidat.cv_path}`} type="application/pdf" width="100%" height="200px" /> : <p> Aucun CV</p>}
                    </td></tr>
                    <tr>
                      <td scope="2">
                        <form onSubmit={handleSubmit}>
                          <Boutons type="submit" valeur="Matching" />
                        </form>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="loader"></div>
        )}
    </div>
  );
}
