import logo from "../../assets/img/LOGO2.png";
import axios from 'axios'; // Import Axios
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Liens from "./liens";
import Dropdown from "./Dropdown";


export default function NavigationGauche() {
    // Définissez les données de sous-menus que vous souhaitez afficher
    const sousMenusManagement = [
        { to: "/management/industry", texte: "Industries" },
        { to: "/management/domaine", texte: "Domains" },
        { to: "/management/user", texte: "Users" },
        { to: "/management/add_user", texte: "Add a user" },

        // Ajoutez d'autres sous-menus si nécessaire
    ];
    const [userData, setUserData] = useState({}); // State to store user data

    
    useEffect(() => {
        // Define a function to fetch user data
        const fetchUserData = async () => {
            // Récupérez la chaîne JSON depuis le local storage
            const userInfoJSON = localStorage.getItem("user");
            const parsedUserInfo = JSON.parse(userInfoJSON);
            // Accédez à la propriété "nom" de l'objet
            const id = parsedUserInfo.id;
            const authToken = localStorage.getItem('token');

            const headers = {
              'Authorization': `Bearer ${authToken}`
            };
          try {
            const response = await axios.get(`https://cerebrotbt.com/api/admin/user/show/${id}`,{headers});
            setUserData(response.data.user); // Set user data in the state
            localStorage.setItem("userRoles", response.data.user.roles[0].nom);
            localStorage.setItem("userFrom", response.data.user.from);


          } catch (error) {
            console.error('Error fetching user data:', error);
          }
        };
    
        fetchUserData(); // Call the function when the component mounts
      }, []);
        let menu;
        console.log("user info",userData)

       if (userData.from === "self") {
            menu =  <div className="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
                                <ul className="navbar-nav">
                                    <Liens icone="dashboard" texte="Dashboard" to="/dashboard" />
                                    <Liens icone="people" texte="Candidate" to="/candidate" />
                                    <Liens icone="add_business" texte="Customers" to="/customer" />
                                    <Liens icone="manage_accounts" texte="Managers" to="/manager" />
                                    <Liens icone="work" texte="Jobs" to="/jobs" />
                                </ul>
                           </div>;
        } else {
            switch (userData.roles && userData.roles.length > 0 && userData.roles[0].nom) {
                case "Admin":
                   menu =  <div className="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
                                <ul className="navbar-nav">
                                    <Liens icone="dashboard" texte="Dashboard" to="/dashboard" />
                                    <Liens icone="people" texte="Candidate" to="/candidate" />
                                    <Liens icone="add_business" texte="Customers" to="/customer" />
                                    <Liens icone="manage_accounts" texte="Managers" to="/manager" />
                                    <Liens icone="work" texte="Jobs" to="/jobs" />
                                    <Liens icone="join_inner" texte="Matching" to="/matching" />
                                    <Dropdown icone="manage_accounts" texte="Management" to="/management" sousMenus={sousMenusManagement} />
                                </ul>
                           </div>;
                    break;
                case "Talent Coach":
                    menu = <div className="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
                            <ul className="navbar-nav">
                                <Liens icone="dashboard" texte="Dashboard" to="/dashboard" />
                                <Liens icone="people" texte="Candidate" to="/candidate" />
                                {/*<Liens icone="add_business" texte="Clients" to="/client" />
                                <Liens icone="manage_accounts" texte="Managers" to="/manager" />
                                <Liens icone="join_inner" texte="Matching" to="/matching" />*/}
                                <Liens icone="work" texte="List of jobs" to="/listes_jobs" />
                                <Liens icone="join_inner" texte="Matching" to="/matching" />
                            </ul>
                          </div>;
                    break;
                case "Business Developer":
                    menu = <div className="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
                                <ul className="navbar-nav">
                                    <Liens icone="dashboard" texte="Dashboard" to="/dashboard" />
                                    <Liens icone="people" texte="Candidate" to="/listes-candidats" />
                                    <Liens icone="add_business" texte="Customers" to="/customer" />
                                    <Liens icone="manage_accounts" texte="Managers" to="/manager" />
                                    <Liens icone="work" texte="Jobs" to="/jobs" />
                                    <Liens icone="join_inner" texte="Matching" to="/matching" />
                                </ul>
                           </div>;
                    break; 
                case "Business Partner":
                    menu = <div className="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
                    <ul className="navbar-nav">
                        <Liens icone="dashboard" texte="Dashboard" to="/dashboard" />
                        <Liens icone="people" texte="Candidate" to="/candidate" />
                        <Liens icone="add_business" texte="Customers" to="/customer" />
                        <Liens icone="manage_accounts" texte="Managers" to="/manager" />
                        <Liens icone="work" texte="Jobs" to="/jobs" />
                        <Liens icone="join_inner" texte="Matching" to="/matching" />
                    </ul>
               </div>;
                    break; 
                default:
                    menu = <div className="loader">
                             Aucun menu
                           </div>;
                    break; 
            }
     
       }


    return (
        <>
            <aside className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3   bg-gradient-dark" id="sidenav-main">
                <div className="sidenav-header">
                    <Link to="/dashboard" className="m-0">
                        <img src={logo} className="h-100 centered-image" alt="main_logo" />
                    </Link>
                </div>
                <hr className="horizontal light mt-0 mb-2" />
                {menu}       
            </aside>
        </>
    );

}