


export default function Boutons({valeur}) {
    return (

        <>
            <button type="submit" className="btn btn-lg bg-gradient-primary btn-lg w-100 mb-0">{valeur}</button>
        </>
    );
}