import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Inscription from './pages/inscription';
import Login from './pages/login';
import Dashboard from './pages/dashboard';
import Management from './pages/management';
import Client from './pages/addClient';
import Profile from './pages/profile';


import Manager from './pages/addManager';

import PrivateRoute from './components/autentification/PrivateRoute';
import Candidat from './pages/addCandidat';
import MatchingCandidat from './pages/matchingCandidat';
import Post from './pages/addPost';
import Matching from './pages/addMatching';
import Industrie from './pages/addIndustry';
import EditIndustrie from './pages/modification/editIndustry';
import EditClient from './pages/modification/editClients';
import EditDomaine from './pages/modification/editDomaine';
import Domaine from './pages/addDomaine';
import ListesPostes from './pages/listesPostes';
import ListesClients from './pages/listesClients';
import AllUsers from './pages/allUsers';
import EditUser from './pages/modification/editUser';
import MiseEnReseauCandidat from './pages/miseEnReseauCandidat';
import MiseEnReseauPoste from './pages/miseEnReseauPost';
import EditCandidat from './pages/modification/editCandidat';
import EditManager from './pages/modification/editManager';
import EditPost from './pages/modification/editPoste';
import MatchingPoste from './pages/matchingPoste';
import Services from './pages/Services';
import About from './pages/About';
import Contact from './pages/Contact';
import CreateUsers from './pages/CreateUsers';

import ListesCandidat from './pages/listesCandidat';

function App() {
  const isAuthenticated = localStorage.getItem('token') !== null;

  function handleAddManager(manager){
    // On recupère le manager envoyé via le formulaire ici 
    console.log("Ajout de manager" , manager)
  }


  return (


    <BrowserRouter>
      <div className="App">
        <Routes>

          <Route path="/" element={<Login />} />
          <Route path="/registration" element={<Inscription />} />          
          <Route path="/our-services" element={<Services />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />}/>} />
          <Route path='/profile' element={<PrivateRoute element={<Profile/>}/>}/>
          <Route path="/candidate" element={<PrivateRoute element={<Candidat/>}/>}  />
          <Route path="/listes-candidats" element={<PrivateRoute element={<ListesCandidat/>}/>}  />
          <Route path="/matching-candidat/:candidatId" element={<PrivateRoute element={<MatchingCandidat/>}/>}  />
          <Route path="/matching-poste/:posteId" element={<PrivateRoute element={<MatchingPoste/>}/>}/>
          <Route path="/mise-en-reseau-candidat/:candidatId" element={<PrivateRoute element={<MiseEnReseauCandidat/>}/>}  />
          <Route path="/mise-en-reseau-poste/:posteId" element={<PrivateRoute element={<MiseEnReseauPoste/>}/>}  />

          <Route path="/customer" element={<PrivateRoute element={<Client />}/>}  />
          <Route path="/edit/customer/:customerId" element={<PrivateRoute element={<EditClient />}/>}  />
          <Route path="/manager" element={<PrivateRoute element={<Manager handleAddManager={handleAddManager} />}/>}  />

          <Route path="/jobs" element={<PrivateRoute element={<Post />}/>}  />
          <Route path="/listes_jobs" element={<PrivateRoute element={<ListesPostes />}/>}  />
          <Route path="/listes_customers" element={<PrivateRoute element={<ListesClients />}/>}  />


          <Route path="/matching" element={<PrivateRoute element={<Matching />}/>  }  />



          <Route path="/management" element={<PrivateRoute element={<Management />}/>}  />
          <Route path="/management/industry" element={<PrivateRoute element={<Industrie />}/>}  />
          <Route path="/update-industry/:industryId" element={<PrivateRoute element={<EditIndustrie />}/>}  />
          <Route path="/edit/user/:userId" element={<PrivateRoute element={<EditUser />}/>}  />
          <Route path='/edit/candidat/:candidatIds' element={<PrivateRoute element={<EditCandidat/>}/>}/>
          <Route path='/edit/manager/:managerId' element={< PrivateRoute element={<EditManager/>}/>}/>
          <Route path='/edit/job/:jobId' element={<PrivateRoute element={<EditPost/>}/>}/>
          
          <Route path="/management/domaine" element={<PrivateRoute element={<Domaine />}/>}  />
          <Route path="/management/user" element={<PrivateRoute element={<AllUsers />}/>}  />
          <Route path='/management/add_user' element={<PrivateRoute element={<CreateUsers/>}/>}/>
          
          <Route path="/update-domaine/:domaineId" element={<PrivateRoute element={<EditDomaine />}/>}  />


        </Routes>
      </div>
    </BrowserRouter>

  );
}

export default App;
