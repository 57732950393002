import { useState, useEffect } from "react";
import axios from 'axios';
import NavigationGauche from "../components/dashboard/navGauch";
import Nav from "../components/dashboard/nav";
import { MDBDataTable } from 'mdbreact';
import { useTablePoste } from "../datas/dataTable/dataPostes";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
import {fetchClientsFromAPI} from "../datas/clients";
import { fetchRemunerationFromAPI } from '../datas/remuneration';
import { fetchContratsFromAPI } from '../datas/contrat';
import { fetchOfficeFromAPI } from '../datas/office';
import { fetchPostesLibresFromAPI } from "../datas/PostesLibres";


export default function ListesPostes() {

  const [location, setLocation] = useState("");
  const [basicModal, setBasicModal] = useState(false);
  const [selectedPoste, setSelectedPoste] = useState(null);
  const [alertMessages, setAlertMessages] = useState([]);
  const [config, load] = useTablePoste();


  const [user, setUser] = useState("");
  const [formattedClients, setFormattedClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  const [formattedRemunerations, setformattedRemunerations] = useState([]);
  const [formattedContrats, setformattedContrats] = useState([]);
  const [formattedOffices, setformattedOffices] = useState([]);
  const [formattedPostesLibres, setFormattedPostesLibres] = useState([]);
  const [selectedClients, setSelectedClients] = useState(null);



  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [clients, setClients] = useState([]);
  

  const toggleShow = (poste) => {
    setSelectedPoste(poste);
    setBasicModal(!basicModal);
  };
  const handleRowClick = (entry) => {
    // Gérez les clics de ligne ici
    console.log('Row clicked:', entry);
  
  };
  const addAlertMessage = (message, type) => {
    // Ajoutez un nouvel message à la liste des alertes
    setAlertMessages([...alertMessages, { message, type }]);
  
    // Supprimez automatiquement le message après un certain délai (par exemple, 5 secondes)
    setTimeout(() => {
      removeAlertMessage();
    }, 5000); // 5000 millisecondes (5 secondes)
  };
  const removeAlertMessage = () => {
    // Supprimez le premier message de la liste des alertes
    if (alertMessages.length > 0) {
      const updatedMessages = [...alertMessages];
      updatedMessages.shift();
      setAlertMessages(updatedMessages);

    // Actualisez la page après avoir supprimé le message (délai de 5000 millisecondes)
    setTimeout(() => {
      window.location.reload();
    }, 5000);
    }
  };

  // const [industrie, setIndustrie] = useState("");
  const [statut, setStatut] = useState("");
  const [description, setDescription] = useState("");
  const [postes, setPostes] = useState([]);
  const [formData, setFormData] = useState({
    description: "",
    location: "",
    office_id: null,
    contrat_id: null,
    remuneration_id: null,
    client_id: null,
    titre:"",
    statut:"",
    user_id:null
  });
  const options = [
    { value: 'brouillon', label: 'Brouillon' },
    { value: 'actif', label: 'Actif' },
    { value: 'personnel', label: 'Personnel' },
    { value: 'fermé', label: 'Fermé' },

  ];

  const authToken = localStorage.getItem('token');
  const headers = {
    'Authorization': `Bearer ${authToken}`
  };
 
  useEffect(() => {

    // Effectuez une requête pour récupérer les domaines en fonction de l'industrie sélectionnée
    fetchClientsFromAPI().then((data) => {
      const formatted = data.clients?  data.clients.map(client => ({
        value: client.id,
        label: client.nom,
      })) : [];
      setFormattedClients(formatted);
      setIsLoading(false);
    });

    fetchRemunerationFromAPI().then((data) => {
      const formatted = data.remunerations.map(remuneration => ({
        value: remuneration.id,
        label: remuneration.nom,
      }));
      setformattedRemunerations(formatted);
      setIsLoading(false);
    });

    fetchContratsFromAPI().then((data) => {
      const formatted = data.contrats.map(contrat => ({
        value: contrat.id,
        label: contrat.nom,
      }));
      setformattedContrats(formatted);
      setIsLoading(false);
    });
    fetchOfficeFromAPI().then((data) => {
      const formatted = data.officePresences.map(office => ({
        value: office.id,
        label: office.nom,
      }));
      setformattedOffices(formatted);
      setIsLoading(false);
    });

     // Récupérez la chaîne JSON depuis le local storage
     const userInfoJSON = localStorage.getItem("user");

    // Vérifiez si userInfoJSON est défini
    if (userInfoJSON) {
      // Parsez la chaîne JSON en un objet JavaScript
      const parsedUserInfo = JSON.parse(userInfoJSON);
      // Accédez à la propriété "nom" de l'objet
      const id = parsedUserInfo.id;
      setUser(id);
    } else {
      console.log('Aucune donnée utilisateur trouvée dans le local storage');
    }

    const authToken = localStorage.getItem('token');
     
        // Effectuez une requête GET pour récupérer les postes depuis votre API
        axios.get('https://cerebrotbt.com/api/admin/poste/index', {
          headers : {
            'Authorization': `Bearer ${authToken}`
          },
        })
          .then((response) => {
            setPostes(response.data.postes);
          })
          .catch((error) => {
            console.error('Erreur lors de la récupération des postes :', error);
          });

}, []);
 // Gestionnaire d'événements pour le changement de l'industrie sélectionnée
 const handleClientChange = (selectedOption) => {
  setFormattedClients(selectedOption);
};

if (isLoading) {
  return <div className="loader"></div>;
}

  return (
    <>

      <NavigationGauche />


      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
        <Nav />
        <div className="container-fluid py-4">

          <div className="row">
          {alertMessages.map((alert, index) => (
          <div key={index} className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
            {alert.message}
            <button
              type="button"
              className="btn-close"
              onClick={() => removeAlertMessage(index)}
              aria-label="Close"
            ></button>
          </div>
        ))}
          </div>

          <div className="row mt-4" >
            <div className="col-12">
              <p className="font-weight-bold">LISTE DES POSTES</p>
              <MDBDataTable
                striped
                bordered
                small
                entriesLabel="Voir les entrées"
              infoLabel={["Afficher", "à", "de", "éléments","Affichage de {0}-{1} de {2} éléments",]} 
              searchLabel="chercher"
              noRecordsFoundLabel="Aucun enregistrement n'a été trouvé"
              noBottomColumns
              paginationLabel={["P", "S"]}
              data={config}
              onRowClick={handleRowClick}
              />
            </div>
          </div>


        </div>
      
      </main>

     
    </>
  );
}