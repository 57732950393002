import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import Axios
import NavigationGauche from "../components/dashboard/navGauch";
import Nav from "../components/dashboard/nav";


export default function Profile() {
    const [userData, setUserData] = useState({}); // State to store user data

    useEffect(() => {
        // Define a function to fetch user data
        const fetchUserData = async () => {
            // Récupérez la chaîne JSON depuis le local storage
            const userInfoJSON = localStorage.getItem("user");
            const parsedUserInfo = JSON.parse(userInfoJSON);
            // Accédez à la propriété "nom" de l'objet
            const id = parsedUserInfo.id;
            const authToken = localStorage.getItem('token');

            const headers = {
              'Authorization': `Bearer ${authToken}`
            };
          try {
            const response = await axios.get(`https://cerebrotbt.com/api/admin/user/show/${id}`,{headers});
            setUserData(response.data.user); // Set user data in the state
            localStorage.setItem("userRoles", response.data.user.roles[0].nom);

          } catch (error) {
            console.error('Error fetching user data:', error);
          }
        };
    
        fetchUserData(); // Call the function when the component mounts
      }, []);

    return(
        <>
        <NavigationGauche />
        <div className="main-content position-relative max-height-vh-100 h-100">
        <Nav />
    <div className="container-fluid px-2 px-md-4">
    <div className="page-header min-height-300 border-radius-xl mt-4" style={{ backgroundImage: "url('https://images.unsplash.com/photo-1531512073830-ba890ca4eba2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80')" }}>
        <span className="mask  bg-gradient-primary  opacity-6"></span>
      </div>
      <div className="card card-body mx-3 mx-md-4 mt-n6">
        <div className="row gx-4 mb-2">
          <div className="col-auto">
            <div className="avatar avatar-xl position-relative">
              <img src="../assets/img/bruce-mars.jpg" alt="profile_image" className="w-100 border-radius-lg shadow-sm"/>
            </div>
          </div>
          <div className="col-auto my-auto">
            <div className="h-100">
              <h5 className="mb-1">
              {userData.nom+" "+userData.prenoms} 
              </h5>
              <p className="mb-0 font-weight-normal text-sm">
                {userData.roles && userData.roles.length > 0 ? userData.roles[0].nom : ""}       
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
            <div className="nav-wrapper position-relative end-0">
              <ul className="nav nav-pills nav-fill p-1" role="tablist">
                <li className="nav-item">
                <a className="nav-link mb-0 px-0 py-1 active" data-bs-toggle="tab" href="#" role="tab" aria-selected="true">
                    <i className="material-icons text-lg position-relative">home</i>
                    <span className="ms-1">App</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link mb-0 px-0 py-1 " data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="false">
                    <i className="material-icons text-lg position-relative">email</i>
                    <span className="ms-1">Messages</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link mb-0 px-0 py-1 " data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="false">
                    <i className="material-icons text-lg position-relative">settings</i>
                    <span className="ms-1">Settings</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="card card-plain h-100">
                <div className="card-header pb-0 p-3">
                  <h6 className="mb-0">Paramètres de la plate-forme</h6>
                </div>
                <div className="card-body p-3">
                  <h6 className="text-uppercase text-body text-xs font-weight-bolder">Compte</h6>
                  <ul className="list-group">
                 {
                  
                  userData.roles && userData.roles.length > 0 && userData.roles[0].nom == "Talent Coach" ?
                <li className="list-group-item border-0 px-0">
                <div className="form-check form-switch ps-0">
                  <input className="form-check-input ms-auto" type="checkbox" id="flexSwitchCheckDefault1"/>
                  <label className="form-check-label text-body ms-3 text-truncate w-80 mb-0" htmlFor="flexSwitchCheckDefault">Devenir Talent Coach</label>
                </div>
              </li>
                :
                <li className="list-group-item border-0 px-0">
                <div className="form-check form-switch ps-0">
                  <input className="form-check-input ms-auto" type="checkbox" id="flexSwitchCheckDefault1"/>
                  <label className="form-check-label text-body ms-3 text-truncate w-80 mb-0" htmlFor="flexSwitchCheckDefault">Devenir Busness Developpeur</label>
                </div>
              </li>
                 }  
                 {/*<li className="list-group-item border-0 px-0">
                  <div className="form-check form-switch ps-0">
                    <input className="form-check-input ms-auto" type="checkbox" id="flexSwitchCheckDefault" checked/>
                    <label className="form-check-label text-body ms-3 text-truncate w-80 mb-0" htmlFor="flexSwitchCheckDefault">Devenir Talent Coach</label>
                  </div>
                </li>
                <li className="list-group-item border-0 px-0">
                <div className="form-check form-switch ps-0">
                  <input className="form-check-input ms-auto" type="checkbox" id="flexSwitchCheckDefault1"/>
                  <label className="form-check-label text-body ms-3 text-truncate w-80 mb-0" htmlFor="flexSwitchCheckDefault">Devenir Busness Developpeur</label>
                </div>
                </li>*/}
                   
                    <li className="list-group-item border-0 px-0">
                      <div className="form-check form-switch ps-0">
                        <input className="form-check-input ms-auto" type="checkbox" id="flexSwitchCheckDefault2" checked/>
                        <label className="form-check-label text-body ms-3 text-truncate w-80 mb-0" htmlFor="flexSwitchCheckDefault">Se mettre en réseaux</label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="card card-plain h-100">
                <div className="card-header pb-0 p-3">
                  <div className="row">
                    <div className="col-md-8 d-flex align-items-center">
                      <h6 className="mb-0">Informations sur le profil</h6>
                    </div>
                    <div className="col-md-4 text-end">
                      <a href="javascript:;">
                        <i className="fas fa-user-edit text-secondary text-sm" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit Profile"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="card-body p-3">
                  <p className="text-sm">
                     Bonjour, je suis Alec Thompson, Décisions : Si vous ne pouvez pas vous décider, la réponse est non. Si deux chemins sont aussi difficiles l'un que l'autre, choisissez celui qui est le plus douloureux à court terme (l'évitement de la douleur crée une illusion d'égalité).
                 </p>
                  <hr className="horizontal gray-light my-4"/>
                  <ul className="list-group">
                    <li className="list-group-item border-0 ps-0 pt-0 text-sm"><strong className="text-dark">Nom complet:</strong> &nbsp; {userData.nom+" "+userData.prenoms} </li>
                    <li className="list-group-item border-0 ps-0 text-sm"><strong className="text-dark">Mobile:</strong> &nbsp; {userData.telephone} </li>
                    <li className="list-group-item border-0 ps-0 text-sm"><strong className="text-dark">Email:</strong> &nbsp; {userData.email} </li>
                    {/*<li className="list-group-item border-0 ps-0 text-sm"><strong className="text-dark">Localisation:</strong> &nbsp; USA</li>*/}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
        </>
    );
}